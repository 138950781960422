import { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Container,
  Typography,
  Divider,
  Paper,
  IconButton,
  Grid,
  TextField,
  Modal,
  Chip,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Search } from "@mui/icons-material";
import tlds from "./tlds";
import SchedulerNavbar from "../../components/SchedulerNavbar";
import useStore from "../../store";
import { getPlanByName } from "../Signup/plans";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    minHeight: 250,
    padding: 10,
  },
  modalPaper: {
    position: "absolute",
    [theme.breakpoints.up("xs")]: {
      maxWidth: "100vw",
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: 500,
    },
    backgroundColor: theme.palette.background.paper,
    //border: "2px solid #000",
    borderRadius: 5,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  avatar: {
    height: 50,
    width: 50,
    margin: 10,
  },
  root: {
    padding: 20,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  domainChip: {
    marginLeft: 5,
  },
}));

const Domains = () => {
  const classes = useStyles();
  function getModalStyle() {
    return {
      top: "40%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    };
  }
  const [modalStyle] = useState(getModalStyle);
  let [domains, setDomains] = useState([]);
  let [domainSearch, setDomainSearch] = useState([]);
  let [isLoading, setIsLoading] = useState(false);
  const searchDomains = useStore((state) => state.searchDomains);
  const isPremiumUser = useStore((state) => state.isPremiumUser);
  const createStripeCheckoutSession = useStore(
    (state) => state.createStripeCheckoutSession
  );
  const fetchDomainPricing = useStore((state) => state.fetchDomainPricing);
  const user = useStore((state) => state.user);
  async function fetchDomains(searchText) {
    setIsLoading(true);
    let { data: pricing } = await fetchDomainPricing();
    let searchArray =
      searchText.split(".").length > 1 // If it already has a specific tld don't show the others
        ? [searchText]
        : tlds.map((tld) => `${searchText}.${tld}`); // If no tld specified, grab them all
    let { data: orgsDomains } = await searchDomains(searchArray);

    let parsedDomains = Array.isArray(
      orgsDomains?.ApiResponse?.CommandResponse?.DomainCheckResult
    )
      ? orgsDomains?.ApiResponse?.CommandResponse?.DomainCheckResult
      : [orgsDomains?.ApiResponse?.CommandResponse?.DomainCheckResult];

    let cleanedDomains = [];
    parsedDomains.map((domain) => {
      let tld = domain.Domain.split(".").pop();
      let tldPrice = pricing.find((priceData) => priceData.tld === tld);
      let totalPrice = domain.IsPremiumName
        ? Number(domain.PremiumRegistrationPrice) * 100 + tldPrice.priceInCents
        : tldPrice.priceInCents;
      return cleanedDomains.push({
        name: domain.Domain,
        isPremium: domain.IsPremiumName,
        premiumPrice: Number(domain.PremiumRegistrationPrice) * 100,
        available:
          typeof domain.Available === "string"
            ? domain.Available.toLowerCase() === "true"
            : domain.Available,
        price: totalPrice,
      });
    });
    setDomains(cleanedDomains);
    setIsLoading(false);
  }
  useEffect(() => {
    let fullName = (user.firstName + user.lastName).toLowerCase();
    fetchDomains(fullName);
  }, []);

  function handleSearchFormSubmit(e) {
    e.preventDefault();
    fetchDomains(domainSearch);
  }

  async function handleDomainPurchase(e, name, price) {
    setIsLoading(true);
    e.preventDefault();
    let redirectURL =
      process.env.NODE_ENV === "development"
        ? "http://localhost:3001"
        : "https://i.help";
    try {
      let { data: checkout } = await createStripeCheckoutSession({
        ...(!isPremiumUser(user)
          ? { price: getPlanByName("pro").id, mode: "subscription" }
          : { mode: "subscription" }),
        domainToPurchase: name,
        successRedirect: `${redirectURL}/domains/owned?session={CHECKOUT_SESSION_ID}&domainToPurchase=${name}`,
        cancelRedirect: `${redirectURL}/domains/search`,
      });

      window.location = checkout.session.url;
      // Create checkout session with a domain.
      // If not premium add regular plan to lineItems
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  }

  return (
    <SchedulerNavbar hideAddNewMeeting>
      <Container maxWidth="md">
        <Paper className={classes.paper}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="body1" style={{ fontWeight: "bold" }}>
                Find and choose a custom domain
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                Choosing a custom domain is not only a great way to stand out,
                but it also it easier for your customers and colleagues to
                remember.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <form onSubmit={handleSearchFormSubmit}>
                <TextField
                  variant="outlined"
                  fullWidth
                  defaultValue={`${(
                    user.firstName + user.lastName
                  ).toLowerCase()}.com`}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton disabled={isLoading} type="submit">
                          <Search style={{ color: "black" }} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => setDomainSearch(e.target.value)}
                />
              </form>
            </Grid>
          </Grid>
          <Table style={{ marginBottom: 20 }}>
            <TableBody>
              {isLoading && (
                <div
                  style={{ width: "100%", textAlign: "center", paddingTop: 20 }}
                >
                  <CircularProgress />
                  <p>Loading your potential domains...</p>
                </div>
              )}
              {!isLoading &&
                domains
                  // .filter(({ price }) => price < 10000) // Hide any domains that are over $100
                  .map(
                    ({ name, price, available, premiumPrice, isPremium }) => (
                      <TableRow key={name}>
                        <TableCell>
                          <span style={{ fontSize: 16 }}>
                            {name.split(".")[0]}
                          </span>
                          <span style={{ fontSize: 16, fontWeight: "bold" }}>
                            .{name.split(".")[1]}
                          </span>
                        </TableCell>
                        <TableCell style={{ width: "100%" }}>
                          {available &&
                            price > 10000 &&
                            `This domain is available. Because it costs ${(
                              (price * 0.1 + price) /
                              100
                            ).toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })} we require that you contact us. Please click the blue chat icon in the bottom right of your screen.`}
                        </TableCell>
                        <TableCell style={{ width: "60%" }}>
                          {premiumPrice !== 0 && (
                            <Chip
                              className={classes.domainChip}
                              color="primary"
                              label="PREMIUM"
                            />
                          )}
                          {!available && (
                            <Chip
                              className={classes.domainChip}
                              label="TAKEN"
                            />
                          )}
                        </TableCell>
                        <TableCell>
                          <span style={{ fontSize: 16 }}>
                            {((price * 0.1 + price) / 100).toLocaleString(
                              "en-US",
                              {
                                style: "currency",
                                currency: "USD",
                              }
                            )}
                          </span>
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            color="primary"
                            disabled={price > 10000 || !available}
                            onClick={(e) =>
                              handleDomainPurchase(e, name, price)
                            }
                          >
                            PURCHASE
                          </Button>
                        </TableCell>
                      </TableRow>
                    )
                  )}
            </TableBody>
          </Table>
        </Paper>
      </Container>
    </SchedulerNavbar>
  );
};

export default Domains;
