import { useEffect, useState } from "react";
import {
  Toolbar,
  Typography,
  IconButton,
  Hidden,
  Drawer,
  CssBaseline,
  AppBar,
  Button,
  MenuItem,
  Menu,
  Divider,
  Box,
  TextField,
  Grid,
  Paper,
  Container,
  Avatar,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSnackbar } from "notistack";
import {
  Menu as MenuIcon,
  AccountCircle,
  Edit,
  FileCopyOutlined,
  OpenInNew,
  Phone,
  Email,
  LinkOutlined,
  LocationOn,
  InsertInvitation,
  AccountCircleOutlined,
  LinkedIn,
  Twitter,
  Facebook,
} from "@mui/icons-material/";
import { makeStyles, useTheme } from "@mui/styles";
import generateSignatureHtml from "./signatureSource";
import { accountIconRoutes } from "../../routes";
import useStore from "../../store";
import DefaultLoadingScreen from "../../components/DefaultLoadingScreen";
import { useInterval } from "../../util";
import StepLabel from "../../components/StepLabel";
const drawerWidth = 325;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: "#fafafa",
    minHeight: "100vh",
  },
  title: {
    flexGrow: 1,
    color: "#072757",
  },
  avatar: {},
  paper: {
    minHeight: 300,
    minWidth: 300,
    marginTop: theme.spacing(15),
    borderRadius: 10,
    padding: 20,
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    boxShadow: "none",
    backgroundColor: "#f0f8ff",
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  formContainer: {
    paddingTop: 7,
    paddingLeft: 7,
    width: "100%",
  },
  listItemIcon: {
    minWidth: 38,
  },
}));

function EmailSignature(props) {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);
  const { user, page, fetchPage, setShowPageNameChangeModal, isPremiumUser } =
    useStore((state) => ({
      setShowUpgradeModal: state.setShowUpgradeModal,
      user: state.user,
      page: state.page,
      showForm: state.showForm,
      fetchPage: state.fetchPage,
      clearForm: state.clearForm,
      setShowForm: state.setShowForm,
      formName: state.formName,
      setShowPageNameChangeModal: state.setShowPageNameChangeModal,
      setFormName: state.setFormName,
      elementToUpdate: state.elementToUpdate,

      isPremiumUser: state.isPremiumUser,
    }));
  const [localForm, setLocalForm] = useState({
    name: user.firstName + " " + user.lastName,
    email: user.email,
    title: user.title,
    department: user.department,
    phone: user.phone,
    url: user.url,
  });
  useEffect(() => {
    document.title = "Edit Page";
    fetchPage(user.defaultPage, "mongo", "draft");
  }, []);

  useInterval(() => {
    fetchPage(user.defaultPage, "mongo", "draft");
  }, 5000);

  const [mobileOpen, setMobileOpen] = useState(false);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleCopyHtml = () => {
    navigator.clipboard.writeText(generateSignatureHtml(localForm));
    enqueueSnackbar("Signature copied to clipboard.", {
      variant: "success",
    });
  };
  const pageUrl = (
    <a
      href={`${
        process.env.NODE_ENV === "production"
          ? "https://i.help"
          : "http://localhost:3001"
      }/${page?.name}`}
      target="_blank"
      rel="noreferrer"
      style={{ textDecoration: "none" }}
      onMouseOver={(event) => (event.target.style.textDecoration = "underline")}
      onMouseOut={(event) => (event.target.style.textDecoration = "none")}
    >
      {`${
        process.env.NODE_ENV === "production" ? "i.help" : "localhost:3001"
      }/${page?.name}`}
    </a>
  );

  const drawer = (
    <Grid container style={{ padding: 10 }}>
      <Grid item>
        <Typography
          style={{ paddingTop: 5, paddingLeft: 5 }}
          variant="subtitle2"
        >
          Create Email Signature
        </Typography>
      </Grid>
      <Grid item>
        <Typography style={{ paddingTop: 5, paddingLeft: 5 }} variant="body1">
          Using the options below and the preview on the right, create a custom
          email signature that can be added to your emails sent from Gmail,
          Outlook, or iCloud account.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <StepLabel style={{ marginBottom: -5 }} step="Your Name:" />
        <TextField
          variant="outlined"
          fullWidth
          value={localForm.name}
          onChange={(e) => setLocalForm({ ...localForm, name: e.target.value })}
        />
      </Grid>
      <Grid item xs={12}>
        <StepLabel style={{ marginBottom: -5 }} step="Job Title:" />
        <TextField
          variant="outlined"
          fullWidth
          value={localForm.title}
          onChange={(e) =>
            setLocalForm({ ...localForm, title: e.target.value })
          }
        />
      </Grid>
      <Grid item xs={12}>
        <StepLabel style={{ marginBottom: -5 }} step="Company Name:" />
        <TextField
          variant="outlined"
          fullWidth
          value={localForm.companyName}
          onChange={(e) =>
            setLocalForm({ ...localForm, companyName: e.target.value })
          }
        />
      </Grid>
      {/* <Grid item xs={12}>
        <StepLabel style={{ marginBottom: -5 }} step="Company Website:" />
        <TextField
          variant="outlined"
          fullWidth
          value={localForm.companyWebsite}
          onChange={(e) =>
            setLocalForm({ ...localForm, companyWebsite: e.target.value })
          }
        />
      </Grid> */}
      <Grid item xs={12}>
        <StepLabel style={{ marginBottom: -5 }} step="Phone:" />
        <TextField
          variant="outlined"
          fullWidth
          value={localForm.phone}
          onChange={(e) =>
            setLocalForm({ ...localForm, phone: e.target.value })
          }
        />
      </Grid>
      <Grid item xs={12}>
        <StepLabel style={{ marginBottom: -5 }} step="Email:" />
        <TextField
          variant="outlined"
          fullWidth
          value={localForm.email}
          onChange={(e) =>
            setLocalForm({ ...localForm, email: e.target.value })
          }
        />
      </Grid>
      <Grid item xs={12}>
        <StepLabel style={{ marginBottom: -5 }} step="Address Line 1:" />
        <TextField
          variant="outlined"
          fullWidth
          value={localForm.address}
          onChange={(e) =>
            setLocalForm({ ...localForm, address: e.target.value })
          }
        />
      </Grid>
      <Grid item xs={12}>
        <StepLabel style={{ marginBottom: -5 }} step="Address Line 2:" />
        <TextField
          variant="outlined"
          fullWidth
          value={localForm.address2}
          onChange={(e) =>
            setLocalForm({ ...localForm, address2: e.target.value })
          }
        />
      </Grid>
      {/* <Grid item xs={12}>
        <StepLabel style={{ marginBottom: -5 }} step="City:" />
        <TextField
          variant="outlined"
          fullWidth
          value={localForm.city}
          onChange={(e) => setLocalForm({ ...localForm, city: e.target.value })}
        />
      </Grid>
      <Grid item xs={12}>
        <StepLabel style={{ marginBottom: -5 }} step="State:" />
        <TextField
          variant="outlined"
          fullWidth
          value={localForm.state}
          onChange={(e) =>
            setLocalForm({ ...localForm, state: e.target.value })
          }
        />
      </Grid>
      <Grid item xs={12}>
        <StepLabel
          style={{ marginBottom: -5 }}
          step="Zip Code or Postal Code:"
        />
        <TextField
          variant="outlined"
          fullWidth
          value={localForm.zip}
          onChange={(e) => setLocalForm({ ...localForm, zip: e.target.value })}
        />
      </Grid>
      <Grid item xs={12}>
        <StepLabel style={{ marginBottom: -5 }} step="Country:" />
        <TextField
          variant="outlined"
          fullWidth
          value={localForm.country}
          onChange={(e) =>
            setLocalForm({ ...localForm, country: e.target.value })
          }
        />
      </Grid> */}
      {/* <Grid item xs={12}>
        <StepLabel style={{ marginBottom: -5 }} step="Twitter URL:" />
        <TextField
          variant="outlined"
          fullWidth
          value={localForm.twitter}
          onChange={(e) =>
            setLocalForm({ ...localForm, twitter: e.target.value })
          }
        />
      </Grid>
      <Grid item xs={12}>
        <StepLabel style={{ marginBottom: -5 }} step="Facebook URL:" />
        <TextField
          variant="outlined"
          fullWidth
          value={localForm.facebook}
          onChange={(e) =>
            setLocalForm({ ...localForm, facebook: e.target.value })
          }
        />
      </Grid>
      <Grid item xs={12}>
        <StepLabel style={{ marginBottom: -5 }} step="LinkedIn URL:" />
        <TextField
          variant="outlined"
          fullWidth
          value={localForm.linkedIn}
          onChange={(e) =>
            setLocalForm({ ...localForm, linkedIn: e.target.value })
          }
        />
      </Grid> */}
    </Grid>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const Signature = () => (
    <table
      cellPadding={0}
      cellSpacing={0}
      className="sc-gPEVay eQYmiW"
      style={{
        verticalAlign: "-webkit-baseline-middle",
        fontSize: "medium",
        fontFamily: "Arial",
      }}
    >
      <tbody>
        <tr>
          <td>
            <table
              cellPadding={0}
              cellSpacing={0}
              className="sc-gPEVay eQYmiW"
              style={{
                verticalAlign: "-webkit-baseline-middle",
                fontSize: "medium",
                fontFamily: "Arial",
              }}
            >
              <tbody>
                <tr>
                  <td style={{ padding: "0px", verticalAlign: "middle" }}>
                    <h3
                      color="#000000"
                      className="sc-fBuWsC eeihxG"
                      style={{
                        margin: "0px",
                        fontSize: "18px",
                        color: "rgb(0, 0, 0)",
                      }}
                    >
                      <span>{localForm.name}</span>
                    </h3>
                    <p
                      color="#000000"
                      fontSize="medium"
                      className="sc-fMiknA bxZCMx"
                      style={{
                        margin: "0px",
                        color: "rgb(0, 0, 0)",
                        fontSize: "14px",
                        lineHeight: "22px",
                      }}
                    >
                      <span>{localForm.title}</span>
                    </p>
                    <p
                      color="#000000"
                      fontSize="medium"
                      className="sc-dVhcbM fghLuF"
                      style={{
                        margin: "0px",
                        fontWeight: 500,
                        color: "rgb(0, 0, 0)",
                        fontSize: "14px",
                        lineHeight: "22px",
                      }}
                    >
                      <span>{localForm.department}</span>
                      <span>&nbsp;|&nbsp;</span>
                      <span>{localForm.companyName}</span>
                    </p>
                    <table
                      cellPadding={0}
                      cellSpacing={0}
                      className="sc-gPEVay eQYmiW"
                      style={{
                        verticalAlign: "-webkit-baseline-middle",
                        fontSize: "medium",
                        fontFamily: "Arial",
                        width: "100%",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td height={10} />
                        </tr>
                        <tr>
                          <td
                            color="#F2547D"
                            direction="horizontal"
                            height={1}
                            className="sc-jhAzac hmXDXQ"
                            style={{
                              width: "100%",
                              borderBottom: "1px solid rgb(0, 0, 0)",
                              borderLeft: "none",
                              display: "block",
                            }}
                          />
                        </tr>
                        <tr>
                          <td height={10} />
                        </tr>
                      </tbody>
                    </table>
                    <table
                      cellPadding={0}
                      cellSpacing={0}
                      className="sc-gPEVay eQYmiW"
                      style={{
                        verticalAlign: "-webkit-baseline-middle",
                        fontSize: "medium",
                        fontFamily: "Arial",
                      }}
                    >
                      <tbody>
                        <tr height={25} style={{ verticalAlign: "middle" }}>
                          <td width={30} style={{ verticalAlign: "middle" }}>
                            <table
                              cellPadding={0}
                              cellSpacing={0}
                              className="sc-gPEVay eQYmiW"
                              style={{
                                verticalAlign: "-webkit-baseline-middle",
                                fontSize: "medium",
                                fontFamily: "Arial",
                              }}
                            >
                              <tbody>
                                <tr>
                                  <td style={{ verticalAlign: "bottom" }}>
                                    <span
                                      color="#F2547D"
                                      width={11}
                                      className="sc-jlyJG bbyJzT"
                                      style={{ display: "block" }}
                                    >
                                      <img
                                        src="https://ihelpimages.s3.amazonaws.com/icons/Phone/2x/baseline_call_black_24dp.png"
                                        color="#F2547D"
                                        width={13}
                                        alt="calendar"
                                        className="sc-iRbamj blSEcj"
                                        style={{ display: "block" }}
                                      />
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                          <td style={{ padding: "0px", color: "rgb(0, 0, 0)" }}>
                            <a
                              href="tel:9163420927"
                              color="#000000"
                              className="sc-gipzik iyhjGb"
                              style={{
                                textDecoration: "none",
                                color: "rgb(0, 0, 0)",
                                fontSize: "12px",
                              }}
                            >
                              <span>{localForm.phone}</span>
                            </a>
                          </td>
                        </tr>
                        <tr height={25} style={{ verticalAlign: "middle" }}>
                          <td width={30} style={{ verticalAlign: "middle" }}>
                            <table
                              cellPadding={0}
                              cellSpacing={0}
                              className="sc-gPEVay eQYmiW"
                              style={{
                                verticalAlign: "-webkit-baseline-middle",
                                fontSize: "medium",
                                fontFamily: "Arial",
                              }}
                            >
                              <tbody>
                                <tr>
                                  <td style={{ verticalAlign: "bottom" }}>
                                    <span
                                      color="#F2547D"
                                      width={11}
                                      className="sc-jlyJG bbyJzT"
                                      style={{ display: "block" }}
                                    >
                                      <img
                                        src="https://ihelpimages.s3.amazonaws.com/icons/Email/2x/baseline_email_black_24dp.png"
                                        color="#F2547D"
                                        width={13}
                                        alt="email"
                                        className="sc-iRbamj blSEcj"
                                        style={{ display: "block" }}
                                      />
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                          <td style={{ padding: "0px" }}>
                            <a
                              href={`mailto:${localForm.email}`}
                              color="#000000"
                              className="sc-gipzik iyhjGb"
                              style={{
                                textDecoration: "none",
                                color: "rgb(0, 0, 0)",
                                fontSize: "12px",
                              }}
                            >
                              <span>{localForm.email}</span>
                            </a>
                          </td>
                        </tr>
                        <tr height={25} style={{ verticalAlign: "middle" }}>
                          <td width={30} style={{ verticalAlign: "middle" }}>
                            <table
                              cellPadding={0}
                              cellSpacing={0}
                              className="sc-gPEVay eQYmiW"
                              style={{
                                verticalAlign: "-webkit-baseline-middle",
                                fontSize: "medium",
                                fontFamily: "Arial",
                              }}
                            >
                              <tbody>
                                <tr>
                                  <td style={{ verticalAlign: "bottom" }}>
                                    <span
                                      color="#F2547D"
                                      width={11}
                                      className="sc-jlyJG bbyJzT"
                                      style={{ display: "block" }}
                                    >
                                      <img
                                        src="https://ihelpimages.s3.amazonaws.com/icons/Link/2x/baseline_link_black_24dp.png"
                                        color="#F2547D"
                                        width={13}
                                        alt="link"
                                        className="sc-iRbamj blSEcj"
                                        style={{ display: "block" }}
                                      />
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                          <td style={{ padding: "0px" }}>
                            <a
                              href={`https://i.help/${page.name}`}
                              color="#000000"
                              className="sc-gipzik iyhjGb"
                              style={{
                                textDecoration: "none",
                                color: "rgb(0, 0, 0)",
                                fontSize: "12px",
                              }}
                            >
                              <span>i.help/{page.name}</span>
                            </a>
                          </td>
                        </tr>
                        <tr height={25} style={{ verticalAlign: "middle" }}>
                          <td width={30} style={{ verticalAlign: "middle" }}>
                            <table
                              cellPadding={0}
                              cellSpacing={0}
                              className="sc-gPEVay eQYmiW"
                              style={{
                                verticalAlign: "-webkit-baseline-middle",
                                fontSize: "medium",
                                fontFamily: "Arial",
                              }}
                            >
                              <tbody>
                                <tr>
                                  <td style={{ verticalAlign: "bottom" }}>
                                    <span
                                      color="#F2547D"
                                      width={11}
                                      className="sc-jlyJG bbyJzT"
                                      style={{ display: "block" }}
                                    >
                                      <img
                                        src="https://ihelpimages.s3.amazonaws.com/icons/Place/2x/baseline_place_black_24dp.png"
                                        color="#F2547D"
                                        width={13}
                                        alt="place"
                                        className="sc-iRbamj blSEcj"
                                        style={{ display: "block" }}
                                      />
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                          <td style={{ padding: "0px" }}>
                            <span
                              color="#000000"
                              className="sc-csuQGl CQhxV"
                              style={{
                                fontSize: "12px",
                                color: "rgb(0, 0, 0)",
                              }}
                            >
                              <span>{localForm.address}</span>
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ padding: "0px" }}>
                            <span
                              color="#000000"
                              className="sc-csuQGl CQhxV"
                              style={{
                                fontSize: "12px",
                                color: "rgb(0, 0, 0)",
                              }}
                            >
                              <span>{localForm.address2}</span>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table
                      cellPadding={0}
                      cellSpacing={0}
                      className="sc-gPEVay eQYmiW"
                      style={{
                        verticalAlign: "-webkit-baseline-middle",
                        fontSize: "medium",
                        fontFamily: "Arial",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td height={30} />
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
  return (
    <div className={classes.root}>
      {page ? (
        <>
          <CssBaseline />
          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
              >
                <MenuIcon style={{ color: "#072757" }} />
              </IconButton>
              <Typography variant="body1" className={classes.title}>
                Your URL:{" "}
                <Box display="inline" fontWeight="fontWeightBold">
                  {pageUrl}
                </Box>
                <IconButton
                  style={{
                    color: "#2f567d",
                    padding: 3,
                    marginLeft: 0.5,
                    marginBottom: 5,
                  }}
                  edge="start"
                  onClick={() => setShowPageNameChangeModal(true)}
                >
                  <Edit fontSize="small" />
                </IconButton>
                <CopyToClipboard
                  text={`https://i.help/${page?.name}`}
                  onCopy={(text, result) =>
                    enqueueSnackbar("URL copied to clipboard.", {
                      variant: "success",
                    })
                  }
                >
                  <IconButton
                    style={{
                      color: "#2f567d",
                      padding: 3,
                      marginLeft: 0.5,
                      marginBottom: 5,
                    }}
                    edge="start"
                  >
                    <FileCopyOutlined fontSize="small" />
                  </IconButton>
                </CopyToClipboard>
                <IconButton
                  style={{
                    color: "#2f567d",
                    padding: 3,
                    marginLeft: 0.5,
                    marginBottom: 5,
                  }}
                  edge="start"
                  target="_blank"
                  href={`${
                    process.env.NODE_ENV === "production"
                      ? "https://i.help"
                      : "http://localhost:3001"
                  }/${page.name}`}
                >
                  <OpenInNew fontSize="small" />
                </IconButton>
              </Typography>
              {!isPremiumUser(user) && (
                <>
                  <Button
                    style={{
                      color: "#1e79d7",
                      marginRight: 5,
                      fontWeight: 700,
                    }}
                    target="_blank"
                    href="https://www.i.help/u"
                  >
                    Learn more about PRO
                  </Button>
                  <Button
                    style={{ borderRadius: 15, fontWeight: 700 }}
                    variant="contained"
                    color="primary"
                    href="/billing"
                  >
                    Upgrade to PRO
                  </Button>
                </>
              )}
              <Button variant="outlined" color="primary" href="/p/edit">
                Back to your Page
              </Button>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                // color="inherit"
                style={{ color: "#072757" }}
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={open}
                onClose={handleClose}
              >
                {accountIconRoutes.map((r) => (
                  <MenuItem onClick={() => history.push(r.path)}>
                    {r.label}
                  </MenuItem>
                ))}
                {user.role === "SuperAdmin" && (
                  <MenuItem onClick={() => history.push("/admin")}>
                    Admin
                  </MenuItem>
                )}
              </Menu>
            </Toolbar>
          </AppBar>
          <nav className={classes.drawer} aria-label="mailbox folders">
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Hidden smUp implementation="css">
              <Drawer
                container={container}
                variant="temporary"
                anchor={theme.direction === "rtl" ? "right" : "left"}
                open={mobileOpen}
                onClose={handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
                {drawer}
              </Drawer>
            </Hidden>
            <Hidden xsDown implementation="css">
              <Drawer
                classes={{
                  paper: classes.drawerPaper,
                }}
                variant="permanent"
                open
              >
                {drawer}
              </Drawer>
            </Hidden>
          </nav>
          <main className={classes.content}>
            <div className={classes.toolbar} />
            <Container>
              <Grid container justifyContent="center" spacing={3}>
                <Grid item xs={12} md={5} lg={5}>
                  <Paper className={classes.paper}>
                    <Signature />
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Box style={{ textAlign: "center" }}>
                    <Button
                      style={{ margin: 5 }}
                      variant="contained"
                      color="primary"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://support.google.com/mail/answer/8395?hl=en&co=GENIE.Platform%3DDesktop"
                    >
                      Gmail Instructions
                    </Button>
                    <Button
                      style={{ margin: 5 }}
                      variant="contained"
                      color="primary"
                      target="_blank"
                      href="https://support.microsoft.com/en-us/office/create-an-email-signature-31fb24f9-e698-4789-b92a-f0e777f774ca"
                      rel="noopener noreferrer"
                    >
                      Outlook Instructions
                    </Button>
                    <Button
                      style={{ margin: 5 }}
                      variant="contained"
                      color="primary"
                      target="_blank"
                      href="https://support.apple.com/guide/mail/create-and-use-email-signatures-mail11943/mac"
                      rel="noopener noreferrer"
                    >
                      iCloud Instructions
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={12} md={5}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={handleCopyHtml}
                  >
                    Copy code to embed in email{" "}
                    <FileCopyOutlined style={{ marginLeft: 10 }} />
                  </Button>
                </Grid>
              </Grid>
            </Container>
          </main>
        </>
      ) : (
        <DefaultLoadingScreen />
      )}
    </div>
  );
}

export default EmailSignature;
