import Navigation from "../../components/Navigation";

function Users() {
  return (
    <Navigation pageName="Users" pageDescription="The Users in your account.">
      <div>
        <h2>Users</h2>
      </div>
    </Navigation>
  );
}

export default Users;
