import { useState } from "react";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { IconButton, Typography, Divider } from "@mui/material";
import useStore from "../store";
function arrayMove(array, item, direction) {
  //Swaps an
  function arrayMoveByIndex(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  }
  let currentIndex = array.indexOf(item);
  let newIndex = direction === "right" ? currentIndex + 1 : currentIndex - 1;
  console.log(array, currentIndex, newIndex);
  let newArray = arrayMoveByIndex(array, currentIndex, newIndex);
  return newArray;
}
//arrayMove(items, "item2", "right" )

const MoveElement = ({ element, direction, style }) => {
  const page = useStore((state) => state.page);
  const fetchPage = useStore((state) => state.fetchPage);
  const publishPage = useStore((state) => state.publishPage);
  const user = useStore((state) => state.user);
  const [isMoving, setIsMoving] = useState(false);
  let elements = page?.draft?.dropZone1?.elements;
  const updatePageButtons = useStore((state) => state.updatePageButtons);
  const moveElement = async (direction) => {
    console.log({ elements, element });
    let newPosition = arrayMove(
      page?.draft?.dropZone1?.elements,
      element,
      direction
    );
    let dropZoneUpdate = newPosition.map((element) => element._id);
    console.log({ dropZoneUpdate });
    setIsMoving(true);
    await updatePageButtons(user.defaultPage, dropZoneUpdate);
    await publishPage();
    await fetchPage();

    setIsMoving(false);
  };

  return (
    <div style={{ textAlign: "center", width: "100%", marginTop: 30 }}>
      <Divider />

      <Typography variant="subtitle2" gutterBottom>
        Move Button
      </Typography>
      <IconButton onClick={() => moveElement("left")} disabled={isMoving}>
        <ArrowBack />
      </IconButton>
      <IconButton onClick={() => moveElement("right")} disabled={isMoving}>
        <ArrowForward />
      </IconButton>
    </div>
  );
};

export default MoveElement;
