import React from "react";
import { Container, Button, TextField, Typography, Grid } from "@mui/material";
import axios from "axios";
import useStore from "../../../store";
import StepLabel from "../../../components/StepLabel";

let AvatarForm = ({ cancelForm, page }) => {
  const updatePage = useStore((state) => state.updatePage);
  const fetchPage = useStore((state) => state.fetchPage);
  const fetchPresignedUrl = useStore((state) => state.fetchPresignedUrl);
  const handleImageUpload = async (e) => {
    e.preventDefault();
    let urlRequest = await fetchPresignedUrl();
    let uploadURL = urlRequest.data.uploadURL;
    var file = document.querySelector("#file").files[0];
    if (file) {
      await axios({
        method: "put",
        url: uploadURL,
        headers: {
          "Content-Type": "image/*",
        },
        data: file,
      });
      await updatePage(page._id, {
        avatar: `https://ihelpimages.s3.amazonaws.com/${
          page._id
        }?key=${new Date().getTime()}`, // We add the timestamp to the end to refresh the image on save
      });
      fetchPage();
      cancelForm();
    }
  };

  return (
    <Container style={{ paddingLeft: 8, paddingRight: 8 }}>
      <form>
        <Grid container spacing={1}>
          <Typography variant="subtitle2">Upload Profile Image</Typography>
          <Grid item xs={12}>
            <StepLabel step="Step 1:" subtitle="Upload your file:" />

            <Button variant="contained" color="primary" component="label">
              Choose File...
              <input
                id="file"
                type="file"
                accept="image/*"
                hidden
                required
                onChange={handleImageUpload}
              />
            </Button>
          </Grid>

          <Grid item xs={6}>
            <Button variant="outlined" onClick={cancelForm}>
              CANCEL
            </Button>
          </Grid>
          {/* <Grid item xs={6}>
            <Button variant="contained" color="primary" type="submit">
              SAVE
            </Button>
          </Grid> */}
        </Grid>
      </form>
    </Container>
  );
};

export default AvatarForm;
