import React, { useEffect, useState } from "react";
import {
  Paper,
  Avatar,
  Grid,
  Typography,
  Button,
  Fab,
  FormControl,
  Modal,
  TextField,
  Container,
  CircularProgress,
  IconButton,
} from "@mui/material";
import groupBy from "lodash/groupBy";
import isUrl from "is-url";
import { format, startOfDay } from "date-fns";
import { useParams, useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import useStore from "../../store";
import { useQuery } from "../../util";
const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    minWidth: "100%",
    position: "fixed",
  },
  paper: {
    //marginTop: "50%",
    [theme.breakpoints.up("lg")]: {
      marginTop: "10%",
    },
    [theme.breakpoints.up("xl")]: {
      marginTop: "10%",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "10%",
    },
    [theme.breakpoints.between("xs", "sm")]: {
      margin: 10,
      marginTop: "18%",
      // minHeight: "95vh",
      // width: "100%",
      // minHeight: "100vh",
    },

    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    alignItems: "center",
    borderRadius: 20,
    textAlign: "center",
    padding: 20,
  },
  timeSlotButton: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#006aff",
    border: "1px solid rgba(0, 107, 255, 0.5)",
    "&:hover": {
      border: "2px solid #006aff",
      backgroundColor: "#fff",
    },
  },
  avatar: {
    marginTop: theme.spacing(-8),
    width: theme.spacing(15),
    height: theme.spacing(15),
    marginBottom: 10,
  },

  image: {
    maxHeight: 75,
    margin: 5,
  },
  backgroundVideo: {
    zIndex: -1,
    position: "fixed",
  },
}));

const Event = (props) => {
  const { width } = props;
  let isMobile = width === "xs";
  let { id, eventTypeId } = useParams();
  let history = useHistory();
  let query = useQuery();
  let mode = query.get("mode") === "draft" ? "draft" : "published";
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = useState({});
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [dateSelected, setDateSelected] = useState(false);
  const [event, setEvent] = useState(null);
  const [hasVoted, setHasVoted] = useState(false);
  const [form, setForm] = useState({});

  const { enqueueSnackbar } = useSnackbar();
  const { isLoadingPage, fetchAvailabilityEvent, castVote } = useStore(
    (state) => ({
      isLoadingPage: state.isLoadingPage,
      fetchAvailabilityEvent: state.fetchAvailabilityEvent,
      castVote: state.castVote,
    })
  );
  function getModalStyle() {
    return {
      top: "40%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    };
  }
  const [modalStyle] = React.useState(getModalStyle);
  let showSlots = !isMobile || dateSelected;
  let showCalendar = !isMobile || !dateSelected;

  const handleFetchAvailabilityEvent = async () => {
    let { data: availabilityEvent } = await fetchAvailabilityEvent(id);
    //console.log(availabilityEvent);
    availabilityEvent.sortedSlots = groupBy(
      availabilityEvent.slots.filter((s) => !s.archived),
      (slot) => {
        return startOfDay(new Date(slot.start)); //Group the dats by the day
      }
    );
    setEvent(availabilityEvent);
  };
  useEffect(() => {
    handleFetchAvailabilityEvent();
  }, []);
  let page = event?.user?.defaultPage;
  let eventUser = event?.user;
  const handleSubmit = async (e) => {
    if (!form.name || !form.email) {
      enqueueSnackbar("Please fill in the name and email fields", {
        variant: "error",
      });
      return;
    }
    e.preventDefault();
    let { data: availabilitySlot } = await castVote(id, {
      selectedSlots,
      ...form,
    });
    console.log(availabilitySlot);
    setHasVoted(true);
  };

  const handleSelectedDates = (slot) => {
    let alreadyIsSelected = selectedSlots.find((s) => s._id === slot._id);

    if (alreadyIsSelected) {
      setSelectedSlots(selectedSlots.filter((s) => s._id !== slot._id));
    } else {
      setSelectedSlots([...selectedSlots, slot]);
    }
  };
  return (
    <>
      {!event ? (
        <div></div>
      ) : (
        <>
          {page?.backgroundVideo && (
            <video className={classes.backgroundVideo} autoPlay loop muted>
              <source src={page?.backgroundVideo} type="video/mp4" />
            </video>
          )}
          <div
            style={{
              ...(!page?.backgroundVideo && page?.backgroundImage
                ? {
                    backgroundImage: isUrl(page?.backgroundImage)
                      ? `url("${page?.backgroundImage}")`
                      : `url("https://d2ub6d0yniya4m.cloudfront.net/bgimages/${page?.backgroundImage}.png")`,
                    backgroundSize: "100% 100%",
                  }
                : !page?.backgroundVideo
                ? { backgroundColor: page?.color || "#5179a1" }
                : null),
            }}
            className={classes.root}
          >
            <Grid
              justifyContent="center"
              alignItems="center"
              container
              style={{
                maxHeight: "100vh",
                overflowY: "auto",
                padding: 0,
                paddingBottom: 80,
              }}
            >
              <Grid item xs={12} sm={10} md={6} lg={6} xl={4}>
                <Paper className={classes.paper}>
                  <Avatar className={classes.avatar} src={page?.avatar} />
                  <Typography variant="h4">
                    {eventUser.firstName} {eventUser.lastName}
                  </Typography>
                  <Typography style={{ marginTop: 15 }} variant="h4">
                    {event?.name}
                  </Typography>
                  {hasVoted ? (
                    <Container>
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Typography>Your vote has been cast for:</Typography>

                        {selectedSlots.map((s) => (
                          <Grid item xs={12}>
                            <p>
                              <i>
                                {format(
                                  new Date(s.start),
                                  "EEEE, MMMM d 'at' h':'mm a"
                                )}
                              </i>
                            </p>
                          </Grid>
                        ))}

                        <Typography>
                          You will receive an email when the event owner selects
                          a time slot.
                        </Typography>
                      </Grid>
                    </Container>
                  ) : (
                    <>
                      <Typography variant="body1">
                        Please select all available times that work for you.
                      </Typography>
                      <Grid container justifyContent="center" spacing={2}>
                        {Object.keys(event.sortedSlots)
                          .sort((a, b) => Date.parse(a) - Date.parse(b))
                          .map((sortedSlot, index) => (
                            <>
                              <Grid item xs={12}>
                                <Typography variant="h6" key={sortedSlot}>
                                  {format(new Date(sortedSlot), "EEEE, MMMM d")}
                                </Typography>
                                {index === 0 && (
                                  <Typography variant="body2">
                                    {
                                      Intl.DateTimeFormat().resolvedOptions()
                                        .timeZone
                                    }
                                  </Typography>
                                )}
                              </Grid>

                              {event.sortedSlots[sortedSlot].map((slot) => (
                                <Grid item key={slot._id} xs={12}>
                                  <Button
                                    //fullWidth
                                    size="large"
                                    variant="outlined"
                                    style={{
                                      color: selectedSlots.find(
                                        (s) => s._id === slot._id
                                      )
                                        ? "#006aff"
                                        : "#8c8c8c",
                                      minWidth: 300,
                                      border: selectedSlots.find(
                                        (s) => s._id === slot._id
                                      )
                                        ? "4px solid #006aff"
                                        : "1px solid #8c8c8c",
                                    }}
                                    className={classes.timeSlotButton}
                                    onClick={() => handleSelectedDates(slot)}
                                  >
                                    {`${format(
                                      new Date(slot?.start),
                                      "h':'mm a"
                                    )} - ${format(
                                      new Date(slot?.end),
                                      "h':'mm a"
                                    )}`}
                                  </Button>
                                </Grid>
                              ))}
                            </>
                          ))}
                        <Grid item xs={8}>
                          <TextField
                            style={{ minWidth: 250 }}
                            label="Name"
                            placeholder={"John Smith"}
                            variant="outlined"
                            autoComplete="name"
                            InputLabelProps={{ shrink: true }}
                            value={form?.name}
                            onChange={(e) =>
                              setForm({ ...form, name: e.target.value })
                            }
                            required
                          />
                        </Grid>
                        <Grid item xs={8}>
                          <TextField
                            style={{ minWidth: 250 }}
                            placeholder={"Johnsmith@gmail.com"}
                            label="Email"
                            autoComplete="email"
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            required
                            value={form?.email}
                            onChange={(e) =>
                              setForm({ ...form, email: e.target.value })
                            }
                          />
                        </Grid>
                        <Grid item xs={8}>
                          <Button
                            style={{ minWidth: 200 }}
                            variant="contained"
                            label="Submit"
                            color="primary"
                            onClick={handleSubmit}
                          >
                            Submit
                          </Button>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Paper>
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </>
  );
};

export default Event;
