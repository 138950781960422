const generateSignatureHtml = ({
  email = "",
  name = "",
  title = "",
  department = "",
  companyName = "",
  url = "",
  address = "",
  phone = "",
}) => `<table
  cellpadding="0"
  cellspacing="0"
  class="sc-gPEVay eQYmiW"
  style="
    vertical-align: -webkit-baseline-middle;
    font-size: medium;
    font-family: Arial;
  "
>
  <tbody>
    <tr>
      <td>
        <table
          cellpadding="0"
          cellspacing="0"
          class="sc-gPEVay eQYmiW"
          style="
            vertical-align: -webkit-baseline-middle;
            font-size: medium;
            font-family: Arial;
          "
        >
          <tbody>
            <tr>
              <td style="padding: 0px; vertical-align: middle">
                <h3
                  color="#000000"
                  class="sc-fBuWsC eeihxG"
                  style="margin: 0px; font-size: 18px; color: rgb(0, 0, 0)"
                >
                  <span>${name}</span>
                </h3>
                <p
                  color="#000000"
                  font-size="medium"
                  class="sc-fMiknA bxZCMx"
                  style="
                    margin: 0px;
                    color: rgb(0, 0, 0);
                    font-size: 14px;
                    line-height: 22px;
                  "
                >
                  <span>${title}</span>
                </p>
                <p
                  color="#000000"
                  font-size="medium"
                  class="sc-dVhcbM fghLuF"
                  style="
                    margin: 0px;
                    font-weight: 500;
                    color: rgb(0, 0, 0);
                    font-size: 14px;
                    line-height: 22px;
                  "
                >
                  <span>${department}</span><span>&nbsp;|&nbsp;</span
                  ><span>${companyName}</span>
                </p>
                <table
                  cellpadding="0"
                  cellspacing="0"
                  class="sc-gPEVay eQYmiW"
                  style="
                    vertical-align: -webkit-baseline-middle;
                    font-size: medium;
                    font-family: Arial;
                    width: 100%;
                  "
                >
                  <tbody>
                    <tr>
                      <td height="10"></td>
                    </tr>
                    <tr>
                      <td
                        color="#F2547D"
                        direction="horizontal"
                        height="1"
                        class="sc-jhAzac hmXDXQ"
                        style="
                          width: 100%;
                          border-bottom: 1px solid rgb(0,0,0);
                          border-left: none;
                          display: block;
                        "
                      ></td>
                    </tr>
                    <tr>
                      <td height="10"></td>
                    </tr>
                  </tbody>
                </table>
                <table
                  cellpadding="0"
                  cellspacing="0"
                  class="sc-gPEVay eQYmiW"
                  style="
                    vertical-align: -webkit-baseline-middle;
                    font-size: medium;
                    font-family: Arial;
                  "
                >
                  <tbody>
                    <tr height="25" style="vertical-align: middle">
                      <td width="30" style="vertical-align: middle">
                        <table
                          cellpadding="0"
                          cellspacing="0"
                          class="sc-gPEVay eQYmiW"
                          style="
                            vertical-align: -webkit-baseline-middle;
                            font-size: medium;
                            font-family: Arial;
                          "
                        >
                          <tbody>
                            <tr>
                              <td style="vertical-align: bottom">
                                <span
                                  color="#F2547D"
                                  width="11"
                                  class="sc-jlyJG bbyJzT"
                                  style="
                                    display: block;
                            
                                  "
                                  ><img
                                    src="https://ihelpimages.s3.amazonaws.com/icons/Phone/2x/baseline_call_black_24dp.png"
                                    color="#F2547D"
                                    width="13"
                                    class="sc-iRbamj blSEcj"
                                    style="
                                      display: block;
                                 
                                    "
                                /></span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td style="padding: 0px; color: rgb(0, 0, 0)">
                        <a
                          href="tel:${phone}"
                          color="#000000"
                          class="sc-gipzik iyhjGb"
                          style="
                            text-decoration: none;
                            color: rgb(0, 0, 0);
                            font-size: 12px;
                          "
                          ><span>${phone}</span></a
                        >
                      </td>
                    </tr>
                    <tr height="25" style="vertical-align: middle">
                      <td width="30" style="vertical-align: middle">
                        <table
                          cellpadding="0"
                          cellspacing="0"
                          class="sc-gPEVay eQYmiW"
                          style="
                            vertical-align: -webkit-baseline-middle;
                            font-size: medium;
                            font-family: Arial;
                          "
                        >
                          <tbody>
                            <tr>
                              <td style="vertical-align: bottom">
                                <span
                                  color="#F2547D"
                                  width="11"
                                  class="sc-jlyJG bbyJzT"
                                  style="
                                    display: block; 
                                  "
                                  ><img
                                    src="https://ihelpimages.s3.amazonaws.com/icons/Email/2x/baseline_email_black_24dp.png"
                                    color="#F2547D"
                                    width="13"
                                    class="sc-iRbamj blSEcj"
                                    style="
                                      display: block;
                                    "
                                /></span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td style="padding: 0px">
                        <a
                          href="mailto:${email}"
                          color="#000000"
                          class="sc-gipzik iyhjGb"
                          style="
                            text-decoration: none;
                            color: rgb(0, 0, 0);
                            font-size: 12px;
                          "
                          ><span>${email}</span></a
                        >
                      </td>
                    </tr>
                    <tr height="25" style="vertical-align: middle">
                      <td width="30" style="vertical-align: middle">
                        <table
                          cellpadding="0"
                          cellspacing="0"
                          class="sc-gPEVay eQYmiW"
                          style="
                            vertical-align: -webkit-baseline-middle;
                            font-size: medium;
                            font-family: Arial;
                          "
                        >
                          <tbody>
                            <tr>
                              <td style="vertical-align: bottom">
                                <span
                                  color="#F2547D"
                                  width="11"
                                  class="sc-jlyJG bbyJzT"
                                  style="
                                    display: block;
                          
                                  "
                                  ><img
                                    src="https://ihelpimages.s3.amazonaws.com/icons/Link/2x/baseline_link_black_24dp.png"
                                    color="#F2547D"
                                    width="13"
                                    class="sc-iRbamj blSEcj"
                                    style="
                                      display: block;
                                 
                                    "
                                /></span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td style="padding: 0px">
                        <a
                          href="${url}"
                          color="#000000"
                          class="sc-gipzik iyhjGb"
                          style="
                            text-decoration: none;
                            color: rgb(0, 0, 0);
                            font-size: 12px;
                          "
                          ><span>${url}</span></a
                        >
                      </td>
                    </tr>
                    <tr height="25" style="vertical-align: middle">
                      <td width="30" style="vertical-align: middle">
                        <table
                          cellpadding="0"
                          cellspacing="0"
                          class="sc-gPEVay eQYmiW"
                          style="
                            vertical-align: -webkit-baseline-middle;
                            font-size: medium;
                            font-family: Arial;
                          "
                        >
                          <tbody>
                            <tr>
                              <td style="vertical-align: bottom">
                                <span
                                  color="#F2547D"
                                  width="11"
                                  class="sc-jlyJG bbyJzT"
                                  style="
                                    display: block;
                                  "
                                  ><img
                                    src="https://ihelpimages.s3.amazonaws.com/icons/Place/2x/baseline_place_black_24dp.png"
                                    color="#F2547D"
                                    width="13"
                                    class="sc-iRbamj blSEcj"
                                    style="
                                      display: block;
                                    "
                                /></span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td style="padding: 0px">
                        <span
                          color="#000000"
                          class="sc-csuQGl CQhxV"
                          style="font-size: 12px; color: rgb(0, 0, 0)"
                          ><span
                            >${address}</span
                          ></span
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
               
      </td>
    </tr>
  </tbody>
</table>`;
export default generateSignatureHtml;
