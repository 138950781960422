import useStore from "../../store";
import { IconButton } from "@mui/material";
import Edit from "@mui/icons-material/Edit";
const ClickWrapper = ({ children, element }) => {
  const {
    showForm,
    setShowForm,
    formName,
    setFormName,
    setElementToUpdate,
    elementToUpdate,
    //clearForm,
  } = useStore((state) => ({
    showForm: state.showForm,
    setShowForm: state.setShowForm,
    formName: state.formName,
    setFormName: state.setFormName,
    setElementToUpdate: state.setElementToUpdate,
    elementToUpdate: state.elementToUpdate,
  }));
  let handleClick = (e) => {
    e.preventDefault();
    if (showForm === true) {
      setShowForm(false);
      setElementToUpdate(null);
    } else {
      setShowForm(true);
      setFormName(element.elementType);
      setElementToUpdate(element);
    }
  };
  return (
    <span onClick={handleClick}>
      {children}
      <IconButton
        aria-label="edit"
        size="small"
        onClick={handleClick}
        style={{ marginBottom: 30, marginLeft: -25, backgroundColor: "#fff" }}
      >
        <Edit style={{ fontSize: "1.05em" }} />
      </IconButton>
    </span>
  );
};

export default ClickWrapper;
