// index.js
import { useEffect, useState } from "react";
import axios from "axios";
//import { DailyProvider } from "@daily-co/daily-react-hooks";
import DailyIframe from "@daily-co/daily-js";
import { useParams } from "react-router-dom";
import DefaultLoadingScreen from "../../components/DefaultLoadingScreen";
import useStore from "../../store";
const Meeting = () => {
  // Create an instance of the Daily call object

  const { pageId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const user = useStore((state) => state.user);
  const page = useStore((state) => state.page);
  const fetchMeeting = useStore((state) => state.fetchMeeting);
  console.log(user);

  const fetchMeetingUrl = async () => {
    try {
      let { data: meeting } = await fetchMeeting(pageId);
      setIsLoading(false);
      const callFrame = DailyIframe.createFrame({
        iframeStyle: {
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        },
      });
      await callFrame.join({
        url: meeting.url,
        showLeaveButton: true,
        ...(meeting?.token ? { token: meeting?.token } : {}),
      });
    } catch (e) {
      setIsLoading(false);
      console.log(e?.response?.data || e);
    }
  };

  useEffect(() => {
    fetchMeetingUrl();
  }, []);

  return <div>{isLoading && <DefaultLoadingScreen />}</div>;
};

// const MeetingWithProvider = () => {
//   const co = DailyIframe.createCallObject();
//   <DailyProvider callObject={co}>
//     <Meeting />
//   </DailyProvider>;
// };
export default Meeting;
