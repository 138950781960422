import {
  Typography,
  TextField,
  Grid,
  Button,
  Container,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { useState, useEffect } from "react";
import StepLabel from "../../../components/StepLabel";
import EventTypeSelect from "../../../components/EventTypeSelect";
import GoogleButton, {
  MicrosoftButton,
} from "../../../components/OauthButtons";
import { CalendarTypeSelect } from "../../../components/CalendarsSelect";
import MoveElement from "../../../components/MoveElement";
import useStore from "../../../store";
const ScheduleMeeting = ({ onSubmit, cancelForm, onRemove }) => {
  const elementToUpdate = useStore((state) => state.elementToUpdate);
  const fetchEventType = useStore((state) => state.fetchEventType);
  const user = useStore((state) => state.user);
  let [localForm, setLocalForm] = useState({
    elementType: "ScheduleMeeting",
    defaultPosition: "dropZone1",
    value: elementToUpdate ? elementToUpdate.value : null,
    label: elementToUpdate ? elementToUpdate.label : "Schedule Meeting",
    eventType: elementToUpdate ? elementToUpdate.eventType : null,
  });

  const handleEventTypeFetch = async () => {
    let { data: eventType } = await fetchEventType(elementToUpdate.eventType);
    console.log(eventType);
    setLocalForm({
      ...localForm,
      calendarType: eventType.calendarType,
    });
  };
  useEffect(() => {
    if (elementToUpdate) {
      handleEventTypeFetch();
    }
  }, [elementToUpdate]);
  let [showOther, setShowOther] = useState(
    elementToUpdate?.value ? true : false
  );
  function handleSchedulerType(e) {
    console.log(e);
    setShowOther(!showOther);
  }
  function handleSubmit(e) {
    let modifiedForm = { ...localForm };
    if (!showOther) {
      modifiedForm.value = null;
    }
    onSubmit(e, modifiedForm);
  }
  const handleEventSelectionLoaded = (eventTypes) => {
    if (!elementToUpdate) {
      setLocalForm({
        ...localForm,
        eventType: eventTypes[0]._id,
      });
    }
  };
  return (
    <Container style={{ paddingLeft: 8, paddingRight: 8 }}>
      <form style={{ width: "100%" }} onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              {elementToUpdate ? "Update" : "Create"} Schedule Meeting Button
            </Typography>
            <StepLabel step="Step 1:" subtitle="Choose a scheduling service" />
            <FormControl variant="outlined" style={{ width: "100%" }}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={showOther}
                onChange={handleSchedulerType}
                fullWidth
              >
                <MenuItem value={false}>Use i.help scheduling service</MenuItem>
                <MenuItem value={true}>Use other scheduling service</MenuItem>
              </Select>
            </FormControl>
            {/* <Button
              size="small"
              color="primary"
              style={{ marginTop: 10, marginBottom: -20 }}
              onClick={() => setShowOther(!showOther)}
            >
              {!showOther
                ? `Use my existing scheduling service *`
                : `Use i.help scheduling service`}
            </Button> */}
          </Grid>
          {!showOther && (
            <Grid item xs={12}>
              <CalendarTypeSelect
                fullWidth
                onChange={(e) =>
                  setLocalForm({ ...localForm, calendarType: e.target.value })
                }
                value={localForm?.calendarType}
              />
            </Grid>
          )}

          {!showOther && (
            <>
              {!user.hasGoogleConnection &&
                localForm?.calendarType === "google" && (
                  <Grid item xs={12}>
                    <p style={{ fontWeight: 700 }}>Step 2: </p>
                    <GoogleButton buttonText="Connect to Google" />
                  </Grid>
                )}
              {!user.hasMicrosoftConnection &&
                localForm?.calendarType === "microsoft" && (
                  <Grid item xs={12}>
                    <p style={{ fontWeight: 700 }}>Step 2: </p>
                    <MicrosoftButton buttonText="Connect to Microsoft" />
                  </Grid>
                )}
            </>
          )}

          {!showOther &&
            (user.hasGoogleConnection || user.hasMicrosoftConnection) && (
              <Grid item xs={12}>
                <StepLabel
                  step="Step 2:"
                  subtitle="Pick the Event You Want Scheduled"
                />
                <EventTypeSelect
                  calendarType={localForm.calendarType || "google"}
                  onChange={(e) =>
                    setLocalForm({ ...localForm, eventType: e.target.value })
                  }
                  value={localForm.eventType}
                  onSelectionLoaded={handleEventSelectionLoaded}
                />
              </Grid>
            )}
          {showOther && (
            <Grid item xs={12}>
              <StepLabel step="Step 2:" subtitle="Enter your scheduling URL" />
              <TextField
                variant="outlined"
                value={localForm.value}
                fullWidth
                placeholder="https://calendly.com/j/92994"
                onChange={(e) =>
                  setLocalForm({ ...localForm, value: e.target.value })
                }
              />
            </Grid>
          )}
          {!showOther &&
            (user.hasGoogleConnection || user.hasMicrosoftConnection) && (
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => (window.location = "/p/edit/eventtypes")}
                >
                  Edit my Events
                </Button>
              </Grid>
            )}

          <Grid item xs={12}>
            <StepLabel
              step="Step 3:"
              subtitle="What would you like the button to say?"
            />
            <TextField
              variant="outlined"
              fullWidth
              value={localForm.label}
              onChange={(e) =>
                setLocalForm({ ...localForm, label: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={6}>
            <Button variant="outlined" fullWidth onClick={cancelForm}>
              CANCEL
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              disabled={!localForm.value && !localForm.eventType}
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
            >
              SAVE
            </Button>
          </Grid>
          {elementToUpdate && (
            <Container>
              <Button
                onClick={onRemove}
                fullWidth
                style={{ color: "red" }}
                variant="text"
              >
                REMOVE BUTTON
              </Button>
              <MoveElement element={elementToUpdate} />
            </Container>
          )}
        </Grid>
      </form>
    </Container>
  );
};

export default ScheduleMeeting;
