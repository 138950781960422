import React, { useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import { Typography, Grid, Link, Divider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useHistory, useLocation, useParams } from "react-router-dom";
import ihelpLogo from "../../assets/ihelp_logo.svg";
import { EmailOutlined } from "@mui/icons-material";
import GoogleButton, { MicrosoftButton } from "../../components/OauthButtons";
import useStore from "../../store";
import { getPlanByName } from "./plans";
const baseUrl =
  process.env.REACT_APP_BASE_URL || process.env.REACT_APP_DEV_ENDPOINT;
// function Copyright() {
//   return (
//     <Typography variant="body2" color="textSecondary" align="center">
//       {"Copyright © "}
//       <Link color="inherit" href="https://material-ui.com/">
//         Your Website
//       </Link>{" "}
//       {new Date().getFullYear()}
//       {"."}
//     </Typography>
//   );
// }

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  socialAuthButton: {
    color: "#5d7594",
    borderColor: "#5d7594",
  },
  brandIcon: {
    maxHeight: 25,
    marginRight: 20,
  },
  oauthButton: {
    color: "#fff",
    marginBottom: 20,
    fontWeight: 1000,
  },
}));

export default function SignUp(props) {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const classes = useStyles();
  const history = useHistory();
  const query = useQuery();
  const { strategy, planName } = useParams();
  const code = query.get("code");
  let [signupForm, setSignupForm] = useState({});
  let [isSubmitting, setIsSubmitting] = useState(false);
  let [oauthLoading, setOauthLoading] = useState(true);
  let [showForm, setShowForm] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  //const setUser = useStore((state) => state.setUser);
  const refreshUser = useStore((state) => state.refreshUser);
  const runAutoCreate = useStore((state) => state.runAutoCreate);
  const signupWithGoogle = useStore((state) => state.signupWithGoogle);
  const signupWithMicrosoft = useStore((state) => state.signupWithMicrosoft);
  const createElement = useStore((state) => state.createElement);
  const hasGoogleCalendarScope = useStore(
    (state) => state.hasGoogleCalendarScope
  );
  const signupWithLinkedin = useStore((state) => state.signupWithLinkedin);
  const createStripeCheckoutSession = useStore(
    (state) => state.createStripeCheckoutSession
  );
  const plan = planName || query.get("plan");
  let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  React.useEffect(() => {
    document.title = "Sign Up";

    if (strategy === "linkedin" && code) {
      handleLinkedinCode(code);
    }

    if (strategy === "microsoft" && code) {
      handleMicrosoftCode(code);
    }

    if (strategy === "google" && code) {
      let googleAttempt = localStorage.getItem("googleAttempt");
      googleAttempt = googleAttempt ? Number(googleAttempt) + 1 : 1;
      localStorage.setItem("googleAttempt", googleAttempt);
      let bypassCalendar = Number(googleAttempt) >= 2;
      handleGoogleCode(code, bypassCalendar);
    }

    if (!code) {
      setOauthLoading(false);
    }
  }, []);

  async function handleMicrosoftCode(code) {
    try {
      setOauthLoading(true);
      let request = await signupWithMicrosoft(
        code,
        window.location.origin + window.location.pathname,
        timeZone
      );

      window.Upscope("updateConnection", {
        // Set the user ID below. If you don't have one, set to undefined.
        uniqueId: request.data.user.email,
        // Set the user name or email below (e.g. ["John Smith", "john.smith@acme.com"]).
        identities: [
          request.data.user.firstName + " " + request.data.user.lastName,
        ],
      });
      handleRedirect(request.data);
    } catch (err) {
      setOauthLoading(false);
      console.log(err?.response?.data?.error?.message, err?.response?.status);
      enqueueSnackbar(
        err?.response?.data?.error?.message ||
          "Error creating new account with Microsoft.",
        { variant: "error" }
      );
    }
  }
  async function handleGoogleCode(code, bypassCalendar) {
    try {
      setOauthLoading(true);
      let request = await signupWithGoogle(
        code,
        window.location.origin + window.location.pathname,
        timeZone,
        bypassCalendar
      );

      window.Upscope("updateConnection", {
        // Set the user ID below. If you don't have one, set to undefined.
        uniqueId: request.data.user.email,
        // Set the user name or email below (e.g. ["John Smith", "john.smith@acme.com"]).
        identities: [
          request.data.user.firstName + " " + request.data.user.lastName,
        ],
      });
      handleRedirect(request.data);
    } catch (err) {
      setOauthLoading(false);
      console.log(err?.response?.data?.error?.message, err?.response?.status);
      if (
        err?.response?.data?.error?.message ===
        "Calendar permissions were not granted please try again."
      ) {
        history.push("/google/scope");
      }
      enqueueSnackbar(
        err?.response?.data?.error?.message ||
          "Error creating new account with Google.",
        { variant: "error" }
      );
    }
  }

  async function handleLinkedinCode(code) {
    console.log(code, window.location.href);
    try {
      setIsSubmitting(true);
      let request = await signupWithLinkedin(
        code,
        window.location.origin + window.location.pathname,
        timeZone
      );
      delete request.data.user.google;
      window.Upscope("updateConnection", {
        // Set the user ID below. If you don't have one, set to undefined.
        uniqueId: request.data.user.email,
        // Set the user name or email below (e.g. ["John Smith", "john.smith@acme.com"]).
        identities: [
          request.data.user.firstName + " " + request.data.user.lastName,
        ],
      });
      handleRedirect(request.data);
    } catch (err) {
      setIsSubmitting(false);
      console.log(err?.response?.data?.error?.message, err?.response?.status);
      enqueueSnackbar(
        err?.response?.data?.error?.message ||
          "Error creating new account with Linkedin.",
        { variant: "error" }
      );
    }
  }
  async function submitSignupForm(e, snapshot) {
    console.log("submitSignupForm was called");
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const signupRequest = await axios({
        method: "post",
        url: `${baseUrl}/v1/users`,
        data: {
          username: snapshot.email,
          defaultTimeZone: timeZone,
          ...snapshot,
          referralPage: localStorage.getItem("referralPage"),
        },
      });
      handleRedirect(signupRequest.data);
    } catch (err) {
      setIsSubmitting(false);
      console.log(err?.response?.data?.error?.message, err?.response?.status);
      if (
        err?.response?.data?.error?.message ===
        "users validation failed: email: This email has already been used."
      ) {
        enqueueSnackbar(
          "This email has already been used. Please use another email or log in.",
          {
            variant: "error",
            action: () => (
              <>
                <Button
                  variant="outlined"
                  style={{ color: "black", borderColor: "black" }}
                  href="/login"
                >
                  Log In
                </Button>
              </>
            ),
          }
        );
      } else {
        enqueueSnackbar(
          err?.response?.data?.error?.message || "Error creating new account.",
          { variant: "error" }
        );
      }
    }
  }

  async function handleRedirect(response) {
    //setUser(response.user);
    localStorage.setItem("token", response.token);
    localStorage.setItem("pageId", response.user.defaultPage);
    localStorage.setItem("authenticatedAt", new Date());
    refreshUser(); //This will set the user in the store and grab the org info
    if (
      strategy === "google" &&
      code &&
      hasGoogleCalendarScope(response.user)
    ) {
      await runAutoCreate("google");
    }
    if (strategy === "microsoft" && code) {
      await runAutoCreate("microsoft");
    }
    if (!strategy) {
      await createElement({
        elementType: "WebConference",
        label: "Join Meeting",
        defaultPosition: "dropZone1",
        value: `https://i.help/${response.page.name}/meet`,
      });
    }
    // Always create a Upload File btn
    await createElement({
      elementType: "UploadFile",
      label: "Send Me a File",
      defaultPosition: "dropZone1",
    });

    if (plan) {
      let returnedPlan = getPlanByName(plan);
      console.log({ returnedPlan });
      let { data } = await createStripeCheckoutSession({
        price: returnedPlan.id,
      });
      setIsSubmitting(false);
      setOauthLoading(false);
      window.location = data.session.url;
    } else {
      setIsSubmitting(false);
      setOauthLoading(false);
      history.push("/billing");
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      {!oauthLoading && (
        <div className={classes.paper}>
          <Grid container>
            <Grid
              item
              xs={12}
              style={{ textAlign: "center", marginTop: "20%" }}
            >
              <img src={ihelpLogo} alt="ihelplogo" />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                style={{ textAlign: "center", marginBottom: 5 }}
              >
                Sign up faster using Google.
              </Typography>
              <GoogleButton
                redirectURL={`${window.location.origin}/signup/google${
                  plan ? "/" + plan : ""
                }`}
                requireConsentPrompt
                buttonText="Sign Up with Google"
                variant="signup"
                buttonStyles={{
                  color: "#fff",
                  marginBottom: 20,
                  fontWeight: 1000,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <MicrosoftButton
                variant="signup"
                buttonText="Sign Up with Microsoft"
                redirectURL={`${window.location.origin}/signup/microsoft${
                  plan ? "/" + plan : ""
                }`}
                buttonStyles={{
                  color: "#fff",
                  marginBottom: 20,
                  fontWeight: 1000,
                }}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <Button
                href={`https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${
                  process.env.REACT_APP_LINKEDIN_CLIENT_ID
                }&redirect_uri=${window.location.origin}/signup/linkedin${
                  plan ? "/" + plan : ""
                }&scope=r_liteprofile%20r_emailaddress`}
                variant="outlined"
                fullWidth
              >
                <img
                  className={classes.brandIcon}
                  src={linkedinLogo}
                  alt="Linkedin Logo"
                />
                Sign Up with LinkedIn
              </Button>
            </Grid> */}

            <Grid item xs={5}>
              <Divider style={{ marginTop: 15 }} />
            </Grid>
            <Grid item xs={2} style={{ textAlign: "center" }}>
              OR
            </Grid>
            <Grid item xs={5}>
              <Divider style={{ marginTop: 15 }} />
            </Grid>
          </Grid>

          {showForm ? (
            <form
              className={classes.form}
              onSubmit={(e) => submitSignupForm(e, signupForm)}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    autoComplete="fname"
                    name="firstName"
                    variant="outlined"
                    required
                    fullWidth
                    id="firstName"
                    disabled={isSubmitting}
                    label="First Name"
                    autoFocus
                    onChange={(e) =>
                      setSignupForm({
                        ...signupForm,
                        firstName: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    required
                    fullWidth
                    id="lastName"
                    disabled={isSubmitting}
                    label="Last Name"
                    name="lastName"
                    autoComplete="family-name"
                    onChange={(e) =>
                      setSignupForm({ ...signupForm, lastName: e.target.value })
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    disabled={isSubmitting}
                    label="Email Address"
                    name="email"
                    type="email"
                    autoComplete="email"
                    onChange={(e) =>
                      setSignupForm({
                        ...signupForm,
                        email: e.target.value.toLowerCase().trim(),
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    disabled={isSubmitting}
                    autoComplete="current-password"
                    onChange={(e) =>
                      setSignupForm({ ...signupForm, password: e.target.value })
                    }
                  />
                </Grid>
                {/* <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox value="terms" color="primary" required />}
                  label="I agree to the terms and conditions."
                />
              </Grid> */}
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                {isSubmitting ? <CircularProgress /> : "Sign Up"}
              </Button>
            </form>
          ) : (
            <>
              <Typography
                variant="h6"
                style={{ textAlign: "center", marginTop: 20, marginBottom: 5 }}
              >
                Don't have Google? Use another email instead.
              </Typography>
              <Button
                fullWidth
                variant="outlined"
                style={{ fontWeight: 500 }}
                onClick={() => setShowForm(true)}
              >
                <EmailOutlined style={{ marginRight: 10 }} />
                Sign Up with Email
              </Button>
            </>
          )}
          <Link href="/login" variant="body2" style={{ marginTop: 15 }}>
            {"Already have an account? Log In"}
          </Link>
        </div>
      )}
    </Container>
  );
}
