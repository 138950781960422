let StepLabel = ({ step, subtitle, link, linkText, style }) => (
  <p style={{ fontWeight: 700, ...style }}>
    {step} <br />
    <span style={{ fontWeight: 400 }}>{subtitle}</span>
    <br />
    {link && (
      <a
        href={link}
        style={{ fontSize: 10 }}
        alt="whats this"
        target="_blank"
        rel="noopener noreferrer"
      >
        {linkText || "What's this?"}
      </a>
    )}
  </p>
);

export default StepLabel;
