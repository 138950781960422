import React, { useState } from "react";
import QRCode from "react-qr-code";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import {
  Modal,
  Paper,
  Button,
  Typography,
  Grid,
  Divider,
  TextField,
  LinearProgress,
} from "@mui/material";
import BackgroundSquare from "./BackgroundSquare";
import squares from "./Squares";
import axios from "axios";
import useStore from "../store";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    //margin: "auto",
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    maxWidth: "100vw",
    backgroundColor: theme.palette.background.paper,
    //border: "2px solid #000",
    borderRadius: 5,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function CallMeModal({ open, handleClose, element }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={{ diplay: "grid" }}>
        <Grid
          container
          spacing={2}
          style={modalStyle}
          className={classes.paper}
        >
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <a href={`tel:${element.value}`} style={{ textDecoration: "none" }}>
              <Typography variant="h6" style={{ fontWeight: "bold" }}>
                {`Click here to dial ${element.value} or`}
              </Typography>
            </a>
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <QRCode size={128} value={`tel:${element.value}`} level={"M"} />
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Typography variant="body1" style={{ fontWeight: "bold" }}>
              {`Scan the QR code with your camera app to dial with your smartphone.`}
            </Typography>
          </Grid>
          <Grid item xs={12} container justifyContent="flex-end">
            <Button variant="contained" onClick={handleClose}>
              Close
            </Button>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
}
