import React from "react";
import { makeStyles } from "@mui/styles";
import {
  MenuItem,
  InputLabel,
  ListItemText,
  FormControl,
  Checkbox,
  Chip,
  Select,
  TextField,
} from "@mui/material";
import googleLogo from "../assets/SmallGoogleLogo.png";
import microsoftLogo from "../assets/ms-logo.svg";
import useStore from "../store";
const useStyles = makeStyles((theme) => ({
  formControl: {
    //margin: theme.spacing(1),
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
}));

export default function MultiCalendarsSelect({
  onChange,
  value,
  calendars = [],
}) {
  const classes = useStyles();

  let menuArray = [];

  for (const calendar of calendars) {
    menuArray.push(
      <MenuItem key={calendar.id} value={calendar}>
        <Checkbox
          color="primary"
          checked={value.findIndex((val) => val.id === calendar.id) > -1}
        />
        <ListItemText primary={calendar.summary || calendar.name} />
      </MenuItem>
    );
  }
  function findValueInCalendars(values = []) {
    let result = [];
    for (const value of values) {
      const calendar = calendars.find((calendar) => calendar.id === value.id);
      if (calendar) {
        result.push(calendar);
      }
    }
    return result;
  }

  return (
    <FormControl variant="outlined" fullWidth className={classes.formControl}>
      <InputLabel id="multi-calendar-select" htmlFor="calendars">
        Calendars to Check for Conflicts
      </InputLabel>
      <Select
        label="Calendars to Check for Conflicts"
        labelId="multi-calendar-select"
        id="calendars"
        multiple
        placeholder="Select calendar(s)."
        onChange={onChange}
        value={findValueInCalendars(value)}
        renderValue={(selected) => (
          <div className={classes.chips}>
            {selected.map((value) => (
              <Chip
                key={value.id}
                label={value.summary || value.name}
                className={classes.chip}
              />
            ))}
          </div>
        )}
      >
        {menuArray}
      </Select>
    </FormControl>
  );
}

export function SingleCalendarSelect({ onChange, value, calendars = [] }) {
  let menuArray = [];

  for (const calendar of calendars) {
    menuArray.push(
      <MenuItem key={calendar.id} value={calendar.id}>
        {calendar.summary || calendar.name}
      </MenuItem>
    );
  }
  return (
    <FormControl style={{ minWidth: 300 }}>
      <InputLabel id="single-calendar-select">Calendar to Add Event</InputLabel>
      <Select
        labelWidth="150px"
        labelId="single-calendar-select"
        label="Calendar to Add Event"
        id="single-calendar-select"
        value={value}
        variant="outlined"
        onChange={onChange}
        //defaultValue={calendars.find((cal) => cal.primary)?.id}
        fullWidth
      >
        {menuArray}
      </Select>
    </FormControl>
  );
}

export function CalendarTypeSelect({ onChange, value, fullWidth = false }) {
  let user = useStore((state) => state.user);
  let menuArray = [
    <MenuItem key={"google"} value={"google"}>
      <img
        style={{ maxHeight: 15, marginRight: 10 }}
        alt="google logo"
        src={googleLogo}
      />{" "}
      Google
    </MenuItem>,
    <MenuItem
      key={"microsoft"}
      value={"microsoft"}
      disabled={!user?.hasMicrosoftConnection}
    >
      <img
        style={{ maxHeight: 15, marginRight: 10 }}
        alt="microsoft logo"
        src={microsoftLogo}
      />{" "}
      Microsoft
    </MenuItem>,
  ];

  return (
    // <FormControl
    //   fullWidth={fullWidth}
    //   variant="outlined"
    //   style={{ minWidth: 200 }}
    // >
    //   <InputLabel >Calendar Type</InputLabel>
    //   <Select
    //     labelWidth={100}
    //     labelId="calendar-types-select"
    //     label="Calendar Type"
    //     value={value}
    //     onChange={onChange}
    //   >
    //     {menuArray}
    //   </Select>
    // </FormControl>
    <TextField
      id="calendar-types-select"
      select
      style={{ minWidth: 200 }}
      label="Calendar Type"
      value={value || "google"}
      onChange={onChange}
    >
      {menuArray}
    </TextField>
  );
}
