//Fetch the page by mongo id and redirect to URL with name
import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import useStore from "../../store";

const PageRedirect = () => {
  let history = useHistory();
  let { pageId } = useParams();
  const fetchPageRequest = useStore((state) => state.fetchPageRequest);

  useEffect(() => {
    const handlePageFetch = async () => {
      try {
        let pageRequest = await fetchPageRequest(pageId, "mongo", "published");
        console.log(pageRequest);
        let page = pageRequest.data;
        history.push(`/${page?.name}`);
      } catch (e) {
        console.log(e?.response?.data || e);
      }
    };
    handlePageFetch();
  }, []);

  return null;
};

export default PageRedirect;
