import { useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Backdrop, Typography } from "@mui/material";
import useStore from "../../store";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: "#2e8cff",
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
let Connect = () => {
  const classes = useStyles();
  const connectWithZoom = useStore((state) => state.connectWithZoom);
  const refreshUser = useStore((state) => state.refreshUser);
  const query = useQuery();
  const { strategy } = useParams();
  let history = useHistory();

  async function handleZoom(code) {
    await connectWithZoom(code);
    refreshUser();
    history.push("/p/edit");
  }

  useEffect(() => {
    let code = query.get("code");
    if (strategy === "zoom" && code) {
      handleZoom(code);
    }
  });
  return (
    <div>
      <Backdrop className={classes.backdrop} open>
        <Typography variant="h3">Connecting to {strategy}...</Typography>
      </Backdrop>
    </div>
  );
};

export default Connect;
