import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  Container,
  Button,
  IconButton,
  LinearProgress,
  Stack,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";
import { DeleteOutline, CloudDownloadOutlined } from "@mui/icons-material";
import filesize from "filesize";
import { useSnackbar } from "notistack";
import { format } from "date-fns";
import { Edit } from "@mui/icons-material";
import useStore from "../../store";
import SchedulerNavbar from "../../components/SchedulerNavbar";
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(2),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  formHeader: { marginBottom: theme.spacing(2) },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  dataTable: {
    "& .uneditableCell": {
      "&:focus": {
        outline: "none !important",
      },
    },
  },
}));

function FileUploads() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  let [isLoading, setIsLoading] = useState(false);
  let [rows, setRows] = useState([]);
  let [selectedRows, setSelectedRows] = useState([]);
  let user = useStore((state) => state.user);
  let updateUser = useStore((state) => state.updateUser);
  let refreshUser = useStore((state) => state.refreshUser);
  let fetchUploads = useStore((state) => state.fetchUploads);
  let updateUpload = useStore((state) => state.updateUpload);
  let deleteUpload = useStore((state) => state.deleteUpload);

  let generatePublicUrl = useStore((state) => state.generatePublicUrl);
  let isPremiumUser = useStore((state) => state.isPremiumUser);
  const handleUploadsFetch = async () => {
    let { data: uploads } = await fetchUploads(user.id);
    let filteredUploads = uploads.filter(
      (upload) => upload.uploadCompleted && !upload.deleted
    );

    for (let [i, upload] of filteredUploads.entries()) {
      upload.id = upload._id;
      upload.createdAt = format(new Date(upload.createdAt), "MM/dd/yyyy");
      if (upload.size) {
        upload.size = filesize(upload.size);
      }
      if (!isPremiumUser(user) && i > 2) {
        upload.blockDownload = true;
      }
    }
    setRows(filteredUploads);
  };

  const handleEditStart = (e, params) => {
    let { api, row } = params;
    api.setCellMode(row.id, "description", "edit");
    console.log({ e, params });
  };

  const handleCellUpdate = async (e) => {
    console.log(e);
    await updateUpload(e.id, {
      [e.field]: e.value,
    });
    handleUploadsFetch();
  };

  const handleCopyLink = (publicId) => {
    let url = `${window.location.origin}/p/public/files/${publicId}`;
    navigator.clipboard.writeText(url);
    enqueueSnackbar(`Link copied to clipboard.`, { variant: "success" });
  };

  const handleGeneratePublicUrl = async (uploadId) => {
    setIsLoading(true);
    let { data: upload } = await generatePublicUrl(uploadId);
    setIsLoading(false);
    handleCopyLink(upload.publicUrl);
    handleUploadsFetch();
  };
  useEffect(() => {
    document.title = "File Uploads";
    handleUploadsFetch();
  }, []);

  const columnWidth = 200;

  const columns = [
    {
      field: "filename",
      headerName: "File Name",
      width: columnWidth,
    },
    {
      field: "description",
      headerName: "Description",
      width: columnWidth,
      editable: true,

      renderCell: (params) => (
        <Grid
          style={{ display: "flex", width: "100%", cursor: "pointer" }}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={5}>
            <Typography variant="body2">
              {params.value.length > 25
                ? params.value.substring(0, 25) + "..."
                : params.value}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton
              onClick={(e) => handleEditStart(e, params)}
              size="small"
            >
              <Edit />
            </IconButton>
          </Grid>
        </Grid>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      width: columnWidth,
    },
    {
      field: "size",
      headerName: "Size",
      width: 100,
    },
    {
      field: "createdAt",
      headerName: "Uploaded At",
      width: 100,
    },
    {
      field: "publicUrl",
      headerName: "Sharing",
      width: columnWidth,
      editable: false,

      renderCell: (params) =>
        console.log(params) || (
          <Grid
            style={{ display: "flex", width: "100%", cursor: "pointer" }}
            justifyContent="space-between"
            alignItems="center"
          >
            {params.value ? (
              <Button
                variant="outlined"
                size="small"
                onClick={() => handleCopyLink(params.value)}
              >
                {" "}
                Copy Public Link
              </Button>
            ) : (
              <Button
                variant="outlined"
                size="small"
                onClick={() => handleGeneratePublicUrl(params.row._id)}
              >
                Create Public Link
              </Button>
            )}
          </Grid>
        ),
    },
  ];
  let sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const downloadFile = (dataurl, filename) => {
    const link = document.createElement("a");
    link.href = dataurl;
    link.download = filename;
    link.click();
  };

  const handleDownloads = async () => {
    console.log(selectedRows);

    for (let row of selectedRows) {
      row = rows.find((r) => row === r.id);
      downloadFile(row.downloadUrl, row.filename);
      await sleep(2000);
    }
  };

  const handleFileDelete = async () => {
    if (
      window.confirm(
        `Do you really want to delete ${selectedRows.length} file(s)?`
      )
    ) {
      for (let row of selectedRows) {
        await deleteUpload(row);
      }
      handleUploadsFetch();
      enqueueSnackbar(`Successfully deleted.`, {
        variant: "success",
      });
    }
  };

  return (
    <SchedulerNavbar hideAddNewMeeting={true} title="File Manager">
      <Container component="main" maxWidth="lg">
        <Grid container xs={12} spacing={2}>
          <Grid container item md={8} xs={8}>
            <Typography variant="h4">Uploaded Files</Typography>
          </Grid>
          <Grid container item md={2} xs={2}>
            <Button
              fullWidth
              disabled={selectedRows.length === 0}
              variant="contained"
              startIcon={<DeleteOutline />}
              onClick={handleFileDelete}
              style={
                selectedRows.length !== 0
                  ? {
                      backgroundColor: "red",
                      color: "#fff",
                    }
                  : {}
              }
            >
              Delete
            </Button>
          </Grid>
          <Grid container item md={2} xs={2}>
            <Button
              fullWidth
              disabled={selectedRows.length === 0}
              color="primary"
              variant="contained"
              startIcon={<CloudDownloadOutlined />}
              onClick={() => handleDownloads(selectedRows)}
            >
              Download
            </Button>
          </Grid>
          {!isPremiumUser(user) && (
            <>
              <Grid item xs={8}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ width: "20%" }}>
                    <Typography variant="span">Free Storage:</Typography>
                  </Box>
                  <Box sx={{ width: "80%", mr: 1 }}>
                    <LinearProgress
                      value={rows.length >= 3 ? 100 : rows.length * 33}
                      variant="determinate"
                    />
                  </Box>
                  <Box sx={{ width: "20%" }}>
                    <Typography
                      variant="span"
                      style={rows.length >= 3 ? { color: "red" } : {}}
                    >
                      {rows.length} of 3 files used
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid
                container
                justifyContent="center"
                item
                xs={4}
                spacing={1}
                style={{ marginBottom: 10 }}
              >
                <Button
                  style={{ borderRadius: 15, fontWeight: 700, marginTop: 10 }}
                  variant="contained"
                  color="primary"
                  href="/billing"
                >
                  Upgrade to PRO to Download Unlimited Files
                </Button>
              </Grid>
            </>
          )}
        </Grid>
        <div style={{ height: "70vh", width: "100%", marginTop: 10 }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={20}
            pagination
            className={classes.dataTable}
            onSelectionModelChange={(selectionModel) =>
              setSelectedRows(selectionModel)
            }
            isRowSelectable={({ row }) => !row.blockDownload}
            onCellEditCommit={handleCellUpdate}
            checkboxSelection
            // disableSelectionOnClick
            getCellClassName={(params) => {
              if (params.field !== "description") {
                return "uneditableCell";
              }
            }}
            components={{
              NoRowsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  No Files
                </Stack>
              ),
            }}
          />
        </div>
      </Container>
    </SchedulerNavbar>
  );
}

export default FileUploads;
