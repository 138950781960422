import * as React from "react";
import {
  Divider,
  Collapse,
  IconButton,
  Box,
  List,
  ListItemButton,
  ListItem,
  ListItemText,
  Button,
} from "@mui/material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSnackbar } from "notistack";
import {
  ExpandMore,
  ExpandLess,
  FileCopyOutlined,
  OpenInNew,
  Edit,
} from "@mui/icons-material";
import useStore from "../../store";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  accordianButton: {
    backgroundColor: "#eff8ff",
    fontWeight: "700",
    color: "#1e79d7",
  },
}));
export default function DrawerMenu({
  pageUrl,
  accountItems,
  widgets,
  page,
  drawerOpen,
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [openWidgets, setOpenWidgets] = React.useState(true);
  const [openAccountSettings, setOpenAccountSettings] = React.useState(true);

  const { setShowPageNameChangeModal } = useStore((state) => ({
    setShowPageNameChangeModal: state.setShowPageNameChangeModal,
  }));

  const handleClick = (menuName) => {
    if (menuName === "widgets") {
      setOpenWidgets(!openWidgets);
    }

    if (menuName === "settings") {
      setOpenAccountSettings(!openAccountSettings);
    }
  };

  return (
    <List
      sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      <ListItem
        style={{ marginTop: -7, height: 20 }}
        className={classes.accordianButton}
      >
        {/* <ListItemButton
          className={classes.accordianButton}
          onClick={() => handleClick("widgets")}
        > */}
        <ListItemText
          primaryTypographyProps={{
            style: {
              fontWeight: "700",
              color: "#1e79d7",
              backgroundColor: "#eff8ff",
            },
          }}
          primary="MY PUBLIC LINK"
        />
        {/* </ListItemButton> */}
      </ListItem>
      <ListItem
        style={{
          //border: "5px #1e79d7 solid",
          height: 50,
        }}
      >
        <Typography variant="body1" className={classes.title}>
          <Box display="inline" fontWeight="fontWeightBold">
            {/* <Typography style={{ display: "inline" }} variant="subtitle2">
              Your URL:
            </Typography> */}
            <Button
              variant="text"
              onClick={(e) => setShowPageNameChangeModal(true)}
              target="_blank"
              rel="noreferrer"
              style={{
                textDecoration: "none",
                display: "inline",
                fontWeight: 700,
                fontSize: "1rem",
                color: "#7545a1",
              }}
              onMouseOver={(event) =>
                (event.target.style.textDecoration = "underline")
              }
              onMouseOut={(event) =>
                (event.target.style.textDecoration = "none")
              }
            >
              <i>{`i.help/${page.name}`}</i>
            </Button>
            <CopyToClipboard
              text={`https://i.help/${page.name}`}
              onCopy={(text, result) =>
                enqueueSnackbar("URL copied to clipboard.", {
                  variant: "success",
                })
              }
            >
              <IconButton
                style={{
                  color: "#2f567d",
                  padding: 3,
                  marginLeft: 0.5,
                  marginBottom: 5,
                }}
                edge="start"
              >
                <FileCopyOutlined fontSize="small" />
              </IconButton>
            </CopyToClipboard>
          </Box>
          {/* <IconButton
            style={{
              color: "#2f567d",
              padding: 3,
              marginLeft: 0.5,
              marginBottom: 5,
            }}
            edge="start"
            onClick={() => setShowPageNameChangeModal(true)}
          >
            <Edit fontSize="small" />
          </IconButton> */}

          {/* <IconButton
            style={{
              color: "#2f567d",
              padding: 3,
              marginLeft: 0.5,
              marginBottom: 5,
            }}
            edge="start"
            target="_blank"
            href={`${
              process.env.NODE_ENV === "production"
                ? "https://i.help"
                : "http://localhost:3001"
            }/${page.name}`}
          >
            <OpenInNew fontSize="small" />
          </IconButton> */}
        </Typography>
      </ListItem>
      <Divider />
      <ListItemButton
        className={classes.accordianButton}
        onClick={() => handleClick("widgets")}
      >
        {/* <ListItemIcon>
          <InboxIcon />
        </ListItemIcon> */}
        <ListItemText
          primaryTypographyProps={{
            style: { fontWeight: "700", color: "#1e79d7" },
          }}
          primary="ADD BUTTON"
        />
        {openWidgets ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openWidgets} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {widgets}
        </List>
      </Collapse>
      <Divider />
      <ListItemButton
        className={classes.accordianButton}
        onClick={() => handleClick("settings")}
      >
        {/* <ListItemIcon>
          <InboxIcon />
        </ListItemIcon> */}
        <ListItemText
          primaryTypographyProps={{
            style: { fontWeight: "700", color: "#1e79d7" },
          }}
          primary="ACCOUNT & SETTINGS"
        />
        {openAccountSettings ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openAccountSettings} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            button
            key={"editurl"}
            style={{
              marginTop: -2,
              marginBottom: -2,
              paddingTop: 5,
              paddingBottom: 5,
            }}
            onClick={(e) => setShowPageNameChangeModal(true)}
          >
            <ListItemText primary={"Edit My Public Link"} />
          </ListItem>
          {accountItems}
        </List>
      </Collapse>
    </List>
  );
}
