import { useState } from "react";
import { LinkedIn, Twitter, Facebook, Instagram } from "@mui/icons-material";
import { useWindowSize } from "react-use";
import { Button, IconButton, Icon } from "@mui/material";
import { makeStyles } from "@mui/styles";
import TikTok from "../assets/tiktok.svg";
import UploadFileModal from "./UploadFileModal";
import CallMeModal from "./CallMeModal";
const useStyles = makeStyles((theme) => ({
  actionButton: {
    margin: 5,
    fontSize: "1rem",
    minWidth: "max-content",
    whiteSpace: "nowrap",
  },
}));
const trackClicks = (element, pageId) => {};
const withHttp = (url) => (!/^https?:\/\//i.test(url) ? `http://${url}` : url);
const TikTokIcon = () => (
  <Icon style={{ minHeight: 30 }}>
    <img alt="tiktok img" src={TikTok} />
  </Icon>
);
const Element = ({ element, pageId, disabled }) => {
  const classes = useStyles();
  const { width } = useWindowSize();
  const [isMobile, setIsMobile] = useState(width < 600);
  const [uploadFileModalOpen, setUploadFileModalOpen] = useState(false);
  const [callMeModalOpen, setCallMeModalOpen] = useState(false);
  let type = element.elementType;
  let item;
  if (type === "CallMe") {
    item = (
      <>
        <Button
          color="primary"
          variant="contained"
          className={classes.actionButton}
          href={isMobile ? `tel:${element.value}` : undefined}
          onClick={!isMobile ? () => setCallMeModalOpen(true) : undefined}
        >
          {element.label}
        </Button>
        <CallMeModal
          open={callMeModalOpen}
          element={element}
          handleClose={() => setCallMeModalOpen(false)}
        />
      </>
    );
  }
  if (type === "TextMe") {
    item = (
      <Button
        color="primary"
        variant="contained"
        className={classes.actionButton}
        href={`sms:${element.value}`}
      >
        {element.label}
      </Button>
    );
  }
  if (type === "EmailMe") {
    item = (
      <Button
        color="primary"
        variant="contained"
        className={classes.actionButton}
        href={`mailto:${element.value}`}
        target="_blank"
        rel="noopener"
      >
        {element.label}
      </Button>
    );
  }
  if (type === "WhatsAppMe") {
    item = (
      <Button
        color="primary"
        variant="contained"
        className={classes.actionButton}
        href={`https://api.whatsapp.com/send/?phone=${element.phone}`}
        target="_blank"
        rel="noopener"
      >
        {element.label}
      </Button>
    );
  }
  if (type === "ScheduleMeeting") {
    item = (
      <Button
        color="primary"
        variant="contained"
        className={classes.actionButton}
        href={element.value || `/${pageId}/schedule/${element.eventType}`}
        target="_blank"
        rel="noopener"
      >
        {element.label}
      </Button>
    );
  }
  if (["ViewWebsite", "CustomURL", "WebConference"].includes(type)) {
    item = (
      <Button
        color="primary"
        variant="contained"
        className={classes.actionButton}
        href={withHttp(element.value)}
        target="_blank"
        rel="noopener"
      >
        {element.label}
      </Button>
    );
  }
  if (type === "DownloadFile") {
    item = (
      <Button
        color="primary"
        variant="contained"
        href={withHttp(element.value)}
        download={withHttp(element.value)}
        className={classes.actionButton}
        type="submit"
      >
        {element.label}
      </Button>
    );
  }
  if (type === "PayMe") {
    let url;
    if (element.payType === "paypal") {
      url = `https://paypal.me/${element.value}`;
    } else if (element.payType === "venmo") {
      url = `https://venmo.com/u/${element.value}`;
    } else if (element.payType === "cashapp") {
      url = `https://cash.app/${element.value}`;
    } else {
      url = element.value;
    }
    item = (
      <Button
        color="primary"
        variant="contained"
        target="_blank"
        rel="noopener"
        href={url}
        className={classes.actionButton}
        type="submit"
      >
        {element.label}
      </Button>
    );
  }
  if (type === "UploadFile") {
    item = (
      <>
        <Button
          color="primary"
          variant="contained"
          target="_blank"
          rel="noopener"
          onClick={() => disabled || setUploadFileModalOpen(true)}
          className={classes.actionButton}
          type="submit"
        >
          {element.label}
        </Button>
        <UploadFileModal
          open={uploadFileModalOpen}
          element={element}
          handleClose={() => setUploadFileModalOpen(false)}
        />
      </>
    );
  }
  if (type === "SocialMedia") {
    item = (
      <>
        {element.facebook && (
          <IconButton
            href={withHttp(element.facebook)}
            target="_blank"
            rel="noreferrer"
          >
            <Facebook style={{ color: "#0a88ef" }} fontSize="large" />
          </IconButton>
        )}
        {element.linkedin && (
          <IconButton
            href={withHttp(element.linkedin)}
            target="_blank"
            rel="noreferrer"
          >
            <LinkedIn style={{ color: "#0c66c2" }} fontSize="large" />
          </IconButton>
        )}
        {element.twitter && (
          <IconButton
            href={withHttp(element.twitter)}
            target="_blank"
            rel="noreferrer"
          >
            <Twitter style={{ color: "#1d9bef" }} fontSize="large" />
          </IconButton>
        )}
        {element.instagram && (
          <IconButton
            href={withHttp(element.instagram)}
            target="_blank"
            rel="noreferrer"
          >
            <Instagram style={{ color: "#e54666" }} fontSize="large" />
          </IconButton>
        )}
        {element.tiktok && (
          <IconButton
            href={withHttp(element.tiktok)}
            target="_blank"
            rel="noreferrer"
          >
            <TikTokIcon />
          </IconButton>
        )}
      </>
    );
  }

  if (item) {
    return item;
  } else {
    return null;
  }
};

export default Element;
