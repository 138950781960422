import React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Toolbar,
  ListItemText,
  ListItem,
  List,
  IconButton,
  Drawer,
  CssBaseline,
  AppBar,
  Typography,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import { useHistory } from "react-router-dom";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  title: {
    flexGrow: 1,
    color: "#072757",
  },

  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    boxShadow: "none",
    backgroundColor: "#f0f8ff",
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function Navigation(props) {
  const { children, title } = props;
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  let container = window.document.body;
  const drawer = (
    <div>
      <List>
        <ListItem button key={"page"} onClick={() => history.push("/p/edit")}>
          <ListItemText primary={"Back to Your Page"} />
        </ListItem>
        <ListItem button key={"logout"} onClick={() => history.push("/logout")}>
          <ListItemText primary={"Log Out"} />
        </ListItem>
      </List>
    </div>
  );

  return (
    <>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h5" style={{ color: "black" }}>
              {title}
            </Typography>
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer} aria-label="mailbox folders">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}

          <Drawer
            sx={{ display: { sm: "none", xs: "block" } }}
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>

          <Drawer
            sx={{ display: { xs: "none", md: "block" } }}
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </nav>
        <main
          style={{ backgroundColor: "#5179a1", minHeight: "100vh" }}
          className={classes.content}
        >
          <div className={classes.toolbar} />
          {children}
        </main>
      </div>
    </>
  );
}

export default Navigation;
