let plans = [
  {
    name: "pro",
    env: "development",
    id: "price_1JAF7qGqnlMoYsqbg8d8n4zf",
    trial_period_days: 14,
  },
  {
    name: "pro",
    env: "production",
    id: "price_1KBL2zGqnlMoYsqb4Ig0WqVP",
    trial_period_days: 14,
  },
];
const env = process.env.NODE_ENV; // npm start will be development; npm build will be production
const getPlanByName = (name) =>
  plans.find((plan) => name === plan.name && env === plan.env);

const getPlanById = (id) =>
  plans.find((plan) => id === plan.id && env === plan.env);

export { getPlanByName, getPlanById };
