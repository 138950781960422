import { useState, useEffect } from "react";
import {
  Paper,
  TextField,
  Typography,
  Button,
  Container,
  Divider,
  Grid,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import defaultAvailability from "./availability";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { useHistory, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import SchedulerNavbar from "../../components/SchedulerNavbar";
import MultiCalendarSelect, {
  SingleCalendarSelect,
  CalendarTypeSelect,
} from "../../components/CalendarsSelect";
import { capitalize } from "../../util";
import useStore from "../../store";
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(20),
    width: "100%",
    padding: 20,
  },
  timeInputs: {
    // marginBottom: 10,
  },
  formControl: {
    width: "100%",
    maxWidth: 300,
  },
  stepLabel: {
    cursor: "pointer",
  },
  fatAccordian: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  customSummary: {
    //  borderBottom: "1px solid #e0e0e0",
  },
}));
const days = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

const durations = [15, 30, 45, 60];

//import { makeStyles, useTheme } from "@mui/styles";
const NewEventType = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [availability, setAvailability] = useState(defaultAvailability);
  const [calendars, setCalendars] = useState([]);
  const [errors, setErrors] = useState({});
  const { id } = useParams();
  const history = useHistory();
  const [activeStep, setActiveStep] = useState(0);
  const {
    createEventType,
    listCalendars,
    user,
    page,
    fetchPageRequest,
    fetchEventType,
    updateEventType,
  } = useStore((state) => ({
    createEventType: state.createEventType,
    listCalendars: state.listCalendars,
    user: state.user,
    page: state.page,
    fetchPageRequest: state.fetchPageRequest,
    fetchEventType: state.fetchEventType,
    updateEventType: state.updateEventType,
  }));
  const populatePageName = (text = "", pageUrl) =>
    text.replace("{{myPage}}", pageUrl);

  const [eventType, setEventType] = useState({
    name: `1 Hour Meeting with ${user.firstName} ${user.lastName}`,
    duration: 60,
    displayInterval: 60,
    meetingBuffer: 0,
    description: "",
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    calendarsToCheck: [],
    calendarType: user.hasGoogleConnection ? "google" : "microsoft",
    redirectUrl: "https://www.i.help/confirmation",
  });

  async function handleListCalendars(calendarType = "google") {
    try {
      let calendarsRequest = await listCalendars(calendarType);
      if (calendarType === "google") {
        setCalendars(calendarsRequest.data.items);
      } else {
        setCalendars(calendarsRequest.data.value);
      }
      if (!id) {
        setEventType({
          ...eventType,
          addToCalendarId: "",
          calendarsToCheck: [],
          duration: 60,
          displayInterval: 60,
          meetingBuffer: 0,
          calendarType: calendarType,
          description: `To join this meeting, visit {{myPage}} and click the Join Meeting button.`,
        });
      }
    } catch (e) {
      console.error(e);
    }
  }

  async function handleFetchEventType() {
    let fetchEventRequest = await fetchEventType(id);
    let { data: page } = await fetchPageRequest();
    let eventType = fetchEventRequest.data;
    eventType.description = populatePageName(
      eventType.description,
      `https://i.help/${page.name}`
    );
    setEventType(eventType);
    setAvailability(fetchEventRequest.data.availability);
    handleListCalendars(fetchEventRequest.data.calendarType);
  }

  useEffect(() => {
    if (user.hasMicrosoftConnection) {
      console.log({ hasMicrosoftConnection: user.hasMicrosoftConnection });
      handleListCalendars("microsoft");
    } else {
      handleListCalendars("google");
    }
    if (id) {
      handleFetchEventType();
    }
  }, [eventType.calendarType]);

  function handDayOpenChange(event, day) {
    setAvailability({
      ...availability,
      [day]: {
        ...availability[day],
        open: !availability[day].open,
      },
    });
  }

  const validateCreateEventType = () => {
    let errors = {};
    if (!eventType.name) {
      errors.name = "Name is required";
    }
    if (!eventType.description) {
      errors.description = "Description is required";
    }
    console.log({ eventType }, eventType.calendarsToCheck.length);

    if (eventType.calendarsToCheck.length === 0) {
      errors.calendarsToCheck =
        "What calendar(s) should we check for existing events?";
    }
    if (!eventType.addToCalendarId) {
      errors.addToCalendarId = "Please select a calendar to add the event to.";
    }
    setErrors(errors);
    return errors;
  };

  async function handleCreateEventType(e) {
    e.preventDefault();
    setErrors({});
    let errors = validateCreateEventType();
    if (Object.keys(errors).length) {
      enqueueSnackbar("Please fix the errors before submitting.", {
        variant: "error",
      });
      console.log(errors, eventType);
      return;
    }
    try {
      await createEventType({
        availability,
        ...eventType,
      });
      history.push("/p/edit/eventtypes");
    } catch (e) {
      enqueueSnackbar(
        e?.response?.data?.error?.message || "Error creating new Event Type.",
        { variant: "error" }
      );
    }
  }
  let insertPlaceholder = (description) => {
    let wordsToReplace = description
      .split(" ")
      .filter((word) => word.includes("i.help"));
    return description.replace(wordsToReplace[0], "{{myPage}}");
  };

  async function handleUpdateEventType(e) {
    e.preventDefault();
    try {
      let { data } = await updateEventType(id, {
        ...eventType,
        description: insertPlaceholder(eventType.description), // Replace ihelp urls with place holder for page
        availability: availability,
      });
      history.push("/p/edit/eventtypes");
    } catch (e) {
      enqueueSnackbar(
        e?.response?.data?.error?.message || "Error updating new Event Type.",
        { variant: "error" }
      );
    }
  }

  function handleFieldChange(event, name) {
    setEventType({
      ...eventType,
      [name]:
        name === "duration" ||
        name === "displayInterval" ||
        name === "meetingBuffer"
          ? Number(event.target.value)
          : event.target.value,
    });
    // if (
    //   name === "duration" &&
    //   Number(event.target.value) < eventType.displayInterval
    // ) {
    //   setEventType({
    //     ...eventType,
    //     displayInterval: Number(event.target.value),
    //   });
    // }
  }

  const handleAvailabilityChange = (e, day, field) => {
    let time = e.target.value;
    let [hours, minutes] = time.split(":");
    setAvailability({
      ...availability,
      [day]: {
        ...availability[day],
        [field]: Number(hours) * 60 + Number(minutes),
      },
    });
  };
  const minutesToTime = (minutes) => {
    let hours = String(Math.floor(minutes / 60));
    let min = String(minutes % 60);
    return `${hours.padStart(2, "0")}:${min.padStart(2, "0")}`;
    //return `${Math.floor(minutes / 60)}:${minutes % 60}`;
  };
  return (
    <SchedulerNavbar hideAddNewMeeting={true}>
      <Container style={{ paddingBottom: 15 }}>
        <Grid container justifyContent="center" spacing={1}>
          <Grid item xs={12}>
            <Accordion className={classes.fatAccordian} defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={classes.customSummary}
              >
                <Typography variant="h6">Event Details</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <p>Meeting Name</p>
                    <TextField
                      variant="outlined"
                      fullWidth
                      error={errors.name}
                      value={eventType.name}
                      style={{ minWidth: 350 }}
                      onChange={(e) => handleFieldChange(e, "name")}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <p>Meeting Purpose / Details</p>
                    <TextField
                      multiline
                      fullWidth
                      rows={4}
                      variant="outlined"
                      value={eventType.description}
                      onChange={(e) => handleFieldChange(e, "description")}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CalendarTypeSelect
                      onChange={(e) =>
                        setEventType({
                          ...eventType,
                          calendarType: e.target.value,
                        })
                      }
                      value={eventType.calendarType}
                    />
                  </Grid>
                  {calendars.length > 0 && (
                    <>
                      <Grid item xs={12}>
                        <MultiCalendarSelect
                          calendars={calendars}
                          onChange={(e) =>
                            setEventType({
                              ...eventType,
                              calendarsToCheck: e.target.value,
                            })
                          }
                          value={eventType.calendarsToCheck}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <SingleCalendarSelect
                          calendars={calendars}
                          onChange={(e) =>
                            setEventType({
                              ...eventType,
                              addToCalendarId: e.target.value,
                            })
                          }
                          value={eventType.addToCalendarId}
                        />
                      </Grid>
                    </>
                  )}

                  <Grid item xs={12}>
                    <FormControl component="fieldset" style={{ width: "100%" }}>
                      <p>Meeting Duration</p>
                      <RadioGroup
                        aria-label="duration"
                        name="duration"
                        label="Duration"
                        value={eventType.duration}
                        onChange={(e) => handleFieldChange(e, "duration")}
                      >
                        {durations.map((duration) => (
                          <>
                            <FormControlLabel
                              value={duration}
                              control={<Radio color="primary" />}
                              label={`${duration} Minutes`}
                            />
                          </>
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl component="fieldset" style={{ width: "100%" }}>
                      <p>
                        Meeting Time Display Interval
                        <Typography variant="caption">
                          {" "}
                          (Show potential meeting times every X minutes)
                        </Typography>
                      </p>
                      <RadioGroup
                        aria-label="displayInterval"
                        name="displayInterval"
                        label="Display Interval"
                        value={Number(eventType.displayInterval)}
                        onChange={(e) =>
                          handleFieldChange(e, "displayInterval")
                        }
                      >
                        {durations.map((interval) => (
                          <>
                            <FormControlLabel
                              value={Number(interval)}
                              control={
                                <Radio
                                  //disabled={eventType.duration < interval}
                                  color="primary"
                                />
                              }
                              label={`${interval} Minutes`}
                            />
                          </>
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl component="fieldset" style={{ width: "100%" }}>
                      <p>
                        Time Buffer Between Meetings
                        <Typography variant="caption">
                          {" "}
                          (Keep a minimum time gap between your scheduled
                          meetings.)
                        </Typography>
                      </p>
                      <RadioGroup
                        aria-label="meetingBuffer"
                        name="meetingBuffer"
                        label="Meeting Buffer"
                        value={Number(eventType.meetingBuffer)}
                        onChange={(e) => handleFieldChange(e, "meetingBuffer")}
                      >
                        {[0, 15, 30, 60].map((buffer) => (
                          <>
                            <FormControlLabel
                              value={Number(buffer)}
                              control={
                                <Radio
                                  //disabled={eventType.duration < interval}
                                  color="primary"
                                />
                              }
                              label={`${buffer} Minutes`}
                            />
                          </>
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      label="Redirect URL after Scheduling"
                      variant="outlined"
                      placeholder="https://www.mywebsite.com"
                      value={eventType.redirectUrl}
                      onChange={(e) => handleFieldChange(e, "redirectUrl")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ marginTop: 10, marginBottom: 10 }}
                  >
                    <p>
                      What times are you available for this event type?
                      <Typography variant="caption">
                        {" "}
                        (defaults to 9:00am - 5:00pm
                        {" " + Intl.DateTimeFormat().resolvedOptions().timeZone}
                        )
                      </Typography>
                    </p>
                  </Grid>
                  {days.map((day) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <Grid
                        container
                        alignItems="center"
                        direction="row"
                        style={{ marginLeft: 10 }}
                      >
                        <Grid item xs={2}>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={availability[day].open}
                                  onChange={(event) =>
                                    handDayOpenChange(event, day)
                                  }
                                  color="primary"
                                />
                              }
                              label={capitalize(day)}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          md={2}
                          container
                          justifyContent="center"
                        >
                          <TextField
                            id="time"
                            //label="Alarm clock"
                            type="time"
                            defaultValue="09:00"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            fullWidth
                            disabled={!availability[day].open}
                            inputProps={{
                              step: 900, // 15 min
                            }}
                            value={minutesToTime(availability[day].from)}
                            onChange={(e) =>
                              handleAvailabilityChange(e, day, "from")
                            }
                            sx={{ width: 150 }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={1}
                          style={{
                            textAlign: "center",
                            color: !availability[day].open && "#bdbdbd",
                          }}
                        >
                          {" "}
                          to{" "}
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          md={2}
                          container
                          justifyContent="center"
                        >
                          <TextField
                            id="time"
                            //label="Alarm clock"
                            type="time"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            value={minutesToTime(availability[day].to)}
                            fullWidth
                            disabled={!availability[day].open}
                            inputProps={{
                              step: 900, // 15 min
                            }}
                            onChange={(e) =>
                              handleAvailabilityChange(e, day, "to")
                            }
                            sx={{ width: 150 }}
                          />
                        </Grid>
                      </Grid>
                      <Divider style={{ width: "95%", margin: 10 }} />
                    </LocalizationProvider>
                  ))}

                  <Grid item xs={12}>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={
                        id ? handleUpdateEventType : handleCreateEventType
                      }
                      style={{ margin: 5 }}
                    >
                      {id ? "Update Event Type" : "Create Event Type"}
                    </Button>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Container>
    </SchedulerNavbar>
  );
};
export default NewEventType;
