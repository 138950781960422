let cloudfront = "https://d2ub6d0yniya4m.cloudfront.net";

const squares = [
  {
    name: "bg_gs_chair",
    get image() {
      return `${cloudfront}/bgimages/${this.name}.png`;
    },
    get thumb() {
      return `${cloudfront}/thumbnails/${this.name}thumb.png`;
    },
  },
  {
    name: "bg_abstractDark",
    get image() {
      return `${cloudfront}/bgimages/${this.name}.png`;
    },
    get thumb() {
      return `${cloudfront}/thumbnails/${this.name}thumb.png`;
    },
  },
  {
    name: "bg_abstractDark1",
    get image() {
      return `${cloudfront}/bgimages/${this.name}.png`;
    },
    get thumb() {
      return `${cloudfront}/thumbnails/${this.name}thumb.png`;
    },
  },
  {
    name: "bg_abstractBlueGreen",
    get image() {
      return `${cloudfront}/bgimages/${this.name}.png`;
    },
    get thumb() {
      return `${cloudfront}/thumbnails/${this.name}thumb.png`;
    },
  },
  {
    name: "bg_abstractCloud",
    get image() {
      return `${cloudfront}/bgimages/${this.name}.png`;
    },
    get thumb() {
      return `${cloudfront}/thumbnails/${this.name}thumb.png`;
    },
  },
  {
    name: "bg_abstractDefault",
    get image() {
      return `${cloudfront}/bgimages/${this.name}.png`;
    },
    get thumb() {
      return `${cloudfront}/thumbnails/${this.name}thumb.png`;
    },
  },
  {
    name: "bg_abstractMinimal",
    get image() {
      return `${cloudfront}/bgimages/${this.name}.png`;
    },
    get thumb() {
      return `${cloudfront}/thumbnails/${this.name}thumb.png`;
    },
  },
  {
    name: "bg_abstractShine",
    get image() {
      return `${cloudfront}/bgimages/${this.name}.png`;
    },
    get thumb() {
      return `${cloudfront}/thumbnails/${this.name}thumb.png`;
    },
  },
  {
    name: "bg_abstractStone",
    get image() {
      return `${cloudfront}/bgimages/${this.name}.png`;
    },
    get thumb() {
      return `${cloudfront}/thumbnails/${this.name}thumb.png`;
    },
  },
  {
    name: "bg_conferenceRoom1",
    get image() {
      return `${cloudfront}/bgimages/${this.name}.png`;
    },
    get thumb() {
      return `${cloudfront}/thumbnails/${this.name}thumb.png`;
    },
  },
  {
    name: "bg_conferenceRoom2",
    get image() {
      return `${cloudfront}/bgimages/${this.name}.png`;
    },
    get thumb() {
      return `${cloudfront}/thumbnails/${this.name}thumb.png`;
    },
  },
  {
    name: "bg_conferenceRoom3",
    get image() {
      return `${cloudfront}/bgimages/${this.name}.png`;
    },
    get thumb() {
      return `${cloudfront}/thumbnails/${this.name}thumb.png`;
    },
  },
  {
    name: "bg_conferenceRoom4",
    get image() {
      return `${cloudfront}/bgimages/${this.name}.png`;
    },
    get thumb() {
      return `${cloudfront}/thumbnails/${this.name}thumb.png`;
    },
  },
  {
    name: "bg_conferenceRoom5",
    get image() {
      return `${cloudfront}/bgimages/${this.name}.png`;
    },
    get thumb() {
      return `${cloudfront}/thumbnails/${this.name}thumb.png`;
    },
  },
  {
    name: "bg_conferenceRoom6",
    get image() {
      return `${cloudfront}/bgimages/${this.name}.png`;
    },
    get thumb() {
      return `${cloudfront}/thumbnails/${this.name}thumb.png`;
    },
  },
  {
    name: "bg_conferenceRoom7",
    get image() {
      return `${cloudfront}/bgimages/${this.name}.png`;
    },
    get thumb() {
      return `${cloudfront}/thumbnails/${this.name}thumb.png`;
    },
  },
  {
    name: "bg_gs_001",
    get image() {
      return `${cloudfront}/bgimages/${this.name}.png`;
    },
    get thumb() {
      return `${cloudfront}/thumbnails/${this.name}_thumb.jpg`;
    },
  },
  {
    name: "bg_gs_002",
    get image() {
      return `${cloudfront}/bgimages/${this.name}.png`;
    },
    get thumb() {
      return `${cloudfront}/thumbnails/${this.name}_thumb.jpg`;
    },
  },
  {
    name: "bg_gs_003",
    get image() {
      return `${cloudfront}/bgimages/${this.name}.png`;
    },
    get thumb() {
      return `${cloudfront}/thumbnails/${this.name}_thumb.jpg`;
    },
  },
  {
    name: "bg_gs_004",
    get image() {
      return `${cloudfront}/bgimages/${this.name}.png`;
    },
    get thumb() {
      return `${cloudfront}/thumbnails/${this.name}_thumb.jpg`;
    },
  },
  {
    name: "bg_gs_005",
    get image() {
      return `${cloudfront}/bgimages/${this.name}.png`;
    },
    get thumb() {
      return `${cloudfront}/thumbnails/${this.name}_thumb.jpg`;
    },
  },
  {
    name: "bg_gs_006",
    get image() {
      return `${cloudfront}/bgimages/${this.name}.png`;
    },
    get thumb() {
      return `${cloudfront}/thumbnails/${this.name}_thumb.jpg`;
    },
  },
  {
    name: "bg_gs_007",
    get image() {
      return `${cloudfront}/bgimages/${this.name}.png`;
    },
    thumb: `${cloudfront}/thumbnails/bg_gs_007_thumb.jpg`,
  },
  // {
  //   name: "bg_gs_008",
  //   get image() {
  //     return `${cloudfront}/bgimages/${this.name}.png`;
  //   },
  //   thumb: `${cloudfront}/thumbnails/bg_gs_008_thumb.jpg`,
  // },
  {
    name: "bg_gs_009",
    get image() {
      return `${cloudfront}/bgimages/${this.name}.png`;
    },
    thumb: `${cloudfront}/thumbnails/bg_gs_009_thumb.jpg`,
  },
  {
    name: "bg_gs_010",
    get image() {
      return `${cloudfront}/bgimages/${this.name}.png`;
    },
    thumb: `${cloudfront}/thumbnails/bg_gs_010_thumb.jpg`,
  },
  {
    name: "clouds_video.mov",
    get image() {
      return `${cloudfront}/bgvideos/${this.name}`;
    },
    thumb: `${cloudfront}/thumbnails/clouds_video_thumb.png`,
    type: "video",
  },
];

export default squares;
