let tlds = [
  "com",
  "help",
  "net",
  "org",
  "chat",
  `info`,
  `guide`,
  `training`,
  `wiki`,
  `accountant`,
  `accountants`,
  `financial`,
  `holdings`,
  `investments`,
  `inc`,
  `attorney`,
  `beauty`,
  `ceo`,
  `coach`,
  `consulting`,
  `contractors`,
  `dental`,
  `dentist`,
  `design`,
  `engineer`,
  `law`,
  `lawyer`,
  `legal`,
  `pro`,
  `tech`,
  `doctor`,
  `vip`,
  `vet`,
  `university`,
  `church`,
  `college`,
  `blog`,
  `expert`,
  `guru`,
  `me`,
  `care`,
  `clinic`,
  `fitness`,
  `surgery`,
];

export default tlds;
