import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import useStore from "../../store";

const PublicSharing = () => {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const fetchUploadByPublicId = useStore(
    (state) => state.fetchUploadByPublicId
  );

  const downloadFile = (dataurl, filename) => {
    const link = document.createElement("a");
    link.href = dataurl;
    link.download = filename;
    link.click();
  };

  const handleDocumentDownload = async () => {
    let { data: upload } = await fetchUploadByPublicId(id);
    console.log(upload);
    downloadFile(upload.downloadUrl, upload.filename);
    enqueueSnackbar(`Download started.`, { variant: "success" });
  };

  useEffect(() => {
    handleDocumentDownload();
  }, []);

  return null;
};

export default PublicSharing;
