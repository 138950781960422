import { useState } from "react";
import {
  Menu as MenuIcon,
  Edit,
  ContactMail,
  Schedule,
} from "@mui/icons-material";
import isUrl from "is-url";
import {
  Typography,
  ListItemText,
  ListItemIcon,
  ListItem,
  List,
  IconButton,
  Drawer,
  CssBaseline,
  AppBar,
  Button,
  MenuItem,
  Menu,
  Divider,
  Box,
  TextField,
} from "@mui/material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { makeStyles, useTheme } from "@mui/styles";
import {
  AccountCircle,
  FileCopyOutlined,
  OpenInNew,
  EventAvailable,
} from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { accountIconRoutes } from "../../routes";
import buttons from "./ContentButtons";
import DrawerForm from "./DrawerForm";
import DrawerMenu from "./DrawerMenu";
import useStore from "../../store";
import PageNameChangeModal from "../../components/PageNameChange";
const drawerWidth = 270;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  title: {
    flexGrow: 1,
    color: "#072757",
  },

  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    boxShadow: "none",
    backgroundColor: "#f0f8ff",
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  formContainer: {
    paddingTop: 7,
    paddingLeft: 7,
    width: "100%",
  },
  listItemIcon: {
    minWidth: 38,
  },
  backgroundVideo: {
    zIndex: -1,
    position: "fixed",
  },
}));

function FormMenu(props) {
  const { children, page, mobileOpen, setMobileOpen } = props;
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();

  const {
    user,
    setUser,
    showForm,
    setShowForm,
    formName,
    setFormName,
    elementToUpdate,
    publishPage,
    updateElement,
    clearForm,
    setShowPageNameChangeModal,
    fetchPage,
    setElementToUpdate,
    createElement,
    isPremiumUser,
    setShowUpgradeModal,
    switchOrgs,
  } = useStore((state) => ({
    setShowUpgradeModal: state.setShowUpgradeModal,
    user: state.user,
    setUser: state.setUser,
    switchOrgs: state.switchOrgs,
    showForm: state.showForm,
    fetchPage: state.fetchPage,
    clearForm: state.clearForm,
    setShowForm: state.setShowForm,
    formName: state.formName,
    setShowPageNameChangeModal: state.setShowPageNameChangeModal,
    setFormName: state.setFormName,
    elementToUpdate: state.elementToUpdate,
    updateElement: state.updateElement,
    createElement: state.createElement,
    setElementToUpdate: state.setElementToUpdate,
    publishPage: state.publishPage,
    isPremiumUser: state.isPremiumUser,
  }));

  const { enqueueSnackbar } = useSnackbar();

  const handleFormSubmit = async (e, payload) => {
    e.preventDefault();
    if (elementToUpdate) {
      updateElement(elementToUpdate._id, payload).then(async () => {
        clearForm();
        setShowForm(false);
        setElementToUpdate(null);
        await publishPage();
        fetchPage();
      });
    } else {
      let numberOfElements =
        page.draft.dropZone1.elements.length +
        page.draft.dropZone2.elements.length;
      if (!isPremiumUser(user) && numberOfElements >= 3) {
        enqueueSnackbar(
          "You need to be a premium user to add more than 3 buttons to your page.",
          { variant: "error" }
        );
        setShowUpgradeModal(true);
        return new Error("Too many buttons for this plan.");
      } else {
        createElement(payload).then(async () => {
          clearForm();
          setShowForm(false);
          await publishPage();
          fetchPage();
        }); //create new element
      }
    }
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  let container = window.document.body;

  const handleFormRender = (e, formComponent) => {
    setShowForm(true);
    setFormName(formComponent);
  };

  const handleOrgChange = async (e) => {
    let { data: response } = await switchOrgs(e.target.value);
    localStorage.setItem("token", response.token);
    localStorage.setItem("authenticatedAt", new Date());
    setUser(response.user);
  };

  const drawer = showForm ? (
    <div className={classes.formContainer}>
      <DrawerForm formName={formName} onSubmit={handleFormSubmit} page={page} />
    </div>
  ) : (
    <div>
      {user.orgs.length > 1 && ( // Don't show org switcher if only one org
        <>
          <TextField
            select
            label="Current Team"
            variant="standard"
            value={user.org._id}
            style={{
              display: "flex",
              flexGrow: 1,
              maxWidth: 200,
              marginLeft: 10,
              marginTop: 5,
              marginBottom: 10,
            }}
            onChange={handleOrgChange}
          >
            {user.orgs.map((org) => (
              <MenuItem key={org._id} value={org._id}>
                <Typography>
                  {org.name
                    ? org.name
                    : user._id === org.ownerUser // if the user is the owner of the org and it has no name use their name
                    ? `${user.firstName} ${user.lastName}`
                    : org._id}{" "}
                  <Typography variant="caption" style={{ color: "grey" }}>
                    {org?.stripe?.status && org?.stripe?.status !== "canceled"
                      ? "(Pro)"
                      : "(Basic)"}
                  </Typography>
                </Typography>
              </MenuItem>
            ))}
          </TextField>
          <Divider />
        </>
      )}

      <DrawerMenu
        page={page}
        drawerOpen={mobileOpen}
        widgets={buttons.freeContentButtons.map(
          ({ label, Icon, formComponent, disabled = false }, index) => (
            <ListItem
              button
              key={index}
              style={{
                marginTop: -2,
                marginBottom: -2,
                paddingTop: 5,
                paddingBottom: 5,
              }}
              onClick={(e) => handleFormRender(e, formComponent)}
              disabled={disabled}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <Icon />
              </ListItemIcon>
              <ListItemText primary={label} />
            </ListItem>
          )
        )}
        accountItems={accountIconRoutes.map(
          ({ label, Icon, path, disabled = false, requiredRole }, index) =>
            (!requiredRole || requiredRole.includes(user.role)) && (
              <ListItem
                button
                key={index}
                style={{
                  marginTop: -2,
                  marginBottom: -2,
                  paddingTop: 5,
                  paddingBottom: 5,
                }}
                onClick={(e) => history.push(path)}
                disabled={disabled}
              >
                <ListItemText primary={label} />
              </ListItem>
            )
        )}
      />
    </div>
  );

  return (
    <>
      <div className={classes.root}>
        <CssBaseline />
        <nav className={classes.drawer} aria-label="mailbox folders">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}

          <Drawer
            sx={{ display: { sm: "none", xs: "block" } }}
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>

          <Drawer
            sx={{ display: { xs: "none", md: "block" } }}
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </nav>
        {page?.backgroundVideo && (
          <video className={classes.backgroundVideo} autoPlay loop muted>
            <source src={page?.backgroundVideo} type="video/mp4" />
          </video>
        )}
        <main
          style={{
            ...(!page?.backgroundVideo &&
              page?.backgroundImage && {
                backgroundColor: page.color || "#5179a1",
                backgroundImage: isUrl(page?.backgroundImage)
                  ? `url("${page?.backgroundImage}")`
                  : `url("https://d2ub6d0yniya4m.cloudfront.net/bgimages/${page?.backgroundImage}.png")`,
              }),
            backgroundSize: "100% 100%",
            minHeight: "100vh",
          }}
          className={classes.content}
        >
          <div className={classes.toolbar} />
          {children}
          <PageNameChangeModal page={page} id="page-name-modal" />
        </main>
      </div>
    </>
  );
}

export default FormMenu;
