import { useState, useEffect } from "react";
import {
  Button,
  Container,
  Typography,
  Card,
  CardContent,
  CardActions,
  IconButton,
  Grid,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { DeleteOutlined } from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import SchedulerNavbar from "../../components/SchedulerNavbar";
import useStore from "../../store";
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(20),
    width: "100%",
    padding: 20,
  },
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));

//import { makeStyles, useTheme } from "@mui/styles";
const EventTypes = () => {
  const classes = useStyles();
  const history = useHistory();
  const [eventTypes, setEventTypes] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { listEventTypes, archiveEventType, fetchPage, page } = useStore(
    (state) => ({
      listEventTypes: state.listEventTypes,
      archiveEventType: state.archiveEventType,
      fetchPage: state.fetchPage,
      page: state.page,
    })
  );

  async function handleArchive(id) {
    setIsDeleting(true);
    await archiveEventType(id);
    enqueueSnackbar("Event type was deleted", { variant: "success" });
    fetchEventTypes();
    setIsDeleting(false);
  }

  async function fetchEventTypes() {
    let { data } = await listEventTypes();
    setEventTypes(data);
  }
  useEffect(() => {
    fetchEventTypes();
    fetchPage();
  }, []);
  const populatePageName = (text = "", pageUrl) =>
    text.replace("{{myPage}}", pageUrl);
  return (
    <SchedulerNavbar>
      <Container>
        <Grid container justifyContent="center" spacing={1}>
          {eventTypes.map((eventType) => (
            <Grid item xs={12} md={3}>
              <Card className={classes.root} variant="outlined">
                <CardContent>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    {`${eventType.duration} min meeting`}
                  </Typography>
                  <Typography variant="h5" component="h2">
                    {eventType.name}
                  </Typography>
                  <br />
                  <Typography variant="body2" component="p">
                    {populatePageName(
                      eventType.description,
                      `https://i.help/${page?.name}`
                    )}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    onClick={() =>
                      history.push(`/p/edit/eventtype/${eventType._id}`)
                    }
                    variant="outlined"
                    size="small"
                    color="primary"
                  >
                    Edit
                  </Button>
                  <IconButton
                    style={{ marginLeft: "auto", color: "#d86e6e" }}
                    onClick={() => handleArchive(eventType._id)}
                    disabled={isDeleting}
                  >
                    <DeleteOutlined />
                  </IconButton>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </SchedulerNavbar>
  );
};
export default EventTypes;
