import React, { useState } from "react";
import isUrl from "is-url";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import {
  Modal,
  Button,
  Typography,
  Grid,
  Divider,
  TextField,
  LinearProgress,
} from "@mui/material";
import BackgroundSquare from "./BackgroundSquare";
import squares from "./Squares";
import axios from "axios";

import useStore from "../store";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    //margin: "auto",
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 600,
    maxWidth: "100vw",
    backgroundColor: theme.palette.background.paper,
    //border: "2px solid #000",
    borderRadius: 5,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function UploadFileModal({ open, handleClose, element }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const fetchPresignedFileUploadUrl = useStore(
    (state) => state.fetchPresignedFileUploadUrl
  );
  const updateUpload = useStore((state) => state.updateUpload);
  let [isSubmitting, setIsSubmitting] = useState(false);
  let [fileName, setFileName] = useState("");
  let [progress, setProgress] = useState(0);
  let [form, setForm] = useState({
    email: "",
    description: "",
  });
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);

  const handleFileSelect = (e) => {
    let file = document.querySelector("#file").files[0];

    let name = document
      .querySelector("#file")
      .value.split("\\")[2]
      .replace(/\s+/g, "");
    setFileName(name);
  };
  const handleFileUpload = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    let file = document.querySelector("#file").files[0];
    let size = file.size;
    let name = document
      .querySelector("#file")
      .value.split("\\")[2]
      .replace(/\s+/g, ""); //remove the whitespace

    let { data } = await fetchPresignedFileUploadUrl(name, element._id, {
      ...form,
      size,
      bucket: "ihelpfileuploads",
    });
    let { uploadURL, upload } = data;
    if (file) {
      try {
        await axios({
          method: "put",
          url: uploadURL,
          data: file,
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            setProgress((loaded / total) * 100);
          },
        });
        await updateUpload(upload._id, {
          // Mark upload as completed
          uploadCompleted: true,
        });

        enqueueSnackbar(`Your ${fileName} has been uploaded.`, {
          variant: "success",
        });
        setIsSubmitting(false);
        setFileName("");
        // fetchPage();
        handleClose();
      } catch (e) {
        setIsSubmitting(false);
        enqueueSnackbar("There was an issue uploading your image.", {
          variant: "error",
        });
      }
    } else {
      setIsSubmitting(false);
    }
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={
        {
          // display: "flex",
          // alignItems: "center",
          // justifyContent: "center",
        }
      }
    >
      <form onSubmit={handleFileUpload}>
        <Grid
          container
          spacing={2}
          style={modalStyle}
          className={classes.paper}
        >
          <Grid
            container
            //direction="row"
            spacing={1}
            style={{ marginBottom: 10 }}
          >
            <Grid item xs={12}>
              <h2 id="simple-modal-title">
                {isSubmitting ? "Uploading File..." : "Upload File"}
              </h2>
            </Grid>
            <Grid item xs={12}>
              {isSubmitting && (
                <LinearProgress variant="determinate" value={progress} />
              )}
            </Grid>

            <Grid item xs={12}>
              <Button
                style={{ marginTop: 10 }}
                variant="contained"
                color="primary"
                component="label"
                disabled={isSubmitting}
              >
                {isSubmitting ? `Uploading...` : `Choose File...`}
                <input
                  id="file"
                  type="file"
                  //accept="video/mp4,video/quicktime,image/png,image/jpg"
                  hidden
                  onChange={handleFileSelect} // Triggers upload on file select
                />
              </Button>{" "}
              {fileName}
            </Grid>
            <Grid item xs={12}>
              <p>Please include your info:</p>
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                required
                label="Email"
                disabled={isSubmitting}
                onChange={(e) =>
                  setForm({ ...form, email: e.target.value.trim() })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                multiline
                rows={4}
                required
                label="Description"
                disabled={isSubmitting}
                onChange={(e) =>
                  setForm({ ...form, description: e.target.value })
                }
              />
            </Grid>
          </Grid>

          <Grid
            container
            justifyContent="flex-end"
            alignItems="flex-end"
            spacing={0}
          >
            <Grid item md={2} xs={4}>
              <Button
                style={{ marginRight: 5 }}
                variant="outlined"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item md={2} xs={4}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? `Saving...` : `Save`}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Modal>
  );
}
