import create from "zustand";
import axios from "axios";
import { devtools } from "zustand/middleware";
import requests from "./requests";
const baseUrl =
  process.env.REACT_APP_BASE_URL || process.env.REACT_APP_DEV_ENDPOINT;

const store = (set, get) => ({
  ...requests,
  invite: {
    title: null,
    description: null,
    start: null,
    end: null,
  },
  setInvite: (invite) => set({ invite }),
  hasGoogleCalendarScope: (user) => {
    if (!user.google) {
      // If they don't have a google conenction at all
      return false;
    }
    let scopes = user?.google?.scope?.split(" ") || [];
    let eventsPerm = scopes.find(
      (scope) => scope === "https://www.googleapis.com/auth/calendar.events"
    );
    let listCalPerm = scopes.find(
      (scope) => scope === "https://www.googleapis.com/auth/calendar.readonly"
    );
    let totalCalPerm = scopes.find(
      (scope) => scope === "https://www.googleapis.com/auth/calendar"
    );
    let hasCalendarPermissions = !!(
      totalCalPerm ||
      (eventsPerm && listCalPerm)
    );
    return hasCalendarPermissions;
  },
  loginUser: async (email, password, defaultTimeZone) => {
    let loginRequest = await axios({
      method: "post",
      url: `${baseUrl}/v1/users/login`,
      data: {
        email,
        password,
        defaultTimeZone,
      },
      ...(localStorage.getItem("token") && {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }),
    });
    localStorage.setItem("token", loginRequest.data.token);
    set({ user: await loginRequest.data.user });
  },

  refreshUserError: null,
  refreshUser: async () => {
    axios({
      method: "get",
      url: `${baseUrl}/v1/users/me`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then(({ data }) => {
        set({ user: data, refreshUserError: null });
      })
      .catch((error) => {
        set({
          refreshUserError:
            error?.response?.data?.error?.message ||
            "There was an error refreshing the user.",
        });
      });
  },
  isPremiumUser: (user) =>
    ["active", "past_due", "trialing"].includes(user.org?.stripe?.status) ||
    user?.isImpersonating,
  user: null,
  setUser: (user) => set({ user }),
  clearUser: () => set({ user: null }),
  isAuthenticating: false,
  //Form State
  showForm: false,
  setShowForm: (showForm) => set({ showForm }),
  formName: null,
  setFormName: (formName) => set({ formName }),
  form: {},
  clearForm: () => set({ form: {} }),
  setForm: (form) => set({ form }),

  elementToUpdate: null,
  setElementToUpdate: (element) => set({ elementToUpdate: element }),

  //Page State
  page: null,
  clearPage: () => set({ page: null }),
  isLoadingPage: false,
  fetchPageError: null,
  fetchPageRequest: async (pageId, idType = "mongo", mode = "draft") =>
    axios({
      method: "get",
      url: `${baseUrl}/v1/pages/${
        pageId || get().user.defaultPage
      }?idType=${idType}&mode=${mode}`,
    }),
  fetchPage: async (pageId, idType = "mongo", mode = "draft") => {
    set({ isLoadingPage: true, fetchPageError: null });
    try {
      let pageRequest = await axios({
        method: "get",
        url: `${baseUrl}/v1/pages/${
          pageId || get().user.defaultPage
        }?idType=${idType}&mode=${mode}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      set({ isLoadingPage: false, page: await pageRequest.data });
    } catch (e) {
      set({
        isLoadingPage: false,
        fetchPageError: e?.response?.data || {
          error: {
            message: "Error loading the page.",
          },
        },
      });
    }
  },
  publishPage: async (pageId) =>
    axios({
      method: "put",
      url: `${baseUrl}/v1/pages/${pageId || get().user.defaultPage}/publish`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  updatePage: async (pageId, update) =>
    axios({
      method: "put",
      url: `${baseUrl}/v1/pages/${pageId || get().user.defaultPage}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {
        ...update,
      },
    }),
  updatePageButtons: async (pageId, elements) =>
    axios({
      method: "put",
      url: `${baseUrl}/v1/pages/${
        pageId || get().user.defaultPage
      }/buttonsmove`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {
        elements,
      },
    }),
  moveButton: (page, elementId, direction) => {
    // This should move an element backwards or forward in the array
    function arrayMove(arr, fromIndex, toIndex) {
      var element = arr[fromIndex];
      arr.splice(fromIndex, 1);
      arr.splice(toIndex, 0, element);
      return arr;
    }
    let elements = page?.draft?.dropZone1?.elements;
    console.log(elements);
    let index = elements.findIndex((element, index) => {
      if (element._id === elementId) {
        return true;
      }
    });
    if (index === 0 && direction === -1) {
      return elements;
    }
    console.log(elements, index, index + direction);
    let modifiedElements = arrayMove(elements, index, index + direction);
    console.log(modifiedElements);
    return modifiedElements;
  },
  updatePageMetrics: async (pageId, type) =>
    axios({
      method: "put",
      url: `${baseUrl}/v1/pages/${pageId}/metrics`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {
        type,
      },
    }),

  showPageNameChangeModal: false,
  setShowPageNameChangeModal: (showPageNameChangeModal) =>
    set({ showPageNameChangeModal }),
  showBackgroundModal: false,
  setShowBackgroundModal: (showBackgroundModal) => set({ showBackgroundModal }),
  showUpgradeModal: false,
  setShowUpgradeModal: (showUpgradeModal) => set({ showUpgradeModal }),

  selectedSquare: null,
  setSelectedSquare: (selectedSquare) => set({ selectedSquare }),

  setFileUploadElement: (elementId) => set({ fileUploadElement: elementId }),
  fileUploadElement: null,

  setShowCalendarInviteModal: (showCalendarInviteModal) =>
    set({ showCalendarInviteModal }),
  showCalendarInviteModal: false,
  calendarInvite: {
    start: new Date(),
    end: new Date(),
    title: "Default Title",
    guests: [],
    description: "Created by i.help",
  },
  setCalendarInvite: (calendarInvite) => set({ calendarInvite }),
  hasNoCalendarIntegration: (user) =>
    !user.hasMicrosoftConnection && !user.hasGoogleConnection,
});
const useStore = create(devtools(store));
export default useStore;
