import { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Container,
  Typography,
  Divider,
  Paper,
  IconButton,
  Grid,
  TextField,
  Modal,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  InputAdornment,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useHistory, useParams } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import { AddCircle, Search } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import SchedulerNavbar from "../../components/SchedulerNavbar";
import AutoCompleteSelect from "../../components/AutoCompleteSelect";
import useStore from "../../store";
import { getBaseUrl } from "../../util";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    minWidth: 100,
    maxWidth: 350,
    minHeight: 200,
    padding: 10,
  },
  modalPaper: {
    position: "absolute",
    [theme.breakpoints.up("xs")]: {
      maxWidth: "100vw",
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: 500,
    },
    backgroundColor: theme.palette.background.paper,
    //border: "2px solid #000",
    borderRadius: 5,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  avatar: {
    height: 50,
    width: 50,
    margin: 10,
  },
  root: {
    padding: 20,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));
function useSearchDebounce(delay = 500) {
  const [search, setSearch] = useState(null);
  const [searchQuery, setSearchQuery] = useState(null);

  useEffect(() => {
    const delayFn = setTimeout(() => setSearch(searchQuery), delay);
    return () => clearTimeout(delayFn);
  }, [searchQuery, delay]);

  return [search, setSearchQuery];
}
const OutlinedButton = ({ label, style, ...rest }) => (
  <Button
    style={{
      marginRight: 10,
      ...style,
      border: "2px solid #30567d",
      color: "#30567d",
    }}
    variant="outlined"
    {...rest}
  >
    {label}
  </Button>
);

const PublicTeam = () => {
  const classes = useStyles();
  function getModalStyle() {
    return {
      top: "40%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    };
  }
  const history = useHistory();
  const { teamName } = useParams();
  const [modalStyle] = useState(getModalStyle);
  const { enqueueSnackbar } = useSnackbar();
  const inviteUser = useStore((state) => state.inviteUser);
  const fetchTeam = useStore((state) => state.fetchTeam);
  const isPremiumUser = useStore((state) => state.isPremiumUser);
  const user = useStore((state) => state.user);
  const searchTeam = useStore((state) => state.searchTeam);
  let [users, setUsers] = useState([]);
  let [showInviteModal, setShowInviteModal] = useState(false);
  let [search, setSearch] = useState("");
  let [isLoading, setIsLoading] = useState(false);
  let [inviteDetails, setInviteDetails] = useState({});
  async function fetchOrgsUsers() {
    let { data: orgsUsers } = await fetchTeam(teamName);
    setUsers(orgsUsers);
  }
  useEffect(() => {
    fetchOrgsUsers();
  }, []);

  async function handleSearch() {
    if (search) {
      let { data: returnedUsers } = await searchTeam(search, teamName);
      setUsers(returnedUsers);
    } else {
      fetchOrgsUsers();
    }
  }

  const NavbarButtons = user
    ? [
        <OutlinedButton
          href={`${getBaseUrl()}/m/${user?.defaultPage}`}
          label="Go to My Page"
        />,
      ]
    : [
        <Button
          target="_blank"
          rel="noopener noreferrer"
          href={`${getBaseUrl()}/login`}
          color="primary"
          variant="contained"
          style={{ margin: 5 }}
        >
          Login
        </Button>,
        <Button
          target="_blank"
          rel="noopener noreferrer"
          href={`${getBaseUrl()}/signup`}
          color="primary"
          variant="contained"
          style={{ margin: 5 }}
        >
          Signup
        </Button>,
      ];
  return (
    <SchedulerNavbar
      hideAddNewMeeting
      hideReturnToPage
      additionalButtons={NavbarButtons}
    >
      <Container maxWidth="lg">
        <Grid container justifyContent="center">
          <Grid item xs={4}>
            <TextField
              value={search}
              label="Search"
              variant="outlined"
              fullWidth
              placeholder="Search name, email, department or title..."
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleSearch}>
                      <Search color="primary" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSearch();
                  // write your functionality here
                }
              }}
            />
          </Grid>
        </Grid>
        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {users.map((accountUser) => (
            <Grid key={accountUser._id} item xs={12} md={4}>
              <Paper className={classes.paper}>
                <Grid container justifyContent="center">
                  <Grid item>
                    <Avatar
                      className={classes.avatar}
                      src={accountUser?.defaultPage?.avatar}
                    />
                  </Grid>
                  <Divider style={{ width: "100%" }} />
                  <Grid item>
                    <Typography variant="h6">
                      {accountUser.firstName} {accountUser.lastName}{" "}
                      <Typography variant="caption">
                        {accountUser.role === "AccountAdmin" ||
                        accountUser.role === "SuperAdmin"
                          ? "(owner)"
                          : ""}
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <div style={{ textAlign: "center" }}>
                      <Typography>
                        {accountUser.title} | {accountUser.department}
                      </Typography>
                    </div>
                  </Grid>
                  <br />
                </Grid>
                <Grid item xs={12}>
                  <div style={{ textAlign: "center" }}>
                    <Button
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`${
                        process.env.NODE_ENV === "production"
                          ? "https://i.help"
                          : "http://localhost:3001"
                      }/${accountUser.defaultPage.name}`}
                      color="primary"
                    >
                      View Page
                    </Button>
                  </div>
                </Grid>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>
    </SchedulerNavbar>
  );
};

export default PublicTeam;
