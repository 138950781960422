import SchedulerNavbar from "../../components/SchedulerNavbar";
import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  TextField,
  CircularProgress,
  Container,
  Button,
  Paper,
  InputAdornment,
} from "@mui/material";
import isUrl from "is-url";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import OrgBackgroundModal from "../../components/OrgBackgroundModal";
import useStore from "../../store";
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(2),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  formHeader: { marginBottom: theme.spacing(2) },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function OrgSettings() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  let [form, setForm] = useState({});
  let [isLoading, setIsLoading] = useState(false);
  let [showOrgBgModal, setShowOrgBgModal] = useState(false);
  let updateOrg = useStore((state) => state.updateOrg);
  let user = useStore((state) => state.user);
  let org = useStore((state) => state.org);
  let updateUser = useStore((state) => state.updateUser);
  let refreshUser = useStore((state) => state.refreshUser);

  useEffect(() => {
    document.title = "Company";
    setForm({
      name: user.org.name,
      teamUrl: user.org.teamUrl,
    });
  }, []);

  let submitForm = async (e) => {
    try {
      setIsLoading(true);
      e.preventDefault();
      await updateOrg(user.org._id, form);
      enqueueSnackbar("Organization updated successfully.", {
        variant: "success",
      });
      setIsLoading(false);
      refreshUser();
    } catch (e) {
      setIsLoading(false);
      enqueueSnackbar(
        e?.response?.data?.error?.message || "Error updating profile.",
        {
          variant: "error",
        }
      );
    }
  };
  return (
    <SchedulerNavbar
      orgLink
      buttonText={"Back to Users"}
      buttonURL={"/p/team"}
      hideAddNewMeeting={true}
    >
      <Container component="main" maxWidth="sm">
        <Paper className={classes.paper}>
          <Typography
            className={classes.formHeader}
            component="h1"
            variant="h5"
          >
            Organization Profile
          </Typography>
          <form className={classes.form} onSubmit={(e) => submitForm(e, form)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  name="name"
                  variant="outlined"
                  required
                  fullWidth
                  id="name"
                  label="Company Name"
                  autoFocus
                  value={form.name}
                  disabled={isLoading}
                  onChange={(e) => setForm({ ...form, name: e.target.value })}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  name="Team URL"
                  variant="outlined"
                  required
                  fullWidth
                  id="teamurl"
                  label="Team URL"
                  autoFocus
                  value={form.teamUrl}
                  disabled={isLoading}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        i.help/hq/
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) =>
                    setForm({ ...form, teamUrl: e.target.value.trim() })
                  }
                />
              </Grid>{" "}
              <Grid item xs={12}>
                <Typography
                  className={classes.formHeader}
                  component="h1"
                  variant="h5"
                >
                  New User Defaults
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Button
                  variant="contained"
                  onClick={() => setShowOrgBgModal(true)}
                >
                  Choose Default Background
                </Button>
              </Grid>
              <Grid item xs={4}>
                <img
                  style={{ width: 60, height: 60 }}
                  src={
                    isUrl(user?.org?.defaultBackground)
                      ? user?.org?.defaultBackground
                      : `https://d2ub6d0yniya4m.cloudfront.net/thumbnails/${user?.org?.defaultBackground}thumb.png`
                  }
                  alt="background img"
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isLoading}
              className={classes.submit}
            >
              {isLoading ? <CircularProgress /> : "Update Profile"}
            </Button>
          </form>
        </Paper>
      </Container>
      <OrgBackgroundModal
        openBgModal={showOrgBgModal}
        onClose={() => setShowOrgBgModal(false)}
      />
    </SchedulerNavbar>
  );
}

export default OrgSettings;
