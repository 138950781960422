import {
  Typography,
  TextField,
  Grid,
  Button,
  Container,
  List,
  Icon,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import ZoomLogo from "../../../assets/zoomlogo.svg";
import MicrosoftLogo from "../../../assets/ms-logo.svg";
import GoogleLogo from "../../../assets/googleLogo.png";
import { makeStyles } from "@mui/styles";
import { MeetingRoomOutlined, ArrowBack, Refresh } from "@mui/icons-material";
import { useState, useEffect } from "react";
import MoveElement from "../../../components/MoveElement";
import StepLabel from "../../../components/StepLabel";
import useStore from "../../../store";
import GoogleButton, {
  MicrosoftButton,
} from "../../../components/OauthButtons";
import ZoomButton from "../../../components/ZoomButton";
const WebConference = ({ onSubmit, cancelForm, onRemove }) => {
  const elementToUpdate = useStore((state) => state.elementToUpdate);
  const refreshGoogleMeetUrl = useStore((state) => state.refreshGoogleMeetUrl);
  const hasGoogleCalendarScope = useStore(
    (state) => state.hasGoogleCalendarScope
  );
  const user = useStore((state) => state.user);
  const refreshUser = useStore((state) => state.refreshUser);

  let [localForm, setLocalForm] = useState({
    elementType: "WebConference",
    defaultPosition: "dropZone1",
    value: elementToUpdate ? elementToUpdate.value : user.defaultMeetingUrl,
    label: elementToUpdate ? elementToUpdate.label : "Join Meeting",
  });
  let [refreshingUrl, setRefreshingUrl] = useState(false);
  let [showGoogle, setShowGoogle] = useState(
    elementToUpdate?.conferenceType === "google"
  );
  let [showZoom, setShowZoom] = useState(
    elementToUpdate?.conferenceType === "zoom"
  );
  let [showMicrosoft, setShowMicrosoft] = useState(
    elementToUpdate?.conferenceType === "microsoft"
  );
  let [showOther, setShowOther] = useState(
    elementToUpdate?.conferenceType === "other"
  );

  const useStyles = makeStyles((theme) => ({
    listItemIcon: {
      minWidth: 38,
    },
  }));
  const classes = useStyles();

  useEffect(() => refreshUser(), []); // reload the user to check for the URLs

  let ZoomIcon = () => (
    <Icon classes={{ root: classes.iconRoot }}>
      <img style={{ height: 24 }} alt="zoomlogo" src={ZoomLogo} />
    </Icon>
  );

  let GoogleIcon = () => (
    <Icon classes={{ root: classes.iconRoot }}>
      <img
        style={{ height: 24, padding: 2 }}
        alt="GoogleLogo"
        src={GoogleLogo}
      />
    </Icon>
  );
  let MicrosoftIcon = () => (
    <Icon classes={{ root: classes.iconRoot }}>
      <img
        style={{ height: 24, padding: 2 }}
        alt="MicrosoftLogo"
        src={MicrosoftLogo}
      />
    </Icon>
  );

  async function handleLinkRefresh() {
    try {
      setRefreshingUrl(true);
      const meetUrlRequest = await refreshGoogleMeetUrl({
        replaceUserMeetingUrl: true,
      });
      console.log(meetUrlRequest.data);
      setLocalForm({
        ...localForm,
        conferenceType: "google",
        value: meetUrlRequest.data.googleMeetUrl,
      });
      refreshUser();
      setRefreshingUrl(false);
    } catch (e) {
      setRefreshingUrl(false);
      console.log(e);
    }
  }

  let meetingButtons = [
    {
      label: "Google Meet",
      PassedIcon: GoogleIcon,
      onClick: () => {
        setShowGoogle(true);
        user.defaultMeetingUrl
          ? setLocalForm({
              ...localForm,
              conferenceType: "google",
              value: user.defaultMeetingUrl,
            })
          : setLocalForm({ ...localForm, value: "" });
      },
    },
    {
      label: "Microsoft Teams",
      PassedIcon: MicrosoftIcon,
      onClick: () => {
        setShowMicrosoft(true);
        user.defaultMeetingUrl
          ? setLocalForm({
              ...localForm,
              conferenceType: "microsoft",
              value: user.defaultMeetingUrl,
            })
          : setLocalForm({ ...localForm, value: "" });
      },
    },
    {
      label: "Zoom",
      PassedIcon: ZoomIcon,
      onClick: () => {
        setShowZoom(true); // Because Zoom Oauth is broken
        user.zoomMeetingUrl
          ? setLocalForm({
              ...localForm,
              conferenceType: "zoom",
              value: user.zoomMeetingUrl,
            })
          : setLocalForm({ ...localForm, conferenceType: "other", value: "" });
      },
    },
    {
      label: "Other",
      PassedIcon: MeetingRoomOutlined,
      onClick: () => {
        setShowOther(true);
        setLocalForm({ ...localForm, conferenceType: "other", value: "" });
      },
    },
  ];

  let typeIsSelected = showZoom || showGoogle || showMicrosoft || showOther;
  let handleBack = () => {
    setShowOther(false);
    setShowGoogle(false);
    setShowMicrosoft(false);
    setShowZoom(false);
  };
  return (
    <Container style={{ paddingLeft: 2, paddingRight: 8 }}>
      <form style={{ width: "100%" }} onSubmit={(e) => onSubmit(e, localForm)}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              {elementToUpdate ? "Update" : "Create"} Web Conference Button
            </Typography>
          </Grid>
          {typeIsSelected && (
            <Grid xs={12}>
              <Typography variant={"subtitle"}>
                <IconButton color="primary" onClick={handleBack}>
                  <ArrowBack />
                </IconButton>
                Web Conference Types
              </Typography>
            </Grid>
          )}

          {!typeIsSelected && (
            <Grid xs={12}>
              <List>
                {meetingButtons.map(({ label, PassedIcon, onClick }) => (
                  <ListItem
                    button
                    key={label}
                    style={{ marginTop: -2, marginBottom: -2 }}
                    onClick={onClick}
                  >
                    <ListItemIcon className={classes.listItemIcon}>
                      <PassedIcon />
                    </ListItemIcon>
                    <ListItemText primary={label} />
                  </ListItem>
                ))}
              </List>
            </Grid>
          )}

          {showGoogle && (
            <>
              <Grid item xs={12}>
                <>
                  <p style={{ fontWeight: 700 }}>Step 1:</p>
                  <GoogleButton disabled={hasGoogleCalendarScope(user)} />
                </>
                {user.hasGoogleConnection && (
                  <Typography variant="caption">
                    <a href="/connections">Manage Google Connection</a>
                  </Typography>
                )}
                <br />
                {hasGoogleCalendarScope(user) && (
                  <Typography variant="caption">
                    URL: {refreshingUrl ? "refreshing..." : localForm.value}
                    <Refresh
                      color="primary"
                      onClick={handleLinkRefresh}
                      style={{ paddingTop: 10, cursor: "pointer" }}
                    />
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12}>
                <StepLabel
                  step="Step 2:"
                  subtitle="What would you like the button to say?"
                />
                <TextField
                  variant="outlined"
                  fullWidth
                  value={localForm.label}
                  onChange={(e) =>
                    setLocalForm({ ...localForm, label: e.target.value })
                  }
                />
              </Grid>
            </>
          )}
          {showMicrosoft && (
            <>
              <Grid item xs={12}>
                <>
                  <p style={{ fontWeight: 700 }}>Step 1:</p>
                  <MicrosoftButton disabled={user.hasMicrosoftConnection} />
                </>
                {user.hasMicrosoftConnection && (
                  <Typography variant="caption">
                    <a href="/connections">Manage Microsoft Connection</a>
                  </Typography>
                )}
                <br />
                {/* {hasGoogleCalendarScope(user) && (
                  <Typography variant="caption">
                    URL: {refreshingUrl ? "refreshing..." : localForm.value}
                    <Refresh
                      color="primary"
                      onClick={handleLinkRefresh}
                      style={{ paddingTop: 10, cursor: "pointer" }}
                    />
                  </Typography>
                )} */}
              </Grid>

              <Grid item xs={12}>
                <StepLabel
                  step="Step 2:"
                  subtitle="What would you like the button to say?"
                />
                <TextField
                  variant="outlined"
                  fullWidth
                  value={localForm.label}
                  onChange={(e) =>
                    setLocalForm({ ...localForm, label: e.target.value })
                  }
                />
              </Grid>
            </>
          )}
          {showZoom && (
            <>
              {/* <Grid item xs={12}>
                <>
                  <StepLabel step="Step 1:" subtitle="Connect to Zoom" />
                  <ZoomButton disabled={user.hasZoomConnection} />
                  {user.hasZoomConnection && (
                    <Typography variant="caption">
                      URL: {localForm.value}
                    </Typography>
                  )}
                </>
              </Grid> */}
              <Grid item xs={12}>
                <StepLabel
                  step="Step 1:"
                  link="https://intercom.help/makeitappen/en/articles/5368598-how-to-add-a-button-to-join-a-meeting"
                  subtitle="Enter your Personal Meeting Room URL:"
                  linkText="Where do I find this?"
                />
                <TextField
                  variant="outlined"
                  value={localForm.value}
                  fullWidth
                  type="url"
                  placeholder="http://us04web.zoom.us/j/yourlink"
                  onChange={(e) =>
                    setLocalForm({ ...localForm, value: e.target.value })
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <StepLabel
                  step="Step 2:"
                  subtitle="What would you like the button to say?"
                />
                <TextField
                  variant="outlined"
                  fullWidth
                  value={localForm.label}
                  onChange={(e) =>
                    setLocalForm({ ...localForm, label: e.target.value })
                  }
                />
              </Grid>
            </>
          )}
          {showOther && (
            <>
              <Grid item xs={12}>
                <StepLabel
                  step="Step 1:"
                  link="https://intercom.help/makeitappen/en/articles/5368598-how-to-add-a-button-to-join-a-meeting"
                  subtitle="Enter your Web Conference URL:"
                  linkText="Where do I find this?"
                />
                <TextField
                  variant="outlined"
                  value={localForm.value}
                  fullWidth
                  type="url"
                  onChange={(e) =>
                    setLocalForm({ ...localForm, value: e.target.value })
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <StepLabel
                  step="Step 2:"
                  subtitle="What would you like the button to say?"
                />
                <TextField
                  variant="outlined"
                  fullWidth
                  value={localForm.label}
                  onChange={(e) =>
                    setLocalForm({ ...localForm, label: e.target.value })
                  }
                />
              </Grid>
            </>
          )}

          <Grid item xs={6}>
            <Button variant="outlined" fullWidth onClick={cancelForm}>
              CANCEL
            </Button>
          </Grid>
          {typeIsSelected && (
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={!localForm.value}
                fullWidth
              >
                SAVE
              </Button>
            </Grid>
          )}
          {elementToUpdate && (
            <Container style={{ textAlign: "center" }}>
              <Button
                onClick={onRemove}
                fullWidth
                style={{ color: "red" }}
                variant="text"
              >
                REMOVE BUTTON
              </Button>
              <MoveElement element={elementToUpdate} />
            </Container>
          )}
          {/* {typeIsSelected && <MoveButtons />} */}
        </Grid>
      </form>
    </Container>
  );
};

export default WebConference;
