import { Link } from "react-router-dom";
import Navigation from "../../components/Navigation";
import {
  TextField,
  Grid,
  Paper,
  CssBaseline,
  Container,
  InputAdornment,
} from "@mui/material";
import useStore from "../../store";
import { makeStyles } from "@mui/styles";
import { AttachMoney, Loop, SyncDisabledTwoTone } from "@mui/icons-material";
function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  formHeader: { marginBottom: theme.spacing(2) },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
function Dashboard() {
  const classes = useStyles();
  const store = useStore();

  return (
    <Navigation pageName="Dashboard" pageDescription="The dashboard.">
      <Container component="main" maxWidth="sm">
        <CssBaseline />
      </Container>
    </Navigation>
  );
}

export default Dashboard;
