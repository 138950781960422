import React, { useEffect, useState } from "react";
import { Paper, Avatar, Grid, Typography, Fab, Button } from "@mui/material";
import isUrl from "is-url";
import { useParams, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { makeStyles, useTheme } from "@mui/styles";
import useStore from "../../store";
import { useQuery } from "../../util";
import { OpenInNew } from "@mui/icons-material";
import PlaceHolderPage from "./PlaceHolderPage.js";
import DeactivatedPage from "./DeactivatedPage.js";
import Element from "../../components/Element";
import logo from "../../assets/ihelp_logo.svg";
const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    minWidth: "100%",
    // position: "fixed",
  },
  paper: {
    //marginTop: "50%",
    [theme.breakpoints.up("lg")]: {
      marginTop: "35%",
    },
    [theme.breakpoints.up("xl")]: {
      marginTop: "35%",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "35%",
    },
    [theme.breakpoints.between("xs", "sm")]: {
      marginTop: "40%",
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: 200,
    maxWidth: 600,
    borderRadius: 20,
    textAlign: "center",
    padding: 20,
    zIndex: 5,
  },
  editPaper: {
    //marginTop: "50%",
    [theme.breakpoints.up("lg")]: {
      marginTop: "5%",
    },
    [theme.breakpoints.up("xl")]: {
      marginTop: "5%",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "5%",
    },
    [theme.breakpoints.between("xs", "sm")]: {
      marginTop: "5%",
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: 100,
    maxWidth: 600,
    borderRadius: 20,
    textAlign: "center",
    padding: 20,
    zIndex: 5,
  },
  avatar: {
    marginTop: theme.spacing(-8),
    width: theme.spacing(15),
    height: theme.spacing(15),
    marginBottom: 10,
  },
  actionButton: {
    margin: 5,
  },
  inlineContent: {
    display: "inline-block",
    textAlign: "center",
  },
  image: {
    maxHeight: 75,
    margin: 5,
  },
  divider: {
    width: "60%",
    marginTop: 15,
    marginBottom: 15,
  },
  referralFab: {
    position: "absolute",
    right: 110,
    margin: 10,
    backgroundColor: "#fff",
    fontSize: "1rem",
    zIndex: 3,
    whiteSpace: "nowrap",
    height: 30,
  },
  loginButton: {
    position: "absolute",
    right: 0,
    margin: 10,
    zIndex: 3,
    color: "#fff",
    whiteSpace: "nowrap",
    height: 30,
    fontSize: "1rem",
    //borderRadius: "20%",
  },
  backgroundVideo: {
    zIndex: -1,
    position: "fixed",
  },
}));

const HelpPage = (props) => {
  let [page, setPage] = useState(null);
  let [fetchPageError, setFetchPageError] = useState(null);
  let { pageId } = useParams();
  let history = useHistory();
  let query = useQuery();
  let mode = query.get("mode") === "draft" ? "draft" : "published";
  let idType = query.get("idType");
  const classes = useStyles();
  const { isLoadingPage, updatePageMetrics, clearPage, fetchPageRequest } =
    useStore((state) => ({
      clearPage: state.clearPage,
      isLoadingPage: state.isLoadingPage,
      updatePageMetrics: state.updatePageMetrics,
      fetchPageRequest: state.fetchPageRequest,
    }));
  let ownsPage =
    localStorage.getItem("token") &&
    localStorage.getItem("pageId") === page?._id.toString();

  let moreThanOneHourAgo = (date) => {
    const HOUR = 1000 * 60 * 60;
    const anHourAgo = Date.now() - HOUR;

    return new Date(date) < anHourAgo;
  };
  let handleMetricUpdate = async () => {
    let viewedPages = window.localStorage.getItem("viewedPages")
      ? JSON.parse(window.localStorage.getItem("viewedPages"))
      : [];

    let lastPageViewRecordedAt = window.localStorage.getItem(
      "lastPageViewRecordedAt"
    );
    // Page views per visitor counted every hour
    if (
      lastPageViewRecordedAt === null ||
      moreThanOneHourAgo(lastPageViewRecordedAt)
    ) {
      await updatePageMetrics(page?._id, "pageViews");
      window.localStorage.setItem("lastPageViewRecordedAt", new Date());
    }
    // Unique page views
    if (!viewedPages.includes(pageId)) {
      await updatePageMetrics(page?._id, "uniquePageViews");
      window.localStorage.setItem(
        "viewedPages",
        JSON.stringify([pageId, ...viewedPages])
      );
    }
  };

  const handlePageFetch = async () => {
    try {
      const { data: fetchedPage } = await fetchPageRequest(
        pageId,
        idType === "mongo" ? "mongo" : "name",
        mode
      );
      if (
        !["active", "past_due", "trialing"].includes(
          fetchedPage?.org?.stripe?.status
        ) ||
        (fetchedPage?.org?.stripe?.status === "past_due" &&
          !fetchedPage?.org?.stripe?.defaultPaymentMethod)
      ) {
        setFetchPageError("The subscription for this page has expired.");
      } else {
        setPage(fetchedPage);
      }
    } catch (e) {
      console.log(e);
      setFetchPageError("Page was not found.");
    }
  };

  useEffect(() => {
    document.title = "i.help: " + pageId;
    handlePageFetch();
  }, []);

  useEffect(() => {
    if (page?._id) {
      handleMetricUpdate();
    }
  }, [page]);

  function handleReferralClick() {
    localStorage.setItem("referralPage", page._id);
    clearPage();
    window.location = `https://www.i.help`;
  }
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`Meet with ${pageId}`} />
      </Helmet>
      {isLoadingPage && !fetchPageError ? (
        <div></div>
      ) : fetchPageError ? (
        <PlaceHolderPage error={fetchPageError} />
      ) : (
        <>
          {page?.backgroundVideo && (
            <video className={classes.backgroundVideo} autoPlay loop muted>
              <source src={page?.backgroundVideo} type="video/mp4" />
            </video>
          )}
          <div
            id="page-content"
            style={{
              ...(!page?.backgroundVideo && page?.backgroundImage
                ? {
                    backgroundImage: isUrl(page?.backgroundImage)
                      ? `url("${page?.backgroundImage}")`
                      : `url("https://d2ub6d0yniya4m.cloudfront.net/bgimages/${page?.backgroundImage}.png")`,
                    backgroundSize: "100% 100%",
                  }
                : !page?.backgroundVideo
                ? { backgroundColor: page?.color || "#5179a1" }
                : null),
              height: "calc(100vh + 200px)",
            }}
            className={classes.root}
          >
            {!ownsPage && (
              <Fab
                onClick={handleReferralClick}
                variant="extended"
                className={classes.referralFab}
              >
                Like this page? Create yours for free at{"  "}
                <img
                  style={{ height: 20, paddingLeft: 5 }}
                  src={logo}
                  alt="logo"
                />
              </Fab>
            )}
            <Fab
              color="primary"
              onClick={() => {
                if (ownsPage) {
                  localStorage.clear();
                  window.location.reload(true);
                } else {
                  history.push("/login");
                }
              }}
              variant="extended"
              className={classes.loginButton}
            >
              {localStorage.getItem("token") &&
              localStorage.getItem("pageId") === page?._id.toString()
                ? "Sign Out "
                : "Log In"}
            </Fab>

            <Grid justifyContent="center" alignContent="center" container>
              <Grid item xs={9} sm={6} md={6} lg={5} xl={4}>
                <Paper className={classes.paper}>
                  <Avatar className={classes.avatar} src={page?.avatar} />
                  <Typography variant="h4">{page?.primaryMessage}</Typography>
                  <Typography style={{ paddingBottom: 5 }}>
                    {page?.message}
                  </Typography>

                  <div
                    style={{ marginBottom: 14, marginTop: 11 }}
                    className={classes.inlineContent}
                  >
                    {page &&
                      page[mode]?.dropZone1?.elements.map((element) => (
                        <Element element={element} pageId={pageId}>
                          {element?.button?.label}
                        </Element>
                      ))}
                  </div>

                  <div className={classes.inlineContent}>
                    {page &&
                      page[mode]?.dropZone2?.elements.map((element) => (
                        <Element element={element} pageId={pageId}>
                          {element?.button?.label}
                        </Element>
                      ))}
                  </div>
                </Paper>
              </Grid>
            </Grid>
            {ownsPage && (
              <Grid justifyContent="center" alignContent="center" container>
                <Grid item xs={9} sm={6} md={6} lg={5} xl={4}>
                  <Paper className={classes.editPaper}>
                    <Typography>Only you can see this section.</Typography>

                    <div
                      style={{ marginBottom: 5, marginTop: 11 }}
                      className={classes.inlineContent}
                    >
                      <Button
                        style={{ margin: 5, backgroundColor: "#114982" }}
                        variant="contained"
                        onClick={() => history.push("/p/find-availability")}
                      >
                        Find Meeting Times
                      </Button>
                      <Button
                        style={{ margin: 5, backgroundColor: "#114982" }}
                        variant="contained"
                        onClick={() =>
                          history.push("/p/find-availability/events")
                        }
                      >
                        View Meeting Time Responses
                      </Button>
                      <Button
                        style={{ margin: 5, backgroundColor: "#114982" }}
                        variant="contained"
                        onClick={() => history.push("/p/files")}
                      >
                        View Uploaded Files
                      </Button>
                    </div>

                    <div className={classes.inlineContent}>
                      <Button
                        onClick={() => history.push("/p/edit")}
                        variant="outlined"
                        startIcon={<OpenInNew />}
                      >
                        Edit My Page
                      </Button>
                    </div>
                  </Paper>
                </Grid>
              </Grid>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default HelpPage;
