import { useEffect, useState } from "react";
import { add, format } from "date-fns";
import {
  Container,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Switch,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { useLocation } from "react-router-dom";
import SchedulerNavbar from "../../components/SchedulerNavbar";
import useStore from "../../store";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    minHeight: 250,
    padding: 10,
    marginBottom: 30,
  },
}));

const OwnedDomains = () => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const fetchOwnedDomains = useStore((state) => state.fetchOwnedDomains);
  const updateOwnedDomains = useStore((state) => state.updateOwnedDomains);
  const updateByStripeSession = useStore(
    (state) => state.updateByStripeSession
  );
  const refreshUser = useStore((state) => state.refreshUser);
  const [domains, setDomains] = useState([]);
  let fetchDomains = async () => {
    let { data: ownedDomains } = await fetchOwnedDomains();
    setDomains(ownedDomains);
  };
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let handleSessionUpdate = async (session, purchasedDomain) => {
    if (purchasedDomain) {
      enqueueSnackbar(
        "Your new domain is being registered and will appear here once the process is complete. This can take a few minutes.",
        {
          variant: "success",
        }
      );
    }
    await updateByStripeSession(session);

    fetchDomains();
    refreshUser();
  };
  const query = useQuery();
  useEffect(() => {
    fetchDomains();
    let session = query.get("session");
    let purchasedDomain = query.get("domainToPurchase");
    if (purchasedDomain) {
      handleSessionUpdate(session, purchasedDomain);
    }
  }, []);

  const handleDomainUpdate = async (domain, update) => {
    await updateOwnedDomains(domain._id, update);
    fetchDomains();
  };
  return (
    <SchedulerNavbar hideAddNewMeeting>
      <Container maxWidth="md">
        <Paper className={classes.paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Domain</TableCell>
                <TableCell>Redirects To</TableCell>
                <TableCell>Auto Renewal</TableCell>
                <TableCell>Renews At</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {domains.map((domain) => (
                <TableRow>
                  <TableCell>{domain.name}</TableCell>
                  <TableCell>
                    {" "}
                    <a
                      href={`${
                        process.env.NODE_ENV === "production"
                          ? "https://i.help"
                          : "http://localhost:3001"
                      }/${domain?.page?.name}`}
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: "none" }}
                      onMouseOver={(event) =>
                        (event.target.style.textDecoration = "underline")
                      }
                      onMouseOut={(event) =>
                        (event.target.style.textDecoration = "none")
                      }
                    >
                      {`${
                        process.env.NODE_ENV === "production"
                          ? "i.help"
                          : "localhost:3001"
                      }/${domain?.page?.name}`}
                    </a>
                  </TableCell>
                  <TableCell>
                    {" "}
                    <Switch
                      color="primary"
                      checked={domain.autoRenew}
                      onChange={() =>
                        handleDomainUpdate(domain, {
                          autoRenew: !domain.autoRenew,
                        })
                      }
                      name="autoRenew"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  </TableCell>
                  <TableCell>
                    {format(
                      add(new Date(domain.renewedAt), { years: 1 }),
                      "MM/dd/yyyy"
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </Container>
    </SchedulerNavbar>
  );
};

export default OwnedDomains;
