import SchedulerNavbar from "../../components/SchedulerNavbar";
import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  TextField,
  CircularProgress,
  Container,
  Button,
  Paper,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import useStore from "../../store";
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(2),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  formHeader: { marginBottom: theme.spacing(2) },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function Profile() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  let [form, setForm] = useState({});
  let [isLoading, setIsLoading] = useState(false);
  let user = useStore((state) => state.user);
  let updateUser = useStore((state) => state.updateUser);
  let refreshUser = useStore((state) => state.refreshUser);

  useEffect(() => {
    document.title = "Profile";
    setForm({
      firstName: user.firstName,
      lastName: user.lastName,
      title: user.title,
      department: user.department,
      email: user.email,
    });
  }, []);

  let submitForm = async (e) => {
    try {
      setIsLoading(true);
      e.preventDefault();
      await updateUser(form);
      enqueueSnackbar("Profile updated successfully.", {
        variant: "success",
      });
      setIsLoading(false);
      refreshUser();
    } catch (e) {
      setIsLoading(false);
      enqueueSnackbar(
        e?.response?.data?.error?.message || "Error updating profile.",
        {
          variant: "error",
        }
      );
    }
  };
  return (
    <SchedulerNavbar hideAddNewMeeting={true}>
      <Container component="main" maxWidth="sm">
        <Paper className={classes.paper}>
          <Typography
            className={classes.formHeader}
            component="h1"
            variant="h5"
          >
            Update Your Profile
          </Typography>
          <form className={classes.form} onSubmit={(e) => submitForm(e, form)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  autoComplete="fname"
                  name="firstName"
                  variant="outlined"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                  value={form.firstName}
                  disabled={isLoading}
                  onChange={(e) =>
                    setForm({ ...form, firstName: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="lname"
                  value={form.lastName}
                  disabled={isLoading}
                  onChange={(e) =>
                    setForm({ ...form, lastName: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  fullWidth
                  id="title"
                  label="Title"
                  name="title"
                  autoComplete="title"
                  value={form.title}
                  disabled={isLoading}
                  onChange={(e) => setForm({ ...form, title: e.target.value })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  fullWidth
                  id="department"
                  label="Department"
                  name="department"
                  disabled={isLoading}
                  value={form.department}
                  autoComplete="department"
                  onChange={(e) =>
                    setForm({
                      ...form,
                      department: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  type="email"
                  disabled={isLoading}
                  value={form.email}
                  autoComplete="email"
                  onChange={(e) =>
                    setForm({
                      ...form,
                      email: e.target.value.toLowerCase().trim(),
                    })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  fullWidth
                  name="password"
                  label="Password"
                  placeholder="New Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={form.password}
                  onChange={(e) =>
                    setForm({ ...form, password: e.target.value })
                  }
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isLoading}
              className={classes.submit}
            >
              {isLoading ? <CircularProgress /> : "Update Profile"}
            </Button>
          </form>
        </Paper>
      </Container>
    </SchedulerNavbar>
  );
}

export default Profile;
