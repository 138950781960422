import React from "react";
import { makeStyles } from "@mui/styles";
import { Modal, Button, Typography, Grid } from "@mui/material";
import { Done } from "@mui/icons-material";

import useStore from "../store";

function getModalStyle() {
  const top = 40;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 350,
    backgroundColor: theme.palette.background.paper,
    //border: "2px solid #000",
    borderRadius: 5,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

let Feature = ({ text }) => (
  <div>
    <Done style={{ color: "green", paddingTop: 10 }} />{" "}
    <span style={{ fontWeight: "bold" }}>{text}</span>
  </div>
);

export default function UpgradeModal() {
  const classes = useStyles();
  let showUpgradeModal = useStore((state) => state.showUpgradeModal);
  let setShowUpgradeModal = useStore((state) => state.setShowUpgradeModal);
  const [modalStyle] = React.useState(getModalStyle);

  return (
    <Modal
      open={showUpgradeModal}
      onClose={() => setShowUpgradeModal(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Grid
        container
        spacing={2}
        justifyContent="center"
        style={modalStyle}
        className={classes.paper}
      >
        <Grid container spacing={2} style={{ marginBottom: 10 }}>
          <Grid item xs={12}>
            <h2 id="simple-modal-title">Upgrade to Premium</h2>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" id="simple-modal-description">
              Everything in Free PLUS:
            </Typography>
            <Feature text="Unlimited Buttons" />
            <Feature text="Short i.help link (/mike)" />
            <Feature text="Custom Page Backgrounds" />
            <Feature text="Priority Support" />
          </Grid>
        </Grid>

        <Grid container justifyContent="flex-end" alignItems="flex-end">
          <Grid item xs={12} md={10}>
            <Button
              style={{ marginRight: 5 }}
              //variant="outlined"
              onClick={() => setShowUpgradeModal(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              href="/billing"
            >
              Upgrade
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
}
