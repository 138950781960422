import { useLocation } from "react-router-dom";
import { useEffect, useRef } from "react";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function capitalize(s) {
  return s && s[0].toUpperCase() + s.slice(1);
}

function getBaseUrl() {
  return process.env.NODE_ENV === "production"
    ? "https://i.help"
    : "http://localhost:3001";
}

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export { useQuery, capitalize, useInterval, getBaseUrl };
