import React from "react";
import { makeStyles } from "@mui/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import logo from "../assets/ihelp_logo.svg";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: { boxShadow: "none", backgroundColor: "#f0f8ff", maxHeight: 61 },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  logoContainer: {
    flexGrow: 1,
  },
  logo: {
    maxHeight: 40,
    maxWidth: 80,
  },
  button: {
    color: "#607f9e",
  },
}));

export default function NavBar({ buttonText, buttonURL, children }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} position="static">
        <Toolbar>
          <div className={classes.logoContainer}>
            <img src={logo} alt="Ihelp" className={classes.logo} />
          </div>

          <Button
            className={classes.button}
            href={buttonURL || "/signup"}
            color="inherit"
          >
            {buttonText || `Sign Up`}
          </Button>
        </Toolbar>
      </AppBar>
      {children}
    </div>
  );
}
