import React, { useEffect, useState } from "react";
import FormMenu from "./FormMenu";
import ClickWrapper from "./ClickWrapper";
import Element from "../../components/Element";
import DefaultLoadingScreen from "../../components/DefaultLoadingScreen";
import BackgroundModal from "../../components/BackgroundModal";
import UpgradeModal from "../../components/UpgradeModal";
import {
  Grid,
  Paper,
  Container,
  Avatar,
  Typography,
  Fab,
  IconButton,
  Button,
} from "@mui/material";
import { useLocation, useHistory } from "react-router-dom";
import { Edit, Menu, OpenInNew } from "@mui/icons-material";
import useStore from "../../store";
import { makeStyles } from "@mui/styles";
import { useInterval } from "../../util";
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(10),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: 200,
    maxWidth: 600,
    borderRadius: 20,
    textAlign: "center",
    padding: 20,
  },
  loginButton: {
    position: "absolute",
    top: 0,
    right: 0,
    margin: 20,
    zIndex: 3,
    color: "#fff",
    whiteSpace: "nowrap",
    height: 30,
    fontSize: "1rem",
    //borderRadius: "20%",
  },
  avatar: {
    marginTop: theme.spacing(-8),
    width: theme.spacing(15),
    height: theme.spacing(15),
    marginBottom: 10,
  },
  actionButton: {
    margin: 5,
  },
  inlineContent: {
    display: "inline-block",
    textAlign: "center",
  },
  image: {
    maxHeight: 75,
    margin: 5,
  },
  divider: {
    width: "60%",
    marginTop: 15,
    marginBottom: 15,
  },
}));
function HelpPage() {
  const classes = useStyles();
  const history = useHistory();
  const [mobileOpen, setMobileOpen] = useState(false);
  const fetchPage = useStore((state) => state.fetchPage);
  const page = useStore((state) => state.page);
  const user = useStore((state) => state.user);
  const updateByStripeSession = useStore(
    (state) => state.updateByStripeSession
  );
  const setShowForm = useStore((state) => state.setShowForm);
  const setFormName = useStore((state) => state.setFormName);
  const isPremiumUser = useStore((state) => state.isPremiumUser);
  const setShowBackgroundModal = useStore(
    (state) => state.setShowBackgroundModal
  );
  const refreshUser = useStore((state) => state.refreshUser);
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  useEffect(() => {
    document.title = "Edit Page";
    fetchPage(user.defaultPage, "mongo", "draft");
    window.Intercom("update");
    window.Intercom("boot", {
      app_id: "gpn6c78o",
      name: user.firstName + " " + user.lastName, // Full name
      email: user.email, // Email address
    });
    let session = query.get("session");
    if (session) {
      handleSessionUpdate(session);
    } else if (!isPremiumUser(user)) {
      history.push("/billing");
    }
    window.Upscope("updateConnection", {
      // Set the user ID below. If you don't have one, set to undefined.
      uniqueId: user.email,
      // Set the user name or email below (e.g. ["John Smith", "john.smith@acme.com"]).
      identities: [user.firstName + " " + user.lastName],
    });

    // window.smartlook("identify", user._id.toString(), {
    //   name: user.firstName + " " + user.lastName,
    //   email: user.email,
    // });
  }, []);

  useInterval(() => {
    fetchPage(user.defaultPage, "mongo", "draft");
  }, 5000);

  let handleSessionUpdate = async (session) => {
    await updateByStripeSession(session);
    refreshUser();
  };
  let handleEditClick = (name) => {
    setShowForm(true);
    setFormName(name);
  };

  return (
    <>
      {page ? (
        <FormMenu
          pageName="HelpPage"
          pageDescription="The help page."
          page={page}
          mobileOpen={mobileOpen}
          setMobileOpen={setMobileOpen}
        >
          <div style={{ display: "inline" }}>
            <Fab
              size="small"
              sx={{
                display: { xs: "inline", md: "none" },
                backgroundColor: "#fff",
                marginRight: 1,
                paddingTop: 1,
              }}
              color="inherit"
              aria-label="menu"
              onClick={() => setMobileOpen(!mobileOpen)}
              className={classes.menuButton}
            >
              <Menu style={{ color: "#072757" }} />
            </Fab>
            <Fab
              variant="extended"
              style={{
                backgroundColor: "#fff",
                whiteSpace: "nowrap",
                height: 36,
              }}
              onClick={() => setShowBackgroundModal(true)}
            >
              Change Background
              <Edit style={{ paddingLeft: 5 }} />
            </Fab>
          </div>
          <Container>
            <Grid
              justifyContent="center"
              alignContent="center"
              spacing={2}
              container
            >
              <Grid item xs={12} md={8} lg={8}>
                <IconButton
                  aria-label="edit"
                  size="small"
                  style={{
                    marginBottom: -95,
                    marginLeft: "43%",
                    backgroundColor: "#fff",
                    zIndex: 999,
                    //position: "absolute",
                    // backgroundColor: "#fff",
                  }}
                  onClick={() => handleEditClick("AvatarForm")}
                >
                  <Edit size="small" style={{ fontSize: "1.05em" }} />
                </IconButton>
                <Paper className={classes.paper}>
                  <Avatar className={classes.avatar} src={page?.avatar} />
                  <Typography variant="h4">
                    {page?.primaryMessage}
                    {page?.primaryMessage && (
                      <IconButton
                        aria-label="edit"
                        size="small"
                        style={{
                          marginBottom: 70,
                          position: "absolute",
                          // marginLeft: -20,
                          // backgroundColor: "#fff",
                        }}
                        onClick={() => handleEditClick("PageMessage")}
                      >
                        <Edit size="small" style={{ fontSize: "1.05em" }} />
                      </IconButton>
                    )}
                  </Typography>

                  <Typography style={{ paddingBottom: 5 }}>
                    {page?.message}
                    {page?.message && (
                      <IconButton
                        aria-label="edit"
                        size="small"
                        style={{
                          marginBottom: 70,
                          position: "absolute",
                          // marginLeft: -20,
                          // backgroundColor: "#fff",
                        }}
                        onClick={() => handleEditClick("PageMessage")}
                      >
                        <Edit size="small" style={{ fontSize: "1.05em" }} />
                      </IconButton>
                    )}
                  </Typography>

                  <div className={classes.inlineContent}>
                    {page.draft.dropZone1.elements.map((element) => (
                      <ClickWrapper key={element._id} element={element}>
                        <Element disabled element={element} pageId={page?.name}>
                          {element?.button?.label}
                        </Element>
                      </ClickWrapper>
                    ))}
                  </div>
                  <div className={classes.inlineContent}>
                    {page.draft.dropZone2.elements.map((element) => (
                      <ClickWrapper key={element._id} element={element}>
                        <Element element={element}>
                          {element?.button?.label}
                        </Element>
                      </ClickWrapper>
                    ))}
                  </div>
                </Paper>
              </Grid>

              <Grid item xs={12} md={8} lg={8}>
                <Paper
                  className={classes.paper}
                  style={{ marginTop: 0, minHeight: 150 }}
                >
                  <Typography>Only you can see this section.</Typography>

                  <div
                    style={{ marginBottom: 5, marginTop: 11 }}
                    className={classes.inlineContent}
                  >
                    <Button
                      style={{ margin: 5, backgroundColor: "#114982" }}
                      variant="contained"
                      onClick={() => history.push("/p/find-availability")}
                    >
                      Find Meeting Times
                    </Button>
                    <Button
                      style={{ margin: 5, backgroundColor: "#114982" }}
                      variant="contained"
                      onClick={() =>
                        history.push("/p/find-availability/events")
                      }
                    >
                      View Meeting Time Responses
                    </Button>
                    <Button
                      style={{ margin: 5, backgroundColor: "#114982" }}
                      variant="contained"
                      onClick={() => history.push("/p/files")}
                    >
                      View Uploaded Files
                    </Button>
                  </div>

                  <div className={classes.inlineContent}>
                    <Button
                      onClick={() => history.push(`/${page?.name}`)}
                      variant="outlined"
                      startIcon={<OpenInNew />}
                    >
                      View Public Page
                    </Button>
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </Container>
          <BackgroundModal />
          <UpgradeModal />
          {/* <Fab
            color="primary"
            onClick={() => history.push(`/${page?.name}`)}
            variant="extended"
            className={classes.loginButton}
          >
            View Live Page
          </Fab> */}
        </FormMenu>
      ) : (
        <DefaultLoadingScreen />
      )}
    </>
  );
}

export default HelpPage;
