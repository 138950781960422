import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Modal,
  Button,
  TextField,
  Typography,
  InputAdornment,
  Grid,
  Divider,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import useStore from "../store";

function getModalStyle() {
  const top = 40;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    //border: "2px solid #000",
    borderRadius: 5,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function PageNameChangeModal() {
  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  let updatePage = useStore((state) => state.updatePage);
  let fetchPage = useStore((state) => state.fetchPage);
  let showPageNameChangeModal = useStore(
    (state) => state.showPageNameChangeModal
  );
  let page = useStore((state) => state.page);
  let user = useStore((state) => state.user);
  let isPremiumUser = useStore((state) => state.isPremiumUser);
  let [name, setName] = useState(page.name);
  let [isSubmitting, setIsSubmitting] = useState(false);
  let setShowPageNameChangeModal = useStore(
    (state) => state.setShowPageNameChangeModal
  );
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);

  let handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await updatePage(page._id, { name });
      setIsSubmitting(false);
      setShowPageNameChangeModal(false);
      fetchPage();
      enqueueSnackbar("Your page link has been updated.", {
        variant: "success",
      });
    } catch (err) {
      setIsSubmitting(false);
      enqueueSnackbar(
        (err?.response?.data?.error &&
          err?.response?.data?.error?.["0"]?.msg) ||
          "Error updating page.",
        { variant: "error" }
      );
    }
  };

  return (
    <>
      <Modal
        open={showPageNameChangeModal}
        onClose={() => setShowPageNameChangeModal(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        id="page-name-modal"
      >
        <form onSubmit={handleSubmit}>
          <Grid
            container
            spacing={1}
            style={modalStyle}
            className={classes.paper}
          >
            <Grid item xs={12}>
              <h2 id="simple-modal-title">Edit your public link</h2>
              {isPremiumUser(user) ? (
                <Typography variant="body1" id="simple-modal-description">
                  Your link must contain at least 3 characters.
                </Typography>
              ) : (
                <Typography variant="body1" id="simple-modal-description">
                  Your link must contain at least 8 characters. Want a shorter
                  link? <a href="/billing"> Click here to upgrade to Pro</a>
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                disabled={isSubmitting}
                autoFocus
                fullWidth
                defaultValue={name}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">i.help/</InputAdornment>
                  ),
                }}
                onChange={(e) =>
                  setName(e.target.value && e.target.value.toLowerCase())
                }
                variant="outlined"
              />
            </Grid>
            <Grid
              container
              justifyContent="flex-end"
              spacing={1}
              style={{ marginTop: 5 }}
            >
              <Grid item>
                <Button
                  style={{ marginRight: 5 }}
                  variant="outlined"
                  onClick={() => setShowPageNameChangeModal(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <h2 id="simple-modal-title">Purchase a custom domain</h2>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" id="simple-modal-description">
                Make an impression and choose a more memorable link by purchasing
                your own domain.
              </Typography>
            </Grid>
            <Grid
              container
              justifyContent="flex-end"
              spacing={1}
              style={{ marginTop: 5 }}
            >
              <Grid item>
                <Button
                  style={{ marginRight: 5 }}
                  variant="outlined"
                  onClick={() => history.push("/domains/owned")}
                >
                  Manage Domains
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={() => history.push("/domains/search")}
                >
                  Search Domains
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Modal>
    </>
  );
}
