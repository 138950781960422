import React, { useEffect } from "react";
import {
  Link,
  Grid,
  TextField,
  Button,
  CssBaseline,
  Container,
  CircularProgress,
  Divider,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { EmailOutlined, Visibility, VisibilityOff } from "@mui/icons-material";
import axios from "axios";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { add, isFuture } from "date-fns";
import { useSnackbar } from "notistack";
import ihelpLogo from "../../assets/ihelp_logo.svg";
import GoogleButton, { MicrosoftButton } from "../../components/OauthButtons";
import useStore from "../../store";
const baseUrl =
  process.env.REACT_APP_BASE_URL || process.env.REACT_APP_DEV_ENDPOINT;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
function Login() {
  const history = useHistory();
  const query = useQuery();
  const { enqueueSnackbar } = useSnackbar();
  const setUser = useStore((state) => state.setUser);
  const loginWithGoogle = useStore((state) => state.loginWithGoogle);
  const loginWithMicrosoft = useStore((state) => state.loginWithMicrosoft);
  const loginWithLinkedin = useStore((state) => state.loginWithLinkedin);
  const runAutoCreate = useStore((state) => state.runAutoCreate);
  const isPremiumUser = useStore((state) => state.isPremiumUser);
  const hasGoogleCalendarScope = useStore(
    (state) => state.hasGoogleCalendarScope
  );
  let [loginForm, setLoginForm] = React.useState({});
  const [loginError, setLoginError] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [oauthLoading, setOauthLoading] = React.useState(true);
  const [showForm, setShowForm] = React.useState(false);
  const { strategy } = useParams();
  const classes = useStyles();
  let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  useEffect(() => {
    document.title = "Log In";
    let code = query.get("code");
    if (strategy === "linkedin" && code) {
      handleLinkedinCode(code);
    }

    if (strategy === "google" && code) {
      handleGoogleCode(code);
    }

    if (strategy === "microsoft" && code) {
      handleMicrosoftCode(code);
    }

    if (!code) {
      setOauthLoading(false);
    }

    let authenticatedAt = localStorage.getItem("authenticatedAt");
    let token = localStorage.getItem("token");
    if (token && authenticatedAt) {
      let tokenIsValid = isFuture(
        add(new Date(authenticatedAt), { days: 1, minutes: 10 })
      );
      if (tokenIsValid && query.get("autoLogin") !== "disabled") {
        history.push("/p/edit");
      }
    }
  }, []);

  async function handleRedirect(path) {
    history.push(query.get("redirectURL") || path || "/p/edit");
  }

  let submitLoginForm = async (e, { email, password }) => {
    e.preventDefault();
    setLoading(true);
    try {
      let loginRequest = await axios({
        method: "post",
        url: `${baseUrl}/v1/users/login`,
        data: {
          email,
          password,
          defaultTimeZone: timeZone,
        },
      });
      setLoading(false);
      handleLoginResponse(loginRequest.data.user, loginRequest.data.token);
      handleRedirect();
    } catch (err) {
      setLoading(false);
      console.log(err?.response?.data?.error?.message, err?.response?.status);
      setLoginError(
        err?.response?.data?.error?.message || "Invalid username or password."
      );
    }
  };

  async function handleMicrosoftCode(code) {
    try {
      setOauthLoading(true);
      let request = await loginWithMicrosoft(
        code,
        window.location.origin + window.location.pathname,
        timeZone
      );
      handleLoginResponse(request.data.user, request.data.token);
      setOauthLoading(false);
      if (
        !request.data.user.defaultItemsCreated &&
        new Date(request.data.user.createdAt).getTime() > 1631291081485
      ) {
        await runAutoCreate("microsoft");
      }
      handleRedirect();
    } catch (err) {
      setOauthLoading(false);
      console.log(err?.response?.data?.error?.message, err?.response?.status);
      enqueueSnackbar(
        err?.response?.data?.error?.message ||
          "Error logging in with Microsoft.",
        { variant: "error" }
      );
      setLoginError(
        err?.response?.data?.error?.message || "Invalid username or password."
      );
    }
  }

  async function handleLinkedinCode(code) {
    try {
      setLoading(true);
      let request = await loginWithLinkedin(
        code,
        window.location.origin + window.location.pathname,
        timeZone
      );
      setLoading(false);
      handleLoginResponse(request.data.user, request.data.token);
      handleRedirect();
    } catch (err) {
      setLoading(false);
      console.log(err?.response?.data?.error?.message, err?.response?.status);
      setLoginError(
        err?.response?.data?.error?.message || "Error loggin in with Linkedin"
      );
    }
  }

  async function handleGoogleCode(code) {
    try {
      setOauthLoading(true);
      let request = await loginWithGoogle(
        code,
        window.location.origin + window.location.pathname,
        timeZone
      );
      localStorage.setItem("hasLoggedInWithGoogle", true);
      setUser(request.data.user);
      setOauthLoading(false);
      handleLoginResponse(request.data.user, request.data.token);
      if (
        !request.data.user.defaultItemsCreated &&
        new Date(request.data.user.createdAt).getTime() > 1631291081485 &&
        hasGoogleCalendarScope(request.data.user) // Don't auto create for users created before Sep 10th
      ) {
        await runAutoCreate("google");
      }
      handleRedirect();
    } catch (err) {
      setOauthLoading(false);
      console.log(err?.response?.data?.error?.message, err?.response?.status);
      enqueueSnackbar(
        err?.response?.data?.error?.message || "Error logging in with Google.",
        { variant: "error" }
      );
      setLoginError(
        err?.response?.data?.error?.message || "Invalid username or password."
      );
    }
  }

  function handleLoginResponse(user, token) {
    setUser(user);
    localStorage.setItem("token", token);
    localStorage.setItem("pageId", user.defaultPage);
    localStorage.setItem("authenticatedAt", new Date());
    window.Intercom("boot", {
      app_id: "gpn6c78o",
      name: user.firstName + " " + user.lastName, // Full name
      email: user.email, // Email address
      created_at: user.createdAt, // Signup date as a Unix timestamp
      last_seen: Math.floor(Date.now() / 1000),
    });
    window.Upscope("updateConnection", {
      // Set the user ID below. If you don't have one, set to undefined.
      uniqueId: user.email,
      // Set the user name or email below (e.g. ["John Smith", "john.smith@acme.com"]).
      identities: [user.firstName + " " + user.lastName],
    });
    if (!isPremiumUser(user)) {
      history.push("/billing");
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      {!oauthLoading && (
        <div className={classes.paper}>
          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              style={{ textAlign: "center", marginTop: "20%" }}
            >
              <img src={ihelpLogo} alt="ihelplogo" />
            </Grid>
            <Grid item xs={12}>
              <GoogleButton
                variant="login"
                buttonText="Log in with Google"
                buttonStyles={{
                  color: "#fff",
                  marginBottom: 20,
                  fontWeight: 1000,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <MicrosoftButton
                variant="signup"
                buttonText="Log in with Microsoft"
                redirectURL={`${window.location.origin}/login/microsoft`}
                buttonStyles={{
                  color: "#fff",
                  marginBottom: 20,
                  fontWeight: 1000,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider style={{ marginTop: 12, marginBottom: 12 }} />
            </Grid>
          </Grid>
          {showForm ? (
            <form
              className={classes.form}
              onSubmit={(e) => submitLoginForm(e, loginForm)}
            >
              <TextField
                variant="outlined"
                margin="normal"
                required
                error={loginError}
                helperText={loginError}
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                disabled={loading}
                onChange={(e) =>
                  setLoginForm({
                    ...loginForm,
                    email: e.target.value.toLowerCase().trim(),
                  })
                }
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                error={loginError}
                helperText={loginError}
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                disabled={loading}
                onChange={(e) =>
                  setLoginForm({ ...loginForm, password: e.target.value })
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={loading}
                className={classes.submit}
              >
                {loading ? <CircularProgress /> : "Log In"}
              </Button>
            </form>
          ) : (
            <Button
              fullWidth
              variant="outlined"
              style={{ fontWeight: 500, marginTop: 10 }}
              onClick={() => setShowForm(true)}
            >
              <EmailOutlined style={{ marginRight: 10 }} />
              Log in with Email
            </Button>
          )}
          <Grid container style={{ marginTop: 15 }}>
            <Grid item xs>
              <Link href="/reset" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="/signup" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </div>
      )}
    </Container>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(10),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  brandIcon: {
    maxHeight: 25,
    marginRight: 10,
  },
  oauthButton: {
    color: "#fff",
    marginBottom: 20,
    fontWeight: 1000,
  },
}));

export default Login;
