import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import routes from "./routes";
import "./App.css";
import useStore from "./store";
import DefaultLoadingScreen from "./components/DefaultLoadingScreen";

let ProtectedRoute = ({ path, Component, location }) => {
  let user = useStore((state) => state.user);
  let page = useStore((state) => state.page);
  let refreshUser = useStore((state) => state.refreshUser);
  let fetchPage = useStore((state) => state.fetchPage);
  let refreshUserError = useStore((state) => state.refreshUserError);
  let hasToken = localStorage.getItem("token");
  let hasUser = user !== null && user !== undefined;
  if (!user && hasToken) {
    refreshUser();
  }
  if (user && !page) {
    fetchPage();
  }
  return hasUser && hasToken ? (
    <Route exact path={path}>
      <Component />
    </Route>
  ) : hasToken && !refreshUserError ? (
    <DefaultLoadingScreen /> // Show a loading spinner while fetching the user with their token.
  ) : (
    <Redirect
      to={{
        pathname: "/login",
        search: `?autoLogin=disabled&redirectURL=${location?.pathname}`,
      }}
    />
  );
};

function App(props) {
  window.Intercom("boot", {
    app_id: "gpn6c78o",
  });
  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/"
          render={() => (
            <div
              style={{
                display: "none", // This prevents the link from being redered on the page for a quick second
              }}
            >
              {window.location.host !== "localhost:3001"
                ? (window.location.href = "https://www.i.help")
                : (window.location.href = "http://localhost:3001/login")}
            </div>
          )}
        />
        {routes.map(({ path, Component, requiresAuth }) =>
          requiresAuth ? (
            <ProtectedRoute
              key={path}
              exact
              path={path}
              Component={Component}
              {...props}
            />
          ) : (
            <Route exact path={path}>
              {Component ? (
                <Component />
              ) : (
                <p>Placeholder, no component in routes file for {path}.</p>
              )}
            </Route>
          )
        )}
        <Route path="*"></Route>
      </Switch>
    </Router>
  );
}

export default App;
