import set from "date-fns/set";
let availability = {
  sunday: {
    from: 9 * 60,
    to: 17 * 60,
    open: false,
  },
  monday: {
    from: 9 * 60,
    to: 17 * 60,
    open: true,
  },
  tuesday: {
    from: 9 * 60,
    to: 17 * 60,
    open: true,
  },
  wednesday: {
    from: 9 * 60,
    to: 17 * 60,
    open: true,
  },
  thursday: {
    from: 9 * 60,
    to: 17 * 60,
    open: true,
  },
  friday: {
    from: 9 * 60,
    to: 17 * 60,
    open: true,
  },
  saturday: {
    from: 9 * 60,
    to: 17 * 60,
    open: false,
  },
};

export default availability;
