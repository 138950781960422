import { useEffect, useState } from "react";
import {
  Grid,
  Button,
  Typography,
  Card,
  CardContent,
  CardActions,
} from "@mui/material";
import SchedulerNavbar from "../../components/SchedulerNavbar";
import GoogleButton, { MicrosoftButton } from "../../components/OauthButtons";
import useStore from "../../store";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  disconnectButton: {
    color: "red",
  },
}));

const Connections = () => {
  const classes = useStyles();
  let [googleProfile, setGoogleProfile] = useState();
  let user = useStore((state) => state.user);
  let fetchGoogleProfile = useStore((state) => state.fetchGoogleProfile);
  let removeGoogleConnection = useStore(
    (state) => state.removeGoogleConnection
  );
  const removeMicrosoftConnection = useStore(
    (state) => state.removeMicrosoftConnection
  );
  let hasGoogleCalendarScope = useStore(
    (state) => state.hasGoogleCalendarScope
  );
  let refreshUser = useStore((state) => state.refreshUser);

  let handleGoogleProfile = async () => {
    let { data } = await fetchGoogleProfile();
    setGoogleProfile(data);
  };

  useEffect(() => {
    if (user.hasGoogleConnection) {
      handleGoogleProfile();
    }
  }, []);
  async function handleGoogleDisconnect() {
    if (
      window.confirm(
        `If you login with Google, please set your password under Profile so you can login with email/password.
        
This will prevent us from accessing any of your Google Services (Calendar, Meet URLs, Google User Info) until you reconnect to Google.`
      )
    ) {
      await removeGoogleConnection();
      refreshUser();
    }
  }

  async function handleMicrosoftDisconnect() {
    if (
      window.confirm(
        `If you login with Microsoft, please set your password under Profile so you can login with email/password.
        
This will prevent us from accessing any of your Microsoft Services (Calendar, Meet URLs) until you reconnect to Microsoft.`
      )
    ) {
      await removeMicrosoftConnection();
      refreshUser();
    }
  }
  return (
    <SchedulerNavbar hideAddNewMeeting={true} title="Connections">
      <div style={{ padding: 15 }}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={8}>
            <Card>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Google
                </Typography>
                <Typography variant="body1" component="p" color="textSecondary">
                  {googleProfile?.email}
                </Typography>
                <Typography variant="body1" component="p">
                  This is your connection to Google. i.help uses this for
                  scheduling events and to create Google Meet urls for meetings.
                </Typography>
                <br />
                {user.googleId && !hasGoogleCalendarScope(user) && (
                  <Typography variant="body1" component="p">
                    It looks like you logged in with Google but never gave
                    i.help permission to access your calendar. You can click the
                    "Connect to Google" button and check the calendar permission
                    boxes on the Google authorization page to get access to more
                    features!
                  </Typography>
                )}
              </CardContent>

              <CardActions>
                <GoogleButton
                  buttonStyles={{
                    maxWidth: 250,
                  }}
                  disabled={hasGoogleCalendarScope(user)}
                />
                {user?.hasGoogleConnection && (
                  <Button
                    size="small"
                    onClick={handleGoogleDisconnect}
                    className={classes.disconnectButton}
                  >
                    Disconnect
                  </Button>
                )}
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} md={8}>
            <Card>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Microsoft
                </Typography>

                <Typography variant="body1" component="p">
                  This is your connection to Microsoft. i.help uses this for
                  scheduling events and to create urls from Microsoft Teams for
                  meetings.
                </Typography>
                <br />
              </CardContent>

              <CardActions>
                <MicrosoftButton
                  buttonStyles={{
                    maxWidth: 250,
                  }}
                  disabled={user?.hasMicrosoftConnection}
                />
                {user?.hasMicrosoftConnection && (
                  <Button
                    size="small"
                    onClick={handleMicrosoftDisconnect}
                    className={classes.disconnectButton}
                  >
                    Disconnect
                  </Button>
                )}
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </div>
    </SchedulerNavbar>
  );
};

export default Connections;
