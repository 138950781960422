import React, { useState } from "react";
import {
  Container,
  Button,
  TextField,
  Typography,
  Grid,
  LinearProgress,
} from "@mui/material";
import axios from "axios";
import { useSnackbar } from "notistack";
import useStore from "../../../store";
import StepLabel from "../../../components/StepLabel";
import MoveElement from "../../../components/MoveElement";

let DownloadFile = ({ cancelForm, page, onSubmit, onRemove }) => {
  const createElement = useStore((state) => state.createElement);
  const fetchPage = useStore((state) => state.fetchPage);
  const fetchPresignedUrl = useStore((state) => state.fetchPresignedUrl);
  const elementToUpdate = useStore((state) => state.elementToUpdate);

  let [localForm, setLocalForm] = useState({
    elementType: "DownloadFile",
    defaultPosition: "dropZone1",
    value: elementToUpdate ? elementToUpdate.value : "",
    label: elementToUpdate ? elementToUpdate.label : "Download File",
  });
  const { enqueueSnackbar } = useSnackbar();
  let [fileName, setFileName] = useState("");
  let [progress, setProgress] = useState(0);
  const handleImageUpload = async (e) => {
    e.preventDefault();

    let file = document.querySelector("#file").files[0];
    let uploadedName = document.querySelector("#file").value.split("\\")[2];
    let name = document
      .querySelector("#file")
      .value.split("\\")[2]
      .toLowerCase()
      .replace(/\W/g, "");
    let urlRequest = await fetchPresignedUrl(name, "downloadfile");
    let uploadURL = urlRequest.data.uploadURL;
    let newElementId = urlRequest.data.elementId;
    setFileName(uploadedName);

    if (file) {
      await axios({
        method: "put",
        url: uploadURL,
        headers: {
          "Content-Type": "image/*",
        },
        data: file,
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          setProgress((loaded / total) * 100);
        },
      });
      enqueueSnackbar(`${uploadedName} has been uploaded successfully.`, {
        variant: "success",
      });
      // fetchPage();
      // cancelForm();
      setLocalForm({
        ...localForm,
        ...(!elementToUpdate && { id: newElementId }),
        filename: name,
        value: `https://ihelpimages.s3.amazonaws.com/${newElementId}_${name}?key=${new Date().getTime()}`,
      });
    }
  };

  return (
    <Container style={{ paddingLeft: 8, paddingRight: 8 }}>
      <form onSubmit={(e) => onSubmit(e, localForm)}>
        <Grid container spacing={1}>
          <Typography variant="subtitle2">Upload File</Typography>
          <Grid item xs={12}>
            <StepLabel step="Step 1:" subtitle="Select your file:" />

            <Button variant="contained" color="primary" component="label">
              Choose File...
              <input
                id="file"
                type="file"
                //accept="image/*"
                hidden
                onChange={handleImageUpload}
              />
            </Button>
            <div style={{ marginTop: 10, fontWeight: 600 }}>
              {fileName || elementToUpdate?.filename}
            </div>
            {progress > 0 && (
              <LinearProgress variant="determinate" value={progress} />
            )}
          </Grid>

          <Grid item xs={12}>
            <StepLabel
              step="Step 2:"
              subtitle="What would you like the button to say?"
            />
            <TextField
              variant="outlined"
              fullWidth
              value={localForm.label}
              onChange={(e) =>
                setLocalForm({ ...localForm, label: e.target.value })
              }
            />
          </Grid>

          <Grid item xs={6}>
            <Button variant="outlined" fullWidth onClick={cancelForm}>
              CANCEL
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" color="primary" type="submit" fullWidth>
              SAVE
            </Button>
          </Grid>
          {elementToUpdate && (
            <Container>
              <Button
                onClick={onRemove}
                fullWidth
                style={{ color: "red" }}
                variant="text"
              >
                REMOVE BUTTON
              </Button>
              <MoveElement element={elementToUpdate} />
            </Container>
          )}
        </Grid>
      </form>
    </Container>
  );
};

export default DownloadFile;
