import { useState, useEffect } from "react";
import {
  Container,
  Paper,
  Grid,
  Typography,
  TextField,
  Button,
  InputAdornment,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";
import { DeleteOutline, Edit, Link } from "@mui/icons-material";
import { getDay, startOfWeek, parse, format, startOfDay } from "date-fns";
import enUS from "date-fns/locale/en-US";
import { useParams, useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { CopyToClipboard } from "react-copy-to-clipboard";
import SchedulerNavbar from "../../components/SchedulerNavbar";
import useStore from "../../store";
import { useInterval } from "../../util";
import CalendarInviteModal from "../../components/CalendarInviteModal";
const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    minHeight: 200,
    padding: 20,
    marginBottom: 30,
  },
}));

const VotingSlot = ({ slot, event, onClick, selectedEvent }) => (
  <Grid
    style={{
      border:
        selectedEvent?._id === slot._id
          ? "3px solid #1e79d6"
          : "1px solid #1e79d6",

      "&:hover": {
        border: "3px solid #1e79d6 !important",
      },
      padding: 10,
    }}
    container
    justifyContent="space-between"
    item
    xs={6}
    //spacing={}
    onClick={(e) => onClick(e, slot)}
  >
    <Grid item>
      <Typography variant="subtitle2" style={{ color: "#1e79d6" }}>
        {format(new Date(slot.start), "p")} - {format(new Date(slot.end), "p")}
      </Typography>
    </Grid>

    <Grid item>
      <Typography variant="subtitle2">
        {slot.votes > 1 || slot.votes === 0
          ? `${slot.votes} votes `
          : `${slot.votes} vote`}
      </Typography>
    </Grid>
    <Grid item xs={12}>
      {slot.calendarEventUrl && (
        <Typography variant="subtitle2">
          <a rel="noreferrer" target="_blank" href={slot.calendarEventUrl}>
            View Calendar Event
          </a>
        </Typography>
      )}
    </Grid>
    <Grid item xs={12}>
      <Typography variant="caption">
        {slot.voters.map((v) => (
          <>
            {" "}
            {`${v.name} (${v.email})`}
            <br />
          </>
        ))}
      </Typography>
    </Grid>
  </Grid>
);

const FindAvailabilityResults = () => {
  const classes = useStyles();
  const history = useHistory();
  let user = useStore((state) => state.user);
  let page = useStore((state) => state.page);
  let [event, setEvent] = useState({ slots: [] });
  let [selectedEvent, setSelectedEvent] = useState(null);
  let { enqueueSnackbar } = useSnackbar();
  let { id } = useParams();
  let fetchAvailabilityEvent = useStore(
    (state) => state.fetchAvailabilityEvent
  );
  let createSlotInvite = useStore((state) => state.createSlotInvite);
  const setShowCalendarInviteModal = useStore(
    (state) => state.setShowCalendarInviteModal
  );
  const hasNoCalendarIntegration = useStore(
    (state) => state.hasNoCalendarIntegration
  );
  const setCalendarInvite = useStore((state) => state.setCalendarInvite);
  const handleSelectEvent = (e, id) => {
    setSelectedEvent(id);
  };
  const handleFetchAvailabilityEvent = async () => {
    let { data: availabilityEvent } = await fetchAvailabilityEvent(id);
    availabilityEvent.sortedSlots = groupBy(
      availabilityEvent.slots.filter((s) => !s.archived),
      (slot) => {
        return startOfDay(new Date(slot.start));
      }
    );
    setEvent(availabilityEvent);
  };

  const handleCreateInvite = async () => {
    //if (hasNoCalendarIntegration(user)) {
    console.log(selectedEvent);
    let attendees = [];
    for (const slot of event.slots) {
      let voterEmails = slot.voters.map((v) => v.email);
      attendees.push(...voterEmails);
    }
    let guests = [...new Set(attendees)];
    setCalendarInvite({
      title: event.name,
      description: `To join meeting, go to i.help/${page?.name}`,
      start: new Date(selectedEvent.start),
      end: new Date(selectedEvent.end),
      guests,
    });
    // If they haven't connected a calendar yet, show invite link modal
    setShowCalendarInviteModal(true);
    // } else {
    // await createSlotInvite(id, selectedEvent._id);
    // handleFetchAvailabilityEvent();
    // setSelectedEvent(null);
    // enqueueSnackbar("You calendar invite was created.", {
    //   variant: "success",
    // });
    // }
  };
  // Poll so we can see votes come in
  useInterval(() => {
    handleFetchAvailabilityEvent();
  }, 5000);
  useEffect(() => {
    handleFetchAvailabilityEvent();
  }, []);
  return (
    <SchedulerNavbar
      hideAddNewMeeting={true}
      buttonURL={`/m/${user.defaultPage}`}
      title="Request Availability"
    >
      <Container maxWidth="md">
        <Paper className={classes.paper}>
          <Grid
            container
            justifyContent="center"
            spacing={2}
            style={{ width: "100%" }}
          >
            <Grid item container justifyContent="center" xs={12}>
              <Typography variant="h4">Invite Participants</Typography>
            </Grid>
            <Grid item container justifyContent="center" xs={12}>
              <Typography variant="subtitle2">
                Copy and send the link below via email - no account is required
                to response.{" "}
              </Typography>
            </Grid>
            <Grid item container justifyContent="center" xs={12}>
              <CopyToClipboard
                text={
                  process.env.NODE_ENV === "development"
                    ? `http://localhost:3001/event/${id}`
                    : `https://i.help/event/${id}`
                }
                onCopy={(text, result) =>
                  enqueueSnackbar("URL copied to clipboard.", {
                    variant: "success",
                  })
                }
              >
                <Button
                  style={{ minWidth: 90, width: "60%" }}
                  variant="outlined"
                  color="primary"
                  fullWidth
                >
                  <Link />
                  {process.env.NODE_ENV === "development"
                    ? `http://localhost:3001/event/${id}`
                    : `https://i.help/event/${id}`}
                </Button>
              </CopyToClipboard>
            </Grid>
            <Grid
              style={{ marginTop: 60 }}
              item
              container
              justifyContent="center"
              xs={12}
            >
              <Typography variant="h4">Responses</Typography>
            </Grid>
            <Grid item container justifyContent="center" xs={12}>
              <Typography variant="body1" style={{ maxWidth: 600 }}>
                Below are the times participants are available. When you're
                ready, select a time and click "Create Invite" create an
                invitation to these participants at the selected time. Need to
                change the time slots? You can edit them here:{" "}
              </Typography>
            </Grid>
            <Grid item container justifyContent="center" xs={12}>
              <Button
                size="small"
                variant="outlined"
                onClick={() => history.push(`/p/find-availability/edit/${id}`)}
              >
                Edit Selectable Times
              </Button>
            </Grid>
            <Grid item container justifyContent="center" xs={12}>
              <Typography variant="body2">
                {Intl.DateTimeFormat().resolvedOptions().timeZone}
              </Typography>
            </Grid>

            <Grid container justifyContent="center" item xs={12}>
              {event.sortedSlots &&
                Object.keys(event.sortedSlots)
                  .sort((a, b) => Date.parse(a) - Date.parse(b)) // sort by day
                  ?.map((sortedSlot, index) => (
                    <>
                      <Grid item container justifyContent="center" xs={12}>
                        <Typography
                          variant="h6"
                          key={sortedSlot}
                          style={{ marginBottom: -8 }}
                        >
                          {format(new Date(sortedSlot), "EEEE, MMMM d")}
                        </Typography>
                      </Grid>

                      {sortBy(
                        event.sortedSlots[sortedSlot], // sort by time on a day
                        (o) => o.startSeconds
                      ).map((s) => (
                        <Grid
                          item
                          container
                          justifyContent="center"
                          key={s._id}
                          xs={12}
                          style={{ padding: 10 }}
                        >
                          <VotingSlot
                            slot={s}
                            event={event}
                            onClick={handleSelectEvent}
                            selectedEvent={selectedEvent}
                          />
                        </Grid>
                      ))}
                    </>
                  ))}
            </Grid>
            <Grid container justifyContent="center" item xs={12}>
              <Button
                color="primary"
                variant="contained"
                //onClick={handleCreateInvite}
                onClick={handleCreateInvite}
                disabled={!selectedEvent || selectedEvent.inviteSent}
              >
                Create Invite
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Container>
      <CalendarInviteModal eventId={id} selectedEvent={selectedEvent?._id} />
    </SchedulerNavbar>
  );
};

export default FindAvailabilityResults;
