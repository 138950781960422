import React from "react";
import {
  Typography,
  TextField,
  Grid,
  Button,
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Icon,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  ArrowBack,
  LinkedIn,
  Twitter,
  Facebook,
  Instagram,
} from "@mui/icons-material";
import TikTok from "../../../assets/tiktok.svg";
import useStore from "../../../store";
import StepLabel from "../../../components/StepLabel";

const useStyles = makeStyles((theme) => ({
  listItemIcon: {
    minWidth: 38,
  },
}));
const TikTokIcon = () => (
  <Icon style={{ minHeight: 30 }}>
    <img alt="tiktok img" src={TikTok} />
  </Icon>
);

const SocialMedia = ({ onSubmit, cancelForm, onRemove }) => {
  let classes = useStyles();
  const elementToUpdate = useStore((state) => state.elementToUpdate);
  const [showFacebook, setShowFacebook] = React.useState(
    !!elementToUpdate?.facebook
  );
  const [showTwitter, setShowTwitter] = React.useState(
    !!elementToUpdate?.twitter
  );
  const [showLinkedin, setShowLinkedin] = React.useState(
    !!elementToUpdate?.linkedin
  );
  const [showInstagram, setShowInstagram] = React.useState(
    !!elementToUpdate?.instagram
  );
  const [showTikTok, setShowTikTok] = React.useState(!!elementToUpdate?.tiktok);
  let [localForm, setLocalForm] = React.useState({
    elementType: "SocialMedia",
    defaultPosition: "dropZone2",
    facebook: elementToUpdate ? elementToUpdate.facebook : "",
    twitter: elementToUpdate ? elementToUpdate.twitter : "",
    linkedin: elementToUpdate ? elementToUpdate.linkedin : "",
    instagram: elementToUpdate ? elementToUpdate.instagram : "",
    tiktok: elementToUpdate ? elementToUpdate.tiktok : "",
  });

  let socialButtons = [
    {
      label: "Facebook",
      PassedIcon: Facebook,
      color: "#1a77f2",
      onClick: () => {
        setShowFacebook(true);
        setLocalForm({ ...localForm, value: localForm.facebook });
      },
    },
    {
      label: "Twitter",
      PassedIcon: Twitter,
      color: "#1da1f2",
      onClick: () => {
        setShowTwitter(true);
        setLocalForm({ ...localForm, value: localForm.twitter });
      },
    },
    {
      label: "Linkedin",
      PassedIcon: LinkedIn,
      color: "#0c66c2",
      onClick: () => {
        setShowLinkedin(true);
        setLocalForm({ ...localForm, value: localForm.linkedin });
      },
    },
    {
      label: "Instagram",
      PassedIcon: Instagram,
      color: "#e54666",
      onClick: () => {
        setShowInstagram(true);
        setLocalForm({ ...localForm, value: localForm.instagram });
      },
    },
    {
      label: "Tik Tok",
      PassedIcon: TikTokIcon,
      color: "#e54666",
      onClick: () => {
        setShowTikTok(true);
        setLocalForm({ ...localForm, value: localForm.tiktok });
      },
    },
  ];
  let typeIsSelected =
    showTwitter || showFacebook || showLinkedin || showInstagram || showTikTok;
  let handleBack = () => {
    setShowTwitter(false);
    setShowFacebook(false);
    setShowLinkedin(false);
    setShowInstagram(false);
    setShowTikTok(false);
  };
  return (
    <Container style={{ paddingLeft: 8, paddingRight: 8 }}>
      <form style={{ width: "100%" }} onSubmit={(e) => onSubmit(e, localForm)}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              {elementToUpdate ? "Update" : "Create"} Social Media
            </Typography>
          </Grid>
          {!typeIsSelected && (
            <Grid xs={12}>
              <List>
                {socialButtons.map(({ label, PassedIcon, onClick, color }) => (
                  <ListItem
                    button
                    key={label}
                    style={{ marginTop: -2, marginBottom: -2 }}
                    onClick={onClick}
                  >
                    <ListItemIcon className={classes.listItemIcon}>
                      <PassedIcon style={{ color }} />
                    </ListItemIcon>
                    <ListItemText primary={label} />
                  </ListItem>
                ))}
              </List>
            </Grid>
          )}
          {typeIsSelected && (
            <Grid xs={12}>
              <Typography variant={"subtitle"}>
                <IconButton color="primary" onClick={handleBack}>
                  <ArrowBack />
                </IconButton>
                Pick a different Social Media
              </Typography>
            </Grid>
          )}
          {typeIsSelected && (
            <Grid item xs={12}>
              <StepLabel
                step="Step 1:"
                subtitle={`Enter your ${
                  showTwitter
                    ? "Twitter"
                    : showFacebook
                    ? "Facebook"
                    : showLinkedin
                    ? "Linkedin"
                    : showInstagram
                    ? "Instagram"
                    : "Tik Tok"
                } URL`}
              />
            </Grid>
          )}

          {showTwitter && (
            <Grid item xs={12}>
              <TextField
                type="text"
                InputLabelProps={{ shrink: true }}
                label="Twitter"
                fullWidth
                required
                variant="outlined"
                placeholder="https://twitter.com/johndoe"
                value={localForm.twitter}
                onChange={(e) =>
                  setLocalForm({ ...localForm, twitter: e.target.value })
                }
              />
            </Grid>
          )}
          {showFacebook && (
            <Grid item xs={12}>
              <TextField
                type="text"
                InputLabelProps={{ shrink: true }}
                label="Facebook"
                variant="outlined"
                fullWidth
                required
                placeholder="https://facebook.com/johndoe"
                value={localForm.facebook}
                onChange={(e) =>
                  setLocalForm({ ...localForm, facebook: e.target.value })
                }
              />
            </Grid>
          )}
          {showLinkedin && (
            <Grid item xs={12}>
              <TextField
                type="text"
                InputLabelProps={{ shrink: true }}
                label="LinkedIn"
                fullWidth
                required
                variant="outlined"
                placeholder="https://linkedin.com/johndoe"
                value={localForm.linkedin}
                onChange={(e) =>
                  setLocalForm({ ...localForm, linkedin: e.target.value })
                }
              />
            </Grid>
          )}
          {showInstagram && (
            <Grid item xs={12}>
              <TextField
                type="text"
                InputLabelProps={{ shrink: true }}
                label="Instagram"
                fullWidth
                required
                variant="outlined"
                placeholder="https://www.instagram.com/johndoe"
                value={localForm.instagram}
                onChange={(e) =>
                  setLocalForm({ ...localForm, instagram: e.target.value })
                }
              />
            </Grid>
          )}
          {showTikTok && (
            <Grid item xs={12}>
              <TextField
                type="text"
                InputLabelProps={{ shrink: true }}
                label="Tik Tok"
                fullWidth
                required
                variant="outlined"
                placeholder="https://www.tiktok.com/johndoe"
                value={localForm.tiktok}
                onChange={(e) =>
                  setLocalForm({ ...localForm, tiktok: e.target.value })
                }
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <Button variant="outlined" fullWidth onClick={cancelForm}>
              CANCEL
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" color="primary" type="submit" fullWidth>
              SAVE
            </Button>
          </Grid>
          {elementToUpdate && (
            <Container>
              <Button
                onClick={onRemove}
                fullWidth
                style={{ color: "red" }}
                variant="text"
              >
                REMOVE BUTTON
              </Button>
            </Container>
          )}
        </Grid>
      </form>
    </Container>
  );
};

export default SocialMedia;
