import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { Typography, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import { useLocation, useParams } from "react-router-dom";
import GoogleButton from "../../components/OauthButtons";
import googleCalendarAuth from "../../assets/google-scopes.png";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(30),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(5),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  socialAuthButton: {
    color: "#5d7594",
    borderColor: "#5d7594",
  },
  brandIcon: {
    maxHeight: 25,
    marginRight: 20,
  },
  oauthButton: {
    color: "#fff",
    marginBottom: 20,
    fontWeight: 1000,
  },
}));

export default function SignUp(props) {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const classes = useStyles();
  const { planName } = useParams();
  const query = useQuery();

  const plan = planName || query.get("plan");

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Grid container spacing={1}>
          {/* <Grid item xs={12} style={{ textAlign: "center", marginTop: "20%" }}>
            <img src={ihelpLogo} alt="ihelplogo" />
          </Grid> */}
          <Grid item xs={12}>
            <Typography
              variant="h6"
              style={{ textAlign: "center", marginBottom: 5 }}
            >
              For the best experience, please check the boxes on the next page.
            </Typography>
            <img
              style={{ maxWidth: 400 }}
              src={googleCalendarAuth}
              alt="googlecalendarscope"
            />
            <GoogleButton
              redirectURL={`${window.location.origin}/signup/google${
                plan ? "/" + plan : ""
              }`}
              requireConsentPrompt
              buttonText="Continue"
              variant="signup"
              buttonStyles={{
                color: "#fff",
                marginBottom: 20,
                fontWeight: 1000,
              }}
              hideIcon
            />
          </Grid>
        </Grid>

        {/* <Link href="/signup" variant="body2" style={{ marginTop: 15 }}>
          {"Already have an account? Log In"}
        </Link> */}
      </div>
    </Container>
  );
}
