import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { getPlanByName } from "../Signup/plans";
import useStore from "../../store";

const Billing = () => {
  let user = useStore((state) => state.user);
  const history = useHistory();
  let createStripePortalSession = useStore(
    (state) => state.createStripePortalSession
  );
  let createStripeCheckoutSession = useStore(
    (state) => state.createStripeCheckoutSession
  );
  async function handlePortal() {
    let portalSessionRequest = await createStripePortalSession();
    let { url } = portalSessionRequest.data;
    window.location = url;
  }
  async function handleCheckout() {
    let plan = getPlanByName("pro");
    let checkoutSessionRequest = await createStripeCheckoutSession({
      price: plan.id,
      // Only add a trial if they don't have an existing subscription ID.
      ...(user.org.stripe.subscription
        ? {}
        : { trial_period_days: plan.trial_period_days }),
    });
    let { session } = checkoutSessionRequest.data;
    window.location = session.url;
  }

  useEffect(() => {
    if (user?.org?.stripe?.status && user?.org?.stripe?.status !== "canceled") {
      handlePortal();
    } else {
      handleCheckout(user?.org?.stripe);
    }
  });
  return <div></div>;
};

export default Billing;
