import googleLogo from "../assets/googleLogo.png";
import googleIcon from "../assets/googleIcon.png";
import microsoftLogo from "../assets/ms-logo.svg";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  brandIcon: {
    maxHeight: 25,
    marginRight: 10,
  },
}));

export const MicrosoftButton = ({
  redirectURL = `${window.location.origin}/login/microsoft`,
  hideIcon = false,
  variant = "normal",
  disabled = false,
  buttonStyles,
  buttonText = "Connect to Microsoft",
}) => {
  const classes = useStyles();
  let authURL =
    "https://login.microsoftonline.com/common/oauth2/v2.0/authorize";
  let scope = [
    "https://graph.microsoft.com/User.Read",
    "offline_access",
    "openid",
    "https://graph.microsoft.com/Calendars.ReadWrite",
    "https://graph.microsoft.com/OnlineMeetings.ReadWrite",
  ].join(" ");
  return (
    <Button
      variant={variant === "normal" ? "outlined" : "contained"}
      fullWidth
      disabled={disabled}
      color="primary"
      style={buttonStyles}
      href={`${authURL}?redirect_uri=${redirectURL}&client_id=${process.env.REACT_APP_MICROSOFT_CLIENT_ID}&scope=${scope}&response_mode=query&response_type=code&prompt=consent`}
    >
      {!hideIcon && (
        <img
          key="googlebutton"
          src={microsoftLogo}
          alt="Google Logo"
          className={classes.brandIcon}
        />
      )}
      {buttonText}
    </Button>
  );
};

const GoogleButton = ({
  buttonText = "Connect to Google",
  redirectURL = `${window.location.origin}/login/google`,
  disabled = false,
  variant = "normal",
  buttonStyles = {},
  hideIcon = false,
  requireConsentPrompt = false,
}) => {
  const classes = useStyles();
  return (
    <Button
      href={`https://accounts.google.com/o/oauth2/v2/auth?client_id=${
        process.env.REACT_APP_GOOGLE_CLIENT_ID
      }&response_type=code${
        requireConsentPrompt ? "&prompt=consent" : ""
      }&scope=${[
        "profile",
        "email",
        "https://www.googleapis.com/auth/calendar.events",
        "https://www.googleapis.com/auth/calendar.readonly",
      ].join(" ")}&access_type=offline&redirect_uri=${redirectURL}`}
      variant={variant === "normal" ? "outlined" : "contained"}
      fullWidth
      disabled={disabled}
      color="primary"
      style={buttonStyles}
    >
      {!hideIcon && (
        <img
          key="googlebutton"
          className={classes.brandIcon}
          src={variant === "normal" ? googleLogo : googleIcon}
          alt="Google Logo"
        />
      )}

      {disabled ? "Google is already Connected" : buttonText}
    </Button>
  );
};

export default GoogleButton;
