import SchedulerNavbar from "../../components/SchedulerNavbar";
import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  TextField,
  CircularProgress,
  Container,
  Button,
  Paper,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { useParams } from "react-router";
import useStore from "../../store";
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(2),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  formHeader: { marginBottom: theme.spacing(2) },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function Profile() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  let [form, setForm] = useState({});
  let [isLoading, setIsLoading] = useState(false);
  let retrieveUser = useStore((state) => state.retrieveUser);
  let updateUser = useStore((state) => state.updateUser);

  async function handleUserFetch() {
    let { data: user } = await retrieveUser(id);
    setForm({
      firstName: user.firstName,
      lastName: user.lastName,
      title: user.title,
      department: user.department,
      email: user.email,
    });
  }

  useEffect(() => {
    document.title = "Profile";
    handleUserFetch();
  }, []);

  let submitForm = async (e) => {
    try {
      setIsLoading(true);
      e.preventDefault();
      await updateUser({ ...form, id });
      enqueueSnackbar("Profile updated successfully.", {
        variant: "success",
      });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      enqueueSnackbar(
        e?.response?.data?.error?.message || "Error updating profile.",
        {
          variant: "error",
        }
      );
    }
  };
  return (
    <SchedulerNavbar
      hideAddNewMeeting={true}
      buttonText={"Back to Users"}
      buttonURL={"/p/team"}
    >
      <Container component="main" maxWidth="sm">
        <Paper className={classes.paper}>
          <Typography
            className={classes.formHeader}
            component="h1"
            variant="h5"
          >
            Manage Profile
          </Typography>
          <form className={classes.form} onSubmit={(e) => submitForm(e, form)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  autoComplete="fname"
                  name="firstName"
                  variant="outlined"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                  value={form.firstName}
                  disabled={isLoading}
                  onChange={(e) =>
                    setForm({ ...form, firstName: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="lname"
                  value={form.lastName}
                  disabled={isLoading}
                  onChange={(e) =>
                    setForm({ ...form, lastName: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  fullWidth
                  id="title"
                  label="Title"
                  name="title"
                  autoComplete="title"
                  value={form.title}
                  disabled={isLoading}
                  onChange={(e) => setForm({ ...form, title: e.target.value })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  fullWidth
                  id="department"
                  label="Department"
                  name="department"
                  disabled={isLoading}
                  value={form.department}
                  autoComplete="department"
                  onChange={(e) =>
                    setForm({
                      ...form,
                      department: e.target.value,
                    })
                  }
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isLoading}
              className={classes.submit}
            >
              {isLoading ? <CircularProgress /> : "Update Profile"}
            </Button>
          </form>
        </Paper>
      </Container>
    </SchedulerNavbar>
  );
}

export default Profile;
