import React, { useEffect } from "react";
import { Paper, Avatar, Grid, Typography, Button, Fab } from "@mui/material";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import useStore from "../../store";
import { useQuery } from "../../util";
import Element from "../../components/Element";
import logo from "../../assets/ihelp_logo.svg";
const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    minWidth: "100%",
    position: "fixed",
  },
  paper: {
    marginTop: "45%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: 200,
    maxWidth: 600,
    borderRadius: 20,
    textAlign: "center",
    padding: 20,
  },
  avatar: {
    marginTop: theme.spacing(-8),
    width: theme.spacing(15),
    height: theme.spacing(15),
    marginBottom: 10,
  },
  actionButton: {
    margin: 5,
  },
  inlineContent: {
    display: "inline-block",
    textAlign: "center",
  },
  image: {
    maxHeight: 75,
    margin: 5,
  },
  divider: {
    width: "60%",
    marginTop: 15,
    marginBottom: 15,
  },
  referralFab: {
    position: "absolute",
    right: 0,
    margin: 10,
  },
}));

const DeactivatedPage = (props) => {
  let history = useHistory();
  let query = useQuery();
  let mode = query.get("mode") === "draft" ? "draft" : "published";
  const classes = useStyles();
  const { page } = useStore((state) => ({
    fetchPage: state.fetchPage,
    page: state.page,
    isLoadingPage: state.isLoadingPage,
    fetchPageError: state.fetchPageError,
  }));
  useEffect(() => {
    //fetchPage(pageId, "name", mode);
  }, []);
  function handleReferralClick() {
    localStorage.setItem("referralPage", page._id);
    history.push("/signup");
  }
  return (
    <>
      <div
        style={{
          backgroundImage: `url("/img/bg_abstractStone.png")`,
          backgroundSize: "cover",
        }}
        className={classes.root}
      >
        <Fab
          onClick={handleReferralClick}
          variant="extended"
          className={classes.referralFab}
          style={{ whiteSpace: "nowrap", height: 30 }}
        >
          Like this page? Create yours for free at{"  "}
          <img style={{ height: 20, paddingLeft: 5 }} src={logo} alt="logo" />
        </Fab>

        <Grid justifyContent="center" alignContent="center" container>
          <Grid item xs={9} sm={6} md={6} lg={4} xl={4}>
            <Paper className={classes.paper}>
              <Avatar className={classes.avatar} src={page?.avatar} />
              <Typography variant="h4">No Users Found</Typography>
              <Typography style={{ paddingBottom: 5 }}>
                This page has been deactivated.
              </Typography>

              <div
                style={{ marginBottom: 14, marginTop: 11 }}
                className={classes.inlineContent}
              >
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.actionButton}
                  href="https://i.help/signup"
                  target="_blank"
                  rel="noopener"
                >
                  Create your free page on i.help
                </Button>
              </div>

              <div className={classes.inlineContent}>
                {page &&
                  page[mode]?.dropZone2?.elements.map((element) => (
                    <Element element={element}>
                      {element?.button?.label}
                    </Element>
                  ))}
              </div>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default DeactivatedPage;
