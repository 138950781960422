import Login from "./pages/Login";
import Logout from "./pages/Logout";
import Billing from "./pages/Billing";
import Dashboard from "./pages/Dashboard";
import CreateInvite from "./pages/CreateInvite";
import Connect from "./pages/Connect";
import Signup from "./pages/Signup";
import CheckScope from "./pages/Signup/CheckScope";
import Profile from "./pages/Profile";
import Manage from "./pages/Profile/Manage";
import Users from "./pages/Users";
import Admin from "./pages/Admin";
import Team from "./pages/Team";
import PublicTeam from "./pages/Team/PublicTeam";
import OrgSettings from "./pages/Team/Settings";
import HelpPageEdit from "./pages/HelpPageEdit";
import HelpPage from "./pages/HelpPage";
import PageRedirect from "./pages/HelpPage/PageRedirect";
import HelpPageScheduler from "./pages/HelpPage/schedule";
import Event from "./pages/HelpPage/Event";
import ManageUser from "./pages/Admin/ManageUser";
import SchedulerSettings from "./pages/SchedulerSettings";
import ManageEventType from "./pages/SchedulerSettings/ManageEventType";
import EventTypes from "./pages/SchedulerSettings/EventTypes";
import Reset from "./pages/Reset";
import AcceptInvite from "./pages/Invite";
import LoadingScreen from "./components/LoadingScreen";
import PasswordChange from "./pages/Reset/PasswordChange";
import Playground from "./pages/Playground";
import Connections from "./pages/Connections";
import DomainSearch from "./pages/Domains";
import OwnedDomains from "./pages/Domains/Owned";
import EmailSignature from "./pages/EmailSignature";
import FileUploads from "./pages/FileUploads";
import PublicSharing from "./pages/FileUploads/publicSharing";
import FindAvailability from "./pages/FindAvailability";
import FindAvailabilityResults from "./pages/FindAvailability/Results";
import FindAvailabilityEvents from "./pages/FindAvailability/Events";
import MeetingWithProvider from "./pages/Meetings";
import { Home, Person, People, ExitToApp } from "@mui/icons-material";

function NotFound() {
  return (
    <div>
      <h2>
        Whoops the page you are looking for doesn't seem to be at this url.
      </h2>
    </div>
  );
}

const routes = [
  {
    path: "/skeleton",
    Component: LoadingScreen,
    requiresAuth: false,
  },
  {
    path: "/login",
    Component: Login,
    requiresAuth: false,
  },
  {
    path: "/login/:strategy",
    Component: Login,
    requiresAuth: false,
  },
  {
    path: "/connect/:strategy",
    Component: Connect,
    requiresAuth: true,
  },
  {
    path: "/reset",
    Component: Reset,
    requiresAuth: false,
  },
  {
    path: "/reset/:token",
    Component: PasswordChange,
    requiresAuth: false,
  },
  {
    path: "/invite/:token",
    Component: AcceptInvite,
    requiresAuth: false,
  },
  {
    path: "/signup",
    Component: Signup,
    requiresAuth: false,
  },
  {
    path: "/signup/:strategy",
    Component: Signup,
    requiresAuth: false,
  },
  {
    path: "/google/scope",
    Component: CheckScope,
    requiresAuth: false,
  },
  {
    path: "/signup/:strategy/:planName",
    Component: Signup,
    requiresAuth: false,
  },
  {
    path: "/playground",
    Component: Playground,
    requiresAuth: false,
  },
  {
    path: "/admin/user/:id",
    Component: ManageUser,
    requiresAuth: true,
  },
  {
    path: "/p/:name/edit",
    Component: HelpPageEdit,
    Icon: Home,
    label: "My Page",
    requiresAuth: true,
  },
  {
    path: "/p/edit",
    Component: HelpPageEdit,
    Icon: Home,
    label: "My Page",
    requiresAuth: true,
  },
  {
    path: "/p/edit/scheduler",
    Component: SchedulerSettings,
    Icon: Home,
    label: "Scheduler Settings",
    requiresAuth: true,
  },
  {
    path: "/p/edit/eventtype",
    Component: ManageEventType,
    Icon: Home,
    label: "New Event Type",
    requiresAuth: true,
  },
  {
    path: "/p/edit/eventtype/:id",
    Component: ManageEventType,
    Icon: Home,
    label: "New Event Type",
    requiresAuth: true,
  },
  {
    path: "/p/dashboard",
    Component: Dashboard,
    requiresAuth: false,
  },
  {
    path: "/p/invite",
    Component: CreateInvite,
    requiresAuth: false,
  },
  {
    path: "/profile",
    Component: Profile,
    Icon: Person,
    label: "Account",
    isAccountIconRoute: true,
    requiresAuth: true,
  },
  {
    path: "/profile/:id",
    Component: Manage,
    Icon: Person,
    requiresAuth: true,
  },
  {
    path: "/p/team",
    Component: Team,
    Icon: Person,
    label: "Team",
    isAccountIconRoute: true,
    requiresAuth: true,
  },
  {
    path: "/hq/:teamName",
    Component: PublicTeam,
    isAccountIconRoute: false,
    requiresAuth: false,
  },
  {
    path: "/organization/settings",
    Component: OrgSettings,
    Icon: Person,
    label: "Team",
    isAccountIconRoute: false,
    requiresAuth: true,
  },
  {
    path: "/domains/search",
    Component: DomainSearch,
    label: "Domain Search",
    isAccountIconRoute: false,
    requiresAuth: true,
  },
  {
    path: "/domains/owned",
    Component: OwnedDomains,
    label: "Domain Search",
    isAccountIconRoute: false,
    requiresAuth: true,
  },
  {
    path: "/users",
    label: "Users",
    Icon: People,
    Component: Users,
    requiresAuth: true,
  },
  {
    label: "Billing",
    Icon: ExitToApp,
    isAccountIconRoute: true,
    Component: Billing,
    path: "/billing",
    requiresAuth: true,
  },
  {
    path: "/admin",
    isAccountIconRoute: false, //This would make it show for every user
    Component: Admin,
    label: "Admin",
    requiresAuth: true,
  },
  {
    path: "/p/edit/eventtypes",
    Component: EventTypes,
    Icon: Home,
    label: "Event Types",
    isAccountIconRoute: true,
    requiresAuth: true,
  },
  {
    path: "/p/email-signature",
    Component: EmailSignature,
    Icon: Home,
    label: "Email Signature",
    isEmailSiganture: true,
    isAccountIconRoute: true,
    requiresAuth: true,
  },
  {
    path: "/p/find-availability",
    Component: FindAvailability,
    Icon: Home,
    label: "Find Availability",
    requiresAuth: true,
  },
  {
    path: "/p/find-availability/edit/:id",
    Component: FindAvailability,
    Icon: Home,
    label: "Find Availability",
    requiresAuth: true,
  },
  {
    path: "/p/find-availability/result/:id",
    Component: FindAvailabilityResults,
    Icon: Home,
    label: "Find Availability",
    requiresAuth: true,
  },
  {
    path: "/p/find-availability/events",
    Component: FindAvailabilityEvents,
    Icon: Home,
    label: "Find Availability",
    requiresAuth: true,
  },
  {
    path: "/event/:id",
    Component: Event,
    Icon: Home,
    label: "Event Voting",
    requiresAuth: false,
  },
  {
    path: "/p/files",
    Component: FileUploads,
    Icon: Home,
    label: "Uploaded Files",
    // isAccountIconRoute: true,
    requiresAuth: true,
  },
  {
    path: "/p/public/files/:id",
    requiresAuth: false,
    Component: PublicSharing,
  },
  {
    path: "/connections",
    isAccountIconRoute: true, //This would make it show for every user
    Component: Connections,
    label: "Integrations",
    requiresAuth: true,
  },
  {
    label: "Admin",
    requiredRole: ["SuperAdmin"],
    isAccountIconRoute: true,
    Component: Admin,
    path: "/admin",
    requiresAuth: true,
  },
  {
    label: "Log Out",
    Icon: ExitToApp,
    isAccountIconRoute: true,
    Component: Logout,
    path: "/logout",
    requiresAuth: false,
  },
  {
    path: "/:pageId",
    Component: HelpPage,
    Icon: Home,
    label: "My Page",
    requiresAuth: false,
  },
  {
    path: "/m/:pageId",
    Component: PageRedirect,
    Icon: Home,
    label: "My Page",
    requiresAuth: false,
  },
  {
    path: "/:pageId/schedule/:eventTypeId",
    Component: HelpPageScheduler,
    Icon: Home,
    label: "My Page",
    requiresAuth: false,
  },
  {
    path: "/:pageId/meet",
    Component: MeetingWithProvider,
    Icon: Home,
    label: "Meetings",
    // isAccountIconRoute: true,
    //requiresAuth: true,
  },

  {
    path: "*",
    Component: NotFound,
    requiresAuth: false,
  },
];

export const primaryNavbarRoutes = routes.filter((r) => r.isPrimaryNavbarRoute);
export const secondaryNavbarRoutes = routes.filter(
  (r) => r.isSecondaryNavbarRoute
);
export const accountIconRoutes = routes.filter((r) => r.isAccountIconRoute);
export default routes;
