import React, { useState } from "react";
import {
  Modal,
  Button,
  Grid,
  Typography,
  Divider,
  Tooltip,
  IconButton,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { google, outlook, office365, ics } from "calendar-link";
import { format } from "date-fns";
import { Info, Download, Google } from "@mui/icons-material";
import Microsoft from "../assets/ms-logo.svg";
import DisplayValue from "./DisplayValue";
import useStore from "../store";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 600,
    backgroundColor: theme.palette.background.paper,
    //border: "2px solid #000",
    borderRadius: 5,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));
const CalendarInviteModal = ({ eventId, selectedEvent }) => {
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const setShowCalendarInviteModal = useStore(
    (state) => state.setShowCalendarInviteModal
  );
  const user = useStore((state) => state.user);
  const page = useStore((state) => state.page);

  const showCalendarInviteModal = useStore(
    (state) => state.showCalendarInviteModal
  );
  const calendarInvite = useStore((state) => state.calendarInvite);
  const setCalendarInvite = useStore((state) => state.setCalendarInvite);
  let createSlotInvite = useStore((state) => state.createSlotInvite);

  const createCalendarInvite = async () => {
    await createSlotInvite(eventId, selectedEvent, {
      description: calendarInvite.description,
    });

    enqueueSnackbar("You calendar invite was created.", {
      variant: "success",
    });
    setShowCalendarInviteModal(false);
  };

  return (
    <Modal
      open={showCalendarInviteModal}
      onClose={() => setShowCalendarInviteModal(false)}
    >
      <Grid
        style={modalStyle}
        className={classes.paper}
        container
        justifyContent="center"
        alignItems="center"
        spacing={1}
      >
        <Grid container justifyContent="center" item xs={12}>
          <Typography variant="h5">Event Details</Typography>
        </Grid>
        <Grid container justifyContent="center" item xs={12}>
          <Typography variant="caption">
            {Intl.DateTimeFormat().resolvedOptions().timeZone}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <DisplayValue title="Title" value={calendarInvite.title} />
        </Grid>
        <Grid item xs={4}>
          <DisplayValue
            title="Start Date/Time"
            value={format(
              new Date(calendarInvite.start),
              "MM/dd/yyyy - h':'mm a"
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <DisplayValue
            title="End Date/Time"
            value={format(
              new Date(calendarInvite.end),
              "MM/dd/yyyy - h':'mm a"
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <DisplayValue
            title="Guests"
            value={calendarInvite?.guests?.join(" ")}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {user.hasGoogleConnection || user.hasMicrosoftConnection ? (
          <>
            <Grid container justifyContent="center" item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                multiline
                id="purpose"
                label="Description of Meeting"
                name="purpose"
                value={calendarInvite.description}
                onChange={(e) =>
                  setCalendarInvite({
                    ...calendarInvite,
                    description: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid container justifyContent="center" item xs={12}>
              <Button
                color="primary"
                variant="contained"
                onClick={createCalendarInvite}
                disabled={!selectedEvent || selectedEvent.inviteSent}
              >
                Create Invite
              </Button>
            </Grid>
          </>
        ) : (
          <>
            <Grid container justifyContent="center" item xs={12}>
              <Typography variant="h5">
                Create Calendar Event{" "}
                <Tooltip
                  placement="right-start"
                  title="These buttons will open a new tab with your event prepopulated."
                >
                  <IconButton style={{ marginLeft: -5 }}>
                    <Info />
                  </IconButton>
                </Tooltip>
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                target="_blank"
                startIcon={<Google />}
                href={google(calendarInvite)}
              >
                Google Calendar
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                target="_blank"
                startIcon={<img src={Microsoft} alt="mslogo" />}
                href={office365(calendarInvite)}
              >
                Microsoft Outlook
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                target="_blank"
                startIcon={<Download />}
                href={ics(calendarInvite)}
              >
                Event File (.ics)
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </Modal>
  );
};
export default CalendarInviteModal;
