import { Container, Box, Grid } from "@mui/material";
import isUrl from "is-url";
import MultiEmailInput from "../../components/MultiEmailInput";
import GoogleButton from "../../components/OauthButtons";
import { MicrosoftButton } from "../../components/OauthButtons";

let Playground = () => {
  let handleChange = (e) => console.log(e);
  let backgroundImage =
    "https://d2ub6d0yniya4m.cloudfront.net/custombgimages/61134fd5df77540015d02077/profilepic(1).jpeg";
  return (
    <Container maxWidth="sm">
      <Box style={{ marginTop: "20vh" }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <MultiEmailInput onChange={handleChange} />
          </Grid>
          <Grid item xs={12}>
            <GoogleButton />
          </Grid>
          <Grid item xs={12}>
            <GoogleButton variant="signup" />
          </Grid>
          <Grid item xs={12}>
            <MicrosoftButton buttonText="Sign In with Microsoft" />
          </Grid>
          <Grid item xs={12}>
            <MicrosoftButton
              buttonText="Sign Up with Microsoft"
              redirectURL={`${window.location.origin}/signup/microsoft`}
            />
          </Grid>
          <Grid item xs={12}>
            {isUrl(backgroundImage)
              ? `url("${backgroundImage}")`
              : `url("https://d2ub6d0yniya4m.cloudfront.net/bgimages/${backgroundImage}.png")`}
            {isUrl(backgroundImage) ? "true" : "false"}
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Playground;
