import React from "react";
import { makeStyles } from "@mui/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Button, IconButton, Box, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import {
  AccountCircle,
  FileCopyOutlined,
  OpenInNew,
  Edit,
} from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import logo from "../assets/ihelp_logo.svg";
import useStore from "../store";
import { getBaseUrl } from "../util";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#f9fafc",
    minHeight: "100vh",
  },
  appBar: { boxShadow: "none", backgroundColor: "#f0f8ff", maxHeight: 61 },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  logoContainer: {
    flexGrow: 1,
    maxWidth: 110,
  },
  logo: {
    maxHeight: 40,
    maxWidth: 80,
  },
  title: {
    flexGrow: 1,
    color: "#072757",
  },
  content: {
    flexGrow: 1,
    marginTop: 10,
    //padding: theme.spacing(3),
  },
  // button: {
  //   color: "#607f9e",
  // },
}));
const currentURL = getBaseUrl();
export default function SchedulerNavBar({
  buttonText,
  buttonURL,
  children,
  hideAddNewMeeting,
  orgLink,
  additionalButtons = [],
  title,
  hideReturnToPage,
}) {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { page, setShowPageNameChangeModal, user } = useStore((state) => ({
    setShowUpgradeModal: state.setShowUpgradeModal,
    page: state.page,
    user: state.user,
    setShowPageNameChangeModal: state.setShowPageNameChangeModal,
  }));
  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} position="static">
        <Toolbar>
          <div
            onClick={() => history.push("/p/edit")}
            style={{ cursor: "pointer" }}
            className={classes.logoContainer}
          >
            <img src={logo} alt="Ihelp" className={classes.logo} />
          </div>
          {/* <div>
            <p style={{ color: "black" }}>Scheduler</p>
          </div> */}
          {orgLink ? (
            <Typography variant="body1" className={classes.title}>
              Your URL:{" "}
              <Box display="inline" fontWeight="fontWeightBold">
                <a
                  href={`${currentURL}/hq/${
                    user?.org?.teamUrl || user?.org?._id
                  }`}
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none" }}
                  onMouseOver={(event) =>
                    (event.target.style.textDecoration = "underline")
                  }
                  onMouseOut={(event) =>
                    (event.target.style.textDecoration = "none")
                  }
                >
                  {`${currentURL}/hq/${user?.org?.teamUrl || user?.org?._id}`}
                </a>
              </Box>
              <IconButton
                style={{
                  color: "#2f567d",
                  padding: 3,
                  marginLeft: 0.5,
                  marginBottom: 5,
                }}
                edge="start"
                onClick={() => history.push("/organization/settings")}
              >
                <Edit fontSize="small" />
              </IconButton>
              <CopyToClipboard
                text={`${currentURL}/hq/${user.org._id}`}
                onCopy={(text, result) =>
                  enqueueSnackbar("URL copied to clipboard.", {
                    variant: "success",
                  })
                }
              >
                <IconButton
                  style={{
                    color: "#2f567d",
                    padding: 3,
                    marginLeft: 0.5,
                    marginBottom: 5,
                  }}
                  edge="start"
                >
                  <FileCopyOutlined fontSize="small" />
                </IconButton>
              </CopyToClipboard>
              <IconButton
                style={{
                  color: "#2f567d",
                  padding: 3,
                  marginLeft: 0.5,
                  marginBottom: 5,
                }}
                edge="start"
                target="_blank"
                href={`${currentURL}/hq/${user.org.teamUrl || user.org._id}`}
              >
                <OpenInNew fontSize="small" />
              </IconButton>
            </Typography>
          ) : (
            <Typography variant="body1" className={classes.title}>
              {title}
            </Typography>
          )}
          {additionalButtons.map((CustomButton) => CustomButton)}
          {!hideAddNewMeeting && (
            <Button
              className={classes.button}
              href={buttonURL || "/p/edit/eventtype"}
              //color="inherit"
              variant="outlined"
            >
              {buttonText || `Add New Meeting Type`}
            </Button>
          )}
          {!hideReturnToPage && (
            <Button
              className={classes.button}
              onClick={() => history.push(buttonURL || "/p/edit/")}
              color="primary"
              variant="contained"
              style={{ marginLeft: 5 }}
            >
              {buttonText || `Return to My Page`}
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <main className={classes.content}>{children}</main>
    </div>
  );
}
