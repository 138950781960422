import React, { useState } from "react";
import { CompactPicker } from "react-color";
import { Typography, TextField, Grid, Button, Container } from "@mui/material";
import useStore from "../../../store";
const Background = ({ cancelForm }) => {
  const page = useStore((state) => state.page);
  const user = useStore((state) => state.user);
  const updatePage = useStore((state) => state.updatePage);
  const fetchPage = useStore((state) => state.fetchPage);
  let [backgroundColor, setBackgroundColor] = useState(page.color);
  let handleChangeComplete = (color) => {
    setBackgroundColor(color.hex);
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    await updatePage(user.defaultPage, {
      color: backgroundColor,
    });
    fetchPage();
  };

  return (
    <Container style={{ paddingLeft: 8, paddingRight: 8 }}>
      <form style={{ width: "100%" }} onSubmit={handleSubmit}>
        <Grid container spacing={1} justifyContent="center">
          <Grid item xs={12}>
            <Typography variant="subtitle2">Update Background</Typography>
          </Grid>
          <Grid item xs={12}>
            <CompactPicker
              color={backgroundColor}
              onChangeComplete={handleChangeComplete}
            />
          </Grid>
          <Grid item xs={6}>
            <Button variant="outlined" onClick={cancelForm}>
              CANCEL
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" color="primary" type="submit">
              SAVE
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default Background;
