import { useState, useEffect } from "react";
import {
  Container,
  Paper,
  Grid,
  Typography,
  TextField,
  Button,
  InputAdornment,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useHistory } from "react-router-dom";
import { DeleteOutline, Edit, Link } from "@mui/icons-material";
import { getDay, startOfWeek, parse, format } from "date-fns";
import enUS from "date-fns/locale/en-US";
import SchedulerNavbar from "../../components/SchedulerNavbar";
import useStore from "../../store";
const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    minHeight: 600,
    padding: 20,
  },
}));

const Event = ({ event, onArchive }) => {
  let history = useHistory();

  let totalVotes = event?.slots?.reduce((n, { votes }) => n + votes, 0);
  let latestUpdatedSlot = event?.slots?.reduce((a, b) => {
    return new Date(a.MeasureDate) > new Date(b.MeasureDate) ? a : b;
  });

  return event?.slots?.length > 0 ? (
    <Grid
      style={{ border: "1px solid #1e79d6", padding: 5 }}
      container
      justifyContent="space-between"
      alignItems="center"
      item
      xs={6}
    >
      <Grid item xs={6}>
        <Typography
          onClick={() =>
            history.push(`/p/find-availability/result/${event._id}`)
          }
          variant="subtitle2"
          style={{ color: "#1e79d6", cursor: "pointer" }}
        >
          {event.name}
        </Typography>
      </Grid>

      <Grid item container justifyContent="flex-end" xs={6}>
        <Typography
          onClick={() =>
            history.push(`/p/find-availability/result/${event._id}`)
          }
          style={{ cursor: "pointer" }}
          variant="subtitle2"
        >
          {totalVotes} responses
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="caption">
          Last Activity:{" "}
          {format(new Date(latestUpdatedSlot.updatedAt), "MMM d h:m aa")}
        </Typography>
      </Grid>
      <Grid item>
        {!event.archived ? (
          <>
            <Button
              onClick={() =>
                history.push(`/p/find-availability/edit/${event._id}`)
              }
              color="primary"
              style={{ paddingRight: 0 }}
            >
              Edit
            </Button>
            |{" "}
            <Button onClick={onArchive} style={{ color: "red" }}>
              Archive
            </Button>
          </>
        ) : (
          <Button color="primary" style={{ paddingRight: 0 }}>
            View
          </Button>
        )}
      </Grid>
    </Grid>
  ) : null;
};

const FindAvailabilityResults = () => {
  const classes = useStyles();
  let history = useHistory();
  let user = useStore((state) => state.user);
  let [events, setEvents] = useState([]);
  let [availabilityEvent, setAvailabilityEvent] = useState(null);
  let fetchAvailabilityEvents = useStore(
    (state) => state.fetchAvailabilityEvents
  );
  let archiveAvailabilityEvent = useStore(
    (state) => state.archiveAvailabilityEvent
  );
  const handleArchive = async (id) => {
    await archiveAvailabilityEvent(id);
    handleFetchAvailabilityEvents();
  };
  const handleFetchAvailabilityEvents = async () => {
    let { data: availabilityEvents } = await fetchAvailabilityEvents();
    console.log(availabilityEvents);
    setEvents(availabilityEvents);
  };
  useEffect(() => {
    handleFetchAvailabilityEvents();
  }, []);
  let archivedEvents = events.filter((event) => event.archived);
  let openEvents = events.filter((event) => !event.archived);
  return (
    <SchedulerNavbar hideAddNewMeeting={true} title="Request Availability">
      <Container maxWidth="md">
        <Paper className={classes.paper}>
          <Grid
            container
            justifyContent="center"
            spacing={2}
            style={{ width: "100%" }}
          >
            <Grid item container justifyContent="center" xs={12}>
              <Typography variant="h4">Open Requests</Typography>
            </Grid>
            <Grid item container justifyContent="center" spacing={1} xs={12}>
              {openEvents.map((event) => (
                <Grid item container justifyContent="center" xs={12}>
                  <Event
                    onArchive={() => handleArchive(event._id)}
                    event={event}
                  />
                </Grid>
              ))}
            </Grid>
            <Grid
              item
              container
              justifyContent="center"
              style={{ marginTop: 20, marginBottom: 40 }}
              xs={12}
            >
              <Button
                color="primary"
                variant="contained"
                onClick={() => history.push("/p/find-availability")}
              >
                Create a New Event
              </Button>
            </Grid>
            <Grid item container justifyContent="center" xs={12}>
              <Typography variant="h4">Archived Requests</Typography>
            </Grid>
            <Grid item container justifyContent="center" spacing={1} xs={12}>
              {archivedEvents.map((event) => (
                <Grid item container justifyContent="center" xs={12}>
                  <Event event={event} />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </SchedulerNavbar>
  );
};

export default FindAvailabilityResults;
