import axios from "axios";
const baseUrl =
  process.env.REACT_APP_BASE_URL || process.env.REACT_APP_DEV_ENDPOINT;
const requests = {
  refreshUserRequest: () =>
    axios({
      method: "get",
      url: `${baseUrl}/v1/users/me`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  runAutoCreate: (calendarType = "google") =>
    axios({
      method: "post",
      url: `${baseUrl}/v1/users/autocreate/${calendarType}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  signupWithLinkedin: (code, uri, timeZone) =>
    axios({
      method: "post",
      url: `${baseUrl}/v1/users`,
      data: {
        linkedinCode: code,
        redirectURI: uri,
        referralPage: localStorage.getItem("referralPage"),
        defaultTimeZone: timeZone,
      },
    }),
  connectWithZoom: (
    code,
    redirectURI = `${window.location.origin}/connect/zoom`
  ) =>
    axios({
      method: "post",
      url: `${baseUrl}/v1/connects/zoom`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {
        code,
        redirectURI,
      },
    }),
  deleteAccount: (id) =>
    axios({
      method: "DELETE",
      url: `${baseUrl}/v1/users/account/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  switchOrgs: (org) =>
    axios({
      method: "post",
      url: `${baseUrl}/v1/users/switchorgs`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {
        org,
      },
    }),
  inviteUser: (newUser) =>
    axios({
      method: "post",
      url: `${baseUrl}/v1/organizations/users/invite`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: newUser,
    }),
  deactivateUser: (userId) =>
    axios({
      method: "delete",
      url: `${baseUrl}/v1/users/deactivate/${userId}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  activateUser: (userId) =>
    axios({
      method: "put",
      url: `${baseUrl}/v1/users/activate/${userId}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  loginWithGoogle: (googleCode, uri, defaultTimeZone) =>
    axios({
      method: "post",
      url: `${baseUrl}/v1/users/login/google`,
      ...(localStorage.getItem("token") && {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }),
      data: {
        googleCode,
        redirectURI: uri,
        defaultTimeZone,
      },
    }),
  signupWithGoogle: (googleCode, uri, timeZone, bypassCalendar) =>
    axios({
      method: "post",
      url: `${baseUrl}/v1/users`,
      data: {
        googleCode,
        redirectURI: uri,
        referralPage: localStorage.getItem("referralPage"),
        defaultTimeZone: timeZone,
        bypassCalendar,
      },
    }),
  removeGoogleConnection: () =>
    axios({
      method: "DELETE",
      url: `${baseUrl}/v1/users/disconnect/google`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  removeMicrosoftConnection: () =>
    axios({
      method: "DELETE",
      url: `${baseUrl}/v1/users/disconnect/microsoft`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  loginWithLinkedin: (code, redirectURI, defaultTimeZone) =>
    axios({
      method: "post",
      url: `${baseUrl}/v1/users/login/linkedin`,
      data: {
        code,
        redirectURI,
        defaultTimeZone,
      },
    }),
  loginWithMicrosoft: (code, redirectURI, defaultTimeZone) =>
    axios({
      method: "post",
      url: `${baseUrl}/v1/users/login/microsoft`,
      headers: {
        ...(localStorage.getItem("token") && {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        }),
      },
      data: {
        microsoftCode: code,
        redirectURI,
        defaultTimeZone,
      },
    }),
  signupWithMicrosoft: (microsoftCode, uri, timeZone) =>
    axios({
      method: "post",
      url: `${baseUrl}/v1/users`,
      data: {
        microsoftCode,
        redirectURI: uri,
        referralPage: localStorage.getItem("referralPage"),
        defaultTimeZone: timeZone,
      },
    }),
  createElement: (payload) =>
    axios({
      method: "POST",
      url: `${baseUrl}/v1/elements`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {
        ...payload,
      },
    }),

  updateElement: (id, payload) =>
    axios({
      method: "PUT",
      url: `${baseUrl}/v1/elements/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {
        ...payload,
      },
    }),
  removeElement: (id) =>
    axios({
      method: "DELETE",
      url: `${baseUrl}/v1/elements/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  fetchPageElement: (id) =>
    axios({
      method: "get",
      url: `${baseUrl}/v1/elements/${id}`,
    }),
  fetchPresignedUrl: async (filename, uploadType = "downloadfile", data) =>
    axios({
      method: "post",
      url: `${baseUrl}/v1/pages/uploadurl/${uploadType}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      ...(filename && {
        data: {
          filename,
          ...data,
        },
      }),
    }),
  fetchPresignedFileUploadUrl: async (filename, elementId, data) =>
    axios({
      method: "post",
      url: `${baseUrl}/v1/pages/uploadurl/fileupload/${elementId}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      ...(filename && {
        data: {
          filename,
          ...data,
        },
      }),
    }),
  listEventTypes: () =>
    axios({
      method: "get",
      url: `${baseUrl}/v1/eventtypes`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  createEventType: (
    data = new Error("Data is required to create an event type")
  ) =>
    axios({
      method: "POST",
      url: `${baseUrl}/v1/eventtypes`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data,
    }),
  updateEventType: (
    id = new Error("id is required to update an event type"),
    data
  ) =>
    axios({
      method: "PUT",
      url: `${baseUrl}/v1/eventtypes/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data,
    }),
  listCalendars: (calendarType = "google") =>
    axios({
      method: "get",
      url: `${baseUrl}/v1/calendars/${calendarType}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "application/json",
    }),
  listCalendarEvents: (calendarType = "google", calendars) =>
    axios({
      method: "POST",
      url: `${baseUrl}/v1/calendars/${calendarType}/events`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "application/json",
      data: {
        calendars,
      },
    }),
  refreshGoogleMeetUrl: (data) =>
    axios({
      method: "POST",
      url: `${baseUrl}/v1/users/googlemeet`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "application/json",
      data,
    }),
  fetchGoogleProfile: () =>
    axios({
      method: "GET",
      url: `${baseUrl}/v1/users/profile/google`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "application/json",
    }),
  fetchTimeSlots: (eventTypeId, data) =>
    axios({
      method: "POST",
      url: `${baseUrl}/v1/bookings/timeslots/google/${eventTypeId}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data,
    }),
  createBooking: (data) =>
    axios({
      method: "POST",
      url: `${baseUrl}/v1/bookings`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data,
    }),
  createStripeCheckoutSession: (data) =>
    axios({
      method: "POST",
      url: `${baseUrl}/v1/stripe/checkout`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data,
    }),
  updateByStripeSession: (sessionId) =>
    axios({
      method: "PUT",
      url: `${baseUrl}/v1/stripe/session`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {
        sessionId,
      },
    }),
  createStripePortalSession: (data) =>
    axios({
      method: "POST",
      url: `${baseUrl}/v1/stripe/portal`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data,
    }),
  searchDomains: (domains) =>
    axios({
      method: "POST",
      url: `${baseUrl}/v1/domains/search`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {
        domains: domains,
      },
    }),
  fetchDomainPricing: () =>
    axios({
      method: "GET",
      url: `${baseUrl}/v1/domains/pricing`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  fetchOwnedDomains: () =>
    axios({
      method: "GET",
      url: `${baseUrl}/v1/domains`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  updateOwnedDomains: (id, data) =>
    axios({
      method: "PUT",
      url: `${baseUrl}/v1/domains/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data,
    }),
  setDomainRedirect: (domain) =>
    axios({
      method: "PUT",
      url: `${baseUrl}/v1/domains/dns`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {
        domain,
      },
    }),
  createAvailabilityEvent: (data) =>
    axios({
      method: "POST",
      url: `${baseUrl}/v1/availabilityevents`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data,
    }),
  fetchAvailabilityEvent: (id) =>
    axios({
      method: "GET",
      url: `${baseUrl}/v1/availabilityevents/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  fetchAvailabilityEvents: () =>
    axios({
      method: "GET",
      url: `${baseUrl}/v1/availabilityevents`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  archiveAvailabilityEvent: (id) =>
    axios({
      method: "DELETE",
      url: `${baseUrl}/v1/availabilityevents/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  archiveAvailabilitySlot: (id) =>
    axios({
      method: "DELETE",
      url: `${baseUrl}/v1/availabilityevents/availabilityslots/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  updateAvailabilityEvent: (id, data) =>
    axios({
      method: "PUT",
      url: `${baseUrl}/v1/availabilityevents/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data,
    }),
  castVote: (eventId, data) =>
    axios({
      method: "PUT",
      url: `${baseUrl}/v1/availabilityevents/${eventId}/availabilityslots`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data,
    }),
  createSlotInvite: (eventId, slotId, data) =>
    axios({
      method: "POST",
      url: `${baseUrl}/v1/availabilityevents/${eventId}/availabilityslots/${slotId}/calendarevent`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data,
    }),
  fetchUploads: () =>
    axios({
      method: "GET",
      url: `${baseUrl}/v1/uploads`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  updateUpload: (id, body) =>
    axios({
      method: "PUT",
      url: `${baseUrl}/v1/uploads/completeupload/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: body,
    }),
  fetchUploadByPublicId: (publicId) =>
    axios({
      method: "GET",
      url: `${baseUrl}/v1/uploads/publicurl/${publicId}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  generatePublicUrl: (id) =>
    axios({
      method: "PUT",
      url: `${baseUrl}/v1/uploads/publicurl/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  deleteUpload: (id) =>
    axios({
      method: "DELETE",
      url: `${baseUrl}/v1/uploads/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  updateUser: ({
    firstName,
    lastName,
    email,
    password,
    department,
    title,
    id,
  }) =>
    axios({
      method: "put",
      url: `${baseUrl}/v1/users/${id || "me"}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {
        firstName,
        lastName,
        email,
        password,
        department,
        title,
      },
    }),
  listUsers: async () =>
    axios({
      method: "get",
      url: `${baseUrl}/v1/users`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  searchUsers: async (searchText) =>
    axios({
      method: "post",
      url: `${baseUrl}/v1/organizations/users/search`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {
        searchText,
      },
    }),
  searchTeam: async (searchText, teamName) =>
    axios({
      method: "post",
      url: `${baseUrl}/v1/organizations/users/team/search/${teamName}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {
        searchText,
      },
    }),
  impersonateUser: async (userId, org) =>
    axios({
      method: "post",
      url: `${baseUrl}/v1/users/impersonate`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {
        userId,
        org,
      },
    }),
  updateOrg: (orgId, data) =>
    axios({
      method: "put",
      url: `${baseUrl}/v1/organizations/${orgId}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data,
    }),
  retrieveAudits: (id) =>
    axios({
      method: "POST",
      url: `${baseUrl}/v1/audit`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {
        user: id,
      },
    }),
  listUsersInOrg: () =>
    axios({
      method: "get",
      url: `${baseUrl}/v1/organizations/users`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  fetchTeam: (teamName) =>
    axios({
      method: "get",
      url: `${baseUrl}/v1/organizations/team/${teamName}`,
    }),
  retrieveUser: (id) =>
    axios({
      method: "get",
      url: `${baseUrl}/v1/users/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  fetchEventType: (id) =>
    axios({
      method: "get",
      url: `${baseUrl}/v1/eventTypes/${id}`,
    }),
  archiveEventType: (id) =>
    axios({
      method: "delete",
      url: `${baseUrl}/v1/eventTypes/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  fetchMeeting: (pageName) =>
    axios({
      method: "get",
      url: `${baseUrl}/v1/meetings/${pageName}`,
      headers: localStorage.getItem("token")
        ? {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          }
        : {},
    }),
};

export default requests;
