import zoomLogo from "../assets/zoomlogo.svg";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  brandIcon: {
    maxHeight: 25,
    marginRight: 10,
  },
}));

const ZoomButton = ({
  buttonText = "Connect to Zoom",
  redirectURL = `${window.location.origin}/connect/zoom`,
  disabled = false,
}) => {
  const classes = useStyles();
  let zoomClientId = process.env.REACT_APP_ZOOM_CLIENT_ID;
  let buttonUrl = `https://zoom.us/oauth/authorize?response_type=code&client_id=${zoomClientId}&redirect_uri=${redirectURL}`;
  return (
    <Button href={buttonUrl} variant="outlined" fullWidth disabled={disabled}>
      <img
        key="zoombutton"
        className={classes.brandIcon}
        src={zoomLogo}
        alt="Zoom"
      />{" "}
      {disabled ? "Zoom is already Connected" : buttonText}
    </Button>
  );
};

export default ZoomButton;
