import { useState, useEffect } from "react";
import {
  Container,
  Paper,
  Grid,
  Typography,
  TextField,
  Modal,
  IconButton,
  Button,
} from "@mui/material";

import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { useParams, useHistory } from "react-router-dom";
import { DeleteOutline, Edit, Close } from "@mui/icons-material";
import { getDay, startOfWeek, parse, format, add } from "date-fns";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import SchedulerNavbar from "../../components/SchedulerNavbar";
import MultiCalendarSelect from "../../components/CalendarsSelect";
import GoogleButton, { MicrosoftButton } from "../../components/OauthButtons";
import useStore from "../../store";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    minHeight: 200,
    padding: 20,
  },
  modal: {
    position: "absolute",
    width: 450,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const FindAvailability = () => {
  let { enqueueSnackbar } = useSnackbar();
  let history = useHistory();
  let { id } = useParams();
  const classes = useStyles();
  let user = useStore((state) => state.user);
  let hasGoogleCalendarScope = useStore(
    (state) => state.hasGoogleCalendarScope
  );
  let createAvailabilityEvent = useStore(
    (state) => state.createAvailabilityEvent
  );
  let updateAvailabilityEvent = useStore(
    (state) => state.updateAvailabilityEvent
  );
  let fetchAvailabilityEvent = useStore(
    (state) => state.fetchAvailabilityEvent
  );
  let archiveAvailabilitySlot = useStore(
    (state) => state.archiveAvailabilitySlot
  );
  let listCalendarEvents = useStore((state) => state.listCalendarEvents);
  let listCalendars = useStore((state) => state.listCalendars);
  let [events, setEvents] = useState([]);
  let [bgEvents, setBgEvents] = useState([]);
  let [calendars, setCalendars] = useState([]);
  let [selectedCalendars, setSelectedCalendars] = useState([]);
  let [name, setName] = useState("");
  let [scrollTime, setScrollTime] = useState("08:00:00");
  let [selectedEvent, setSelectedEvent] = useState(null);
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(false);

  const preferredCalendar = hasGoogleCalendarScope(user)
    ? "google"
    : user.hasMicrosoftConnection
    ? "microsoft"
    : null;

  const handleClose = () => {
    setOpen(false);
  };
  const handleSelectEvent = (newEvent, other) => {
    newEvent.id = new Date().getTime();
    setScrollTime(`${new Date(newEvent.start).getHours()}:00:00`);
    setEvents([...events, newEvent]);
  };

  const handleSelectedEvent = (ev, other) => {
    const event = ev.event;
    setSelectedEvent({
      start: event.start,
      end: event.end,
      id: event.id,
      _id: event?.extendedProps?._id,
    });
    setOpen(true);
  };

  const removeEvent = async (eventId, slot) => {
    console.log({ eventId, slot });
    let filteredEvents = events.filter(
      (event) => event._id !== eventId && event.id !== Number(eventId) // Only events that are stored in the database have _id
    );
    if (selectedEvent._id) {
      await archiveAvailabilitySlot(slot.id); // Marks the slot as archived in the DB
    }

    setEvents(filteredEvents);
    setSelectedEvent(null);
    setOpen(false);
  };

  const handleCreateAvailabilityEvent = async () => {
    if (!name) {
      return enqueueSnackbar("Please enter a name for this event", {
        variant: "error",
      });
    }
    let { data: availabilityEvent } = await createAvailabilityEvent({
      name,
      calendarType: preferredCalendar,
      events: events.filter((event) => event.display !== "background"),
    });
    history.push(`/p/find-availability/result/${availabilityEvent.event._id}`);
  };

  const handleUpdateAvailabilityEvent = async () => {
    try {
      let { data: availabilityEvent } = await updateAvailabilityEvent(id, {
        name,
        events,
      });
      console.log(availabilityEvent);
      history.push(`/p/find-availability/result/${availabilityEvent._id}`);
    } catch (e) {
      console.log(e);
    }
  };

  const handleFetchAvailabilityEvent = async () => {
    let { data: availabilityEvent } = await fetchAvailabilityEvent(id);
    console.log(availabilityEvent);
    setName(availabilityEvent.name);
    let cleanedSlots = availabilityEvent.slots
      .filter((s) => !s.archived)
      .map((slot) => ({
        _id: slot._id,
        id: slot._id || new Date().getTime(),
        start: new Date(slot.start),
        end: new Date(slot.end),
      }));
    setEvents(cleanedSlots);
    console.log(availabilityEvent.slots);
  };

  const truncateString = (str = "", num = 12) => {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  };

  const handleListCalendarEvents = async () => {
    let { data: rawBgEvents } = await listCalendarEvents(
      preferredCalendar,
      selectedCalendars
    );
    if (preferredCalendar === "microsoft") {
      rawBgEvents = rawBgEvents.value;
    }
    if (preferredCalendar === "google") {
      rawBgEvents = rawBgEvents.items;
    }
    let cleanedBgEvents = rawBgEvents.map((e) => ({
      start: new Date(e?.start?.dateTime),
      end: new Date(e?.end?.dateTime),
      title: truncateString(e.subject, 12) || truncateString(e.summary, 12),
      display: "background",
    }));
    setBgEvents(cleanedBgEvents);
  };
  async function handleListCalendars(calendarType = "google") {
    console.log("handleListCalendars was called");
    try {
      let calendarsRequest = await listCalendars(
        calendarType,
        selectedCalendars
      );
      if (calendarType === "google") {
        setCalendars(calendarsRequest.data.items);
      } else {
        setCalendars(calendarsRequest.data.value);
      }
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    if (id) {
      handleFetchAvailabilityEvent();
    }
    if (user.hasMicrosoftConnection) {
      handleListCalendars("microsoft");
    } else {
      handleListCalendars("google");
    }
    handleListCalendarEvents();
  }, []);
  const handleEditEvent = (editEvent) => {
    const clonedEvents = [...events];
    const eventIndex = clonedEvents.findIndex(
      (e) => e.id.toString() === editEvent.event.id
    );
    console.log({ eventIndex, editEvent });
    clonedEvents[eventIndex] = {
      start: editEvent.event.start,
      end: editEvent.event.end,
      id: editEvent.event.id,
      title: editEvent.event.title,
      _id: editEvent?.event?.extendedProps?._id,
      //display: editEvent.event.display,
    };

    setEvents(clonedEvents);
  };

  const handleCalendarChange = async (e) => {
    setSelectedCalendars(e.target.value);
    handleListCalendarEvents();
  };
  return (
    <SchedulerNavbar hideAddNewMeeting={true} title="Request Availability">
      <Container style={{ paddingBottom: 30 }} maxWidth="md">
        <Paper className={classes.paper}>
          <Grid container spacing={2} style={{ width: "100%" }}>
            <Grid item xs={12} md={8}>
              <Typography variant="subtitle2">
                Complete this information to create a shareable link to
                determine people's availability.
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              {preferredCalendar === "google" ? (
                <GoogleButton
                  fullWidth
                  disabled={hasGoogleCalendarScope(user)}
                />
              ) : preferredCalendar === "microsoft" ? (
                <MicrosoftButton
                  fullWidth
                  disabled={user.hasMicrosoftConnection}
                />
              ) : (
                <>
                  <GoogleButton
                    fullWidth
                    disabled={hasGoogleCalendarScope(user)}
                  />

                  <MicrosoftButton
                    buttonStyles={{ marginTop: 10 }}
                    fullWidth
                    disabled={user.hasMicrosoftConnection}
                  />
                </>
              )}
            </Grid>
            <Grid
              item
              container
              xs={12}
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={12} md={4}>
                <TextField
                  variant="outlined"
                  label="Name your event"
                  autoComplete="off"
                  fullWidth
                  autoFocus
                  InputLabelProps={{ shrink: true }}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                {calendars.length > 0 && (
                  <MultiCalendarSelect
                    calendars={calendars}
                    onChange={handleCalendarChange}
                    value={selectedCalendars}
                  />
                )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                Using the calendar below please select the times that work for
                you.{" "}
                <span
                  style={{ fontSize: ".75em", fontWeight: "bold" }}
                >{`Selected Times (${events.length})`}</span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FullCalendar
                plugins={[timeGridPlugin, interactionPlugin]}
                headerToolbar={{
                  left: "prev,next today",
                  center: "title",
                  right: "timeGridWeek,timeGridDay",
                }}
                initialView="timeGridWeek"
                editable={true}
                selectable={true}
                selectMirror={true}
                allDaySlot={false}
                eventDrop={handleEditEvent}
                eventResize={handleEditEvent}
                validRange={{ start: new Date() }} // Disables past days from being selected
                // weekends={this.state.weekendsVisible}
                events={[...bgEvents, ...events]} // alternatively, use the `events` setting to fetch from a feed
                select={handleSelectEvent}
                // eventContent={renderEventContent} // custom render function
                eventClick={handleSelectedEvent}
                scrollTimeReset={false}
                scrollTime={scrollTime}
                //timezone="local" // Set to local timezone (its the default)
                // slotMinTime="08:00:00" //Controls the start time of the time axis
                // slotMaxTime="24:00:00" //Controls the end time of the time axis
                // eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed
                /* you can update a remote database when these fire:
            eventAdd={function(){}}
            eventChange={function(){}}
            eventRemove={function(){}}
            */
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                style={{ minWidth: 200 }}
                disabled={events.length < 2}
                onClick={
                  id
                    ? handleUpdateAvailabilityEvent
                    : handleCreateAvailabilityEvent
                }
              >
                {id ? "Update" : "Finish"}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Container>
      <Modal open={open} onClose={handleClose}>
        <Paper style={modalStyle} className={classes.modal}>
          {selectedEvent && (
            <Grid container justifyContent="space-between">
              <Grid item xs={11}>
                {/* <IconButton size="small">
                  <Edit />
                </IconButton> */}
                <Typography variant="h6">
                  {format(new Date(selectedEvent.start), "eeee, LLLL d")} ⋅
                  {format(new Date(selectedEvent.start), "p")} -{" "}
                  {format(new Date(selectedEvent.end), "p")}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  size="small"
                  onClick={() =>
                    removeEvent(
                      selectedEvent._id || selectedEvent.id,
                      selectedEvent
                    )
                  }
                >
                  <DeleteOutline />
                </IconButton>
                {/* <IconButton size="small" onClick={handleClose}>
                  <Close />
                </IconButton> */}

                {/* <p>
                  Duis mollis, est non commodo luctus, nisi erat porttitor
                  ligula.
                </p> */}
              </Grid>
            </Grid>
          )}
        </Paper>
      </Modal>
    </SchedulerNavbar>
  );
};

export default FindAvailability;
