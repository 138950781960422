/*
This page will look at the state of the createEvent object in global state and allow the user to create an event in various calendars by clicking a link.
*/

import { Link } from "react-router-dom";
import SchedulerNavbar from "../../components/SchedulerNavbar";
import DisplayValue from "../../components/DisplayValue";
import {
  TextField,
  Grid,
  Paper,
  CssBaseline,
  Container,
  InputAdornment,
  Button,
  Typography,
  Divider,
} from "@mui/material";
import useStore from "../../store";
import { makeStyles } from "@mui/styles";
import { AttachMoney, Loop, SyncDisabledTwoTone } from "@mui/icons-material";

import { google, outlook, office365, yahoo, ics } from "calendar-link";

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  formHeader: { marginBottom: theme.spacing(2) },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
function CreateInvite() {
  const classes = useStyles();
  const store = useStore();
  const invite = useStore((invite) => invite);
  // const invite = {
  //   title: "My birthday party",
  //   description: "Be there!",
  //   start: "2019-12-29 18:00:00 +0100",
  //   duration: [3, "hour"],
  // };

  // Then fetch the link
  // google(invite); // https://calendar.google.com/calendar/render...
  // outlook(invite); // https://outlook.live.com/owa/...
  // office365(invite); // https://outlook.office.com/owa/...
  // yahoo(invite); // https://calendar.yahoo.com/?v=60&title=...
  // ics(invite); // standard ICS file based on https://icalendar.org

  return (
    <SchedulerNavbar hideAddNewMeeting>
      <Container component="main" maxWidth="md">
        <Grid container justifyContent="center">
          <Paper style={{ width: "100%", minHeight: 200, padding: 15 }}>
            <Grid container justifyContent="center" spacing={1}>
              <Grid container justifyContent="center" item xs={12}>
                <Typography variant="h5">Event Details</Typography>
              </Grid>
              <Grid item xs={4}>
                <DisplayValue title="Title" value={invite.title} />
              </Grid>
              <Grid item xs={4}>
                <DisplayValue title="Start Date/Time" value={invite.start} />
              </Grid>
              <Grid item xs={4}>
                <DisplayValue title="Description" value={invite.description} />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid container justifyContent="center" item xs={12}>
                <Typography variant="h5">Create Calendar Event</Typography>
              </Grid>
              <Grid container justifyContent="center" item xs={2}>
                <Button
                  variant="contained"
                  target="_blank"
                  href={google(invite)}
                >
                  Google
                </Button>
              </Grid>
              <Grid container justifyContent="center" item xs={2}>
                <Button
                  variant="contained"
                  target="_blank"
                  href={office365(invite)}
                >
                  Outlook
                </Button>
              </Grid>
              <Grid container justifyContent="center" item xs={2}>
                <Button
                  variant="contained"
                  target="_blank"
                  href={outlook(invite)}
                >
                  Office 365
                </Button>
              </Grid>
              <Grid container justifyContent="center" item xs={2}>
                <Button variant="contained" target="_blank" href={ics(invite)}>
                  ICS File
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Container>
    </SchedulerNavbar>
  );
}

export default CreateInvite;
