import { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Container,
  Typography,
  Divider,
  Paper,
  IconButton,
  Grid,
  TextField,
  Modal,
  MenuItem,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Select,
  InputLabel,
  InputAdornment,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useHistory } from "react-router-dom";
import { AddCircle, Search, HelpOutline } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import TimeZoneSelect from "../../components/TimeZoneSelect";
import SchedulerNavbar from "../../components/SchedulerNavbar";
import useStore from "../../store";
import { getBaseUrl } from "../../util";
const useStyles = makeStyles((theme) => ({
  paper: {
    //width: "100%",
    minWidth: 100,
    maxWidth: 350,
    minHeight: 200,
    padding: 10,
  },
  modalPaper: {
    position: "absolute",
    [theme.breakpoints.up("xs")]: {
      maxWidth: "100vw",
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: 500,
    },
    backgroundColor: theme.palette.background.paper,
    //border: "2px solid #000",
    borderRadius: 5,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  avatar: {
    height: 50,
    width: 50,
    margin: 10,
  },
  root: {
    padding: 20,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));

const OutlinedButton = ({ label, style, ...rest }) => (
  <Button
    style={{
      marginRight: 10,
      ...style,
      border: "2px solid #30567d",
      color: "#30567d",
    }}
    variant="outlined"
    {...rest}
  >
    {label}
  </Button>
);

const Team = () => {
  const classes = useStyles();
  function getModalStyle() {
    return {
      top: "40%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    };
  }
  const history = useHistory();
  const [modalStyle] = useState(getModalStyle);
  const { enqueueSnackbar } = useSnackbar();
  const inviteUser = useStore((state) => state.inviteUser);
  const listUsersInOrg = useStore((state) => state.listUsersInOrg);
  const deactivateUser = useStore((state) => state.deactivateUser);
  const activateUser = useStore((state) => state.activateUser);
  const isPremiumUser = useStore((state) => state.isPremiumUser);
  const user = useStore((state) => state.user);
  const searchUsers = useStore((state) => state.searchUsers);
  let [users, setUsers] = useState([]);
  let [showInviteModal, setShowInviteModal] = useState(false);
  let [search, setSearch] = useState("");
  let [isLoading, setIsLoading] = useState(false);
  let [inviteDetails, setInviteDetails] = useState({
    role: "user",
    defaultTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
  async function fetchOrgsUsers() {
    let { data: orgsUsers } = await listUsersInOrg();
    setUsers(orgsUsers);
  }
  useEffect(() => {
    fetchOrgsUsers();
  }, []);

  const handleRemoveUser = async (id) => {
    if (
      window.confirm(
        "Are you sure you want to deactivate this user? Their page will be disabled and they will no longer be able to log in."
      )
    ) {
      await deactivateUser(id);
      fetchOrgsUsers();
    }
  };

  async function handleInviteFormSubmit(e) {
    setIsLoading(true);
    e.preventDefault();

    try {
      await inviteUser(inviteDetails);
      setShowInviteModal(false);
      enqueueSnackbar("Invitation sent successfully", {
        variant: "success",
      });
      setIsLoading(false);
      fetchOrgsUsers();
    } catch (e) {
      setIsLoading(false);
    }
  }

  async function handleSearch() {
    if (search) {
      let { data: returnedUsers } = await searchUsers(search);
      setUsers(returnedUsers);
    } else {
      fetchOrgsUsers();
    }
  }
  async function handleActivateUser(id) {
    if (window.confirm("Are you sure you want to activate this user?")) {
      await activateUser(id);
      fetchOrgsUsers();
    }
  }
  const NavbarButtons = [
    <OutlinedButton
      label="Team Settings"
      onClick={() => history.push("/organization/settings")}
    />,
    <OutlinedButton href={`/billing`} label="Manage Billing" />,
  ];

  function handleInvite(params) {
    if (isPremiumUser(user)) {
      setShowInviteModal(true);
    } else {
      history.push("/billing");
    }
  }
  console.log({ inviteDetails });
  return (
    <SchedulerNavbar
      hideAddNewMeeting
      orgLink
      additionalButtons={NavbarButtons}
    >
      <Container>
        <Grid container justifyContent="center">
          <Grid item xs={4}>
            <TextField
              value={search}
              label="Search"
              variant="outlined"
              fullWidth
              placeholder="Search name, email, department or title..."
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleSearch}>
                      <Search color="primary" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSearch();
                  // write your functionality here
                }
              }}
            />
          </Grid>
        </Grid>
        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {users.map((accountUser) => (
            <Grid key={accountUser._id} item xs={12} md={3}>
              <Paper className={classes.paper}>
                <Grid container justifyContent="center">
                  <Grid item>
                    <Avatar
                      className={classes.avatar}
                      src={accountUser?.defaultPage?.avatar}
                    />
                  </Grid>
                  <Divider style={{ width: "100%" }} />
                  <Grid item>
                    <Typography
                      variant="h6"
                      style={accountUser.deleted ? { color: "grey" } : {}}
                    >
                      {accountUser.firstName} {accountUser.lastName}{" "}
                      <Typography variant="caption">
                        {/* {["AccountAdmin", "SuperAdmin"].includes(user.role)
                          ? "(admin)"
                          : "(user)"} */}
                        {`(${
                          accountUser?.roles?.find(
                            (r) => r.org === user.org._id.toString()
                          )?.role
                        })`}
                      </Typography>
                    </Typography>
                  </Grid>
                  {accountUser.title ||
                    (accountUser.department && (
                      <Grid item xs={12}>
                        <div style={{ textAlign: "center" }}>
                          <Typography
                            style={accountUser.deleted ? { color: "grey" } : {}}
                          >
                            {accountUser.title} | {accountUser.department}
                          </Typography>
                        </div>
                      </Grid>
                    ))}
                  <Grid item xs={12}>
                    <div style={{ textAlign: "center" }}>
                      <Typography
                        variant="caption"
                        style={accountUser.deleted ? { color: "grey" } : {}}
                      >
                        Page Views: {accountUser.defaultPage.pageViews}
                      </Typography>
                    </div>
                  </Grid>
                  <br />
                </Grid>
                <Grid item xs={12}>
                  <div style={{ textAlign: "center" }}>
                    <Button
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`${
                        process.env.NODE_ENV === "production"
                          ? "https://i.help"
                          : "http://localhost:3001"
                      }/${accountUser.defaultPage.name}`}
                      color="primary"
                    >
                      View Page
                    </Button>
                    {["AccountAdmin", "SuperAdmin"].includes(user.role) && (
                      <Button
                        onClick={() =>
                          history.push(`/profile/${accountUser._id}`)
                        }
                        color="primary"
                      >
                        Manage
                      </Button>
                    )}
                    {user._id !== accountUser._id && (
                      <>
                        {["AccountAdmin", "SuperAdmin"].includes(user.role) && (
                          <>
                            {accountUser.deleted ? (
                              <Button
                                onClick={() =>
                                  handleActivateUser(accountUser._id)
                                }
                                style={{ color: "green" }}
                              >
                                Reactivate
                              </Button>
                            ) : (
                              <Button
                                onClick={() =>
                                  handleRemoveUser(accountUser._id)
                                }
                                style={{ color: "red" }}
                              >
                                Deactivate
                              </Button>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </Grid>
              </Paper>
            </Grid>
          ))}
          {(user.role === "AccountAdmin" || user.role === "SuperAdmin") && (
            <Grid item xs={12} md={3}>
              <Paper
                variant="outlined"
                className={classes.paper}
                style={{ backgroundColor: "#f9fafc" }}
              >
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  alignContent="center"
                >
                  <Grid item>
                    <Avatar className={classes.avatar} />
                  </Grid>
                  <Divider style={{ width: "100%" }} />
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    <Typography variant="h6">Invite a User </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton color="primary" onClick={handleInvite}>
                      <AddCircle style={{ fontSize: 80 }} />
                    </IconButton>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          )}
        </Grid>
      </Container>
      <Modal open={showInviteModal} onClose={() => setShowInviteModal(false)}>
        <form onSubmit={handleInviteFormSubmit}>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            style={modalStyle}
            className={classes.modalPaper}
          >
            <Grid item xs={12}>
              <Typography variant="h6">Invite a User</Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="First Name"
                name="firstName"
                autoComplete="given_name"
                autoFocus
                value={inviteDetails.firstName}
                onChange={(e) =>
                  setInviteDetails({
                    ...inviteDetails,
                    firstName: e.target.value.trim(),
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="family_name"
                value={inviteDetails.lastName}
                onChange={(e) =>
                  setInviteDetails({
                    ...inviteDetails,
                    lastName: e.target.value.trim(),
                  })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                multiline
                required
                autoFocus
                autoComplete="email"
                type="email"
                id="email"
                label="Email"
                name="email"
                value={inviteDetails.email}
                onChange={(e) =>
                  setInviteDetails({
                    ...inviteDetails,
                    email: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Role
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={(e) =>
                    setInviteDetails({
                      ...inviteDetails,
                      role: e.target.value,
                    })
                  }
                  value={inviteDetails.role}
                >
                  <FormControlLabel
                    value="admin"
                    control={<Radio />}
                    label="Admin"
                  />
                  <FormControlLabel
                    value="user"
                    control={<Radio />}
                    label="User"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TimeZoneSelect
                required
                label="User's Time Zone"
                onChange={(e, newValue) =>
                  setInviteDetails({
                    ...inviteDetails,
                    defaultTimeZone: newValue,
                  })
                }
                value={inviteDetails.defaultTimeZone}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                multiline
                type="title"
                id="title"
                label="Title (optional)"
                name="title"
                value={inviteDetails.title}
                onChange={(e) =>
                  setInviteDetails({
                    ...inviteDetails,
                    title: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                multiline
                autoFocus
                type="department"
                id="department"
                label="Department (optional)"
                name="department"
                value={inviteDetails.department}
                onChange={(e) =>
                  setInviteDetails({
                    ...inviteDetails,
                    department: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <div style={{ width: "100%" }}>
                <Button
                  variant="outlined"
                  onClick={() => setShowInviteModal(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isLoading}
                  style={{ marginLeft: 10 }}
                >
                  Invite User
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      </Modal>
    </SchedulerNavbar>
  );
};

export default Team;
