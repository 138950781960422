import {
  Typography,
  TextField,
  Grid,
  Button,
  Container,
  ListItem,
  ListItemIcon,
  ListItemText,
  List,
  IconButton,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import CashAppLogo from "../../../assets/cashapp.png";
import VenmoLogo from "../../../assets/venmo.png";
import PaypalLogo from "../../../assets/paypal.png";
import StepLabel from "../../../components/StepLabel";
import useStore from "../../../store";
import MoveElement from "../../../components/MoveElement";

const useStyles = makeStyles((theme) => ({
  listItemIcon: {
    height: 25,
    width: 25,
  },
}));
const PayMe = ({ onSubmit, cancelForm, onRemove }) => {
  const elementToUpdate = useStore((state) => state.elementToUpdate);
  const classes = useStyles();
  let [localForm, setLocalForm] = useState({
    elementType: "PayMe",
    defaultPosition: "dropZone1",
    value: elementToUpdate ? elementToUpdate.value : "",
    label: elementToUpdate ? elementToUpdate.label : "Pay Me",
    payType: elementToUpdate ? elementToUpdate.payType : "",
  });
  let [showCashApp, setShowCashApp] = useState(
    elementToUpdate?.payType === "cashapp"
  );
  let [showVenmo, setShowVenmo] = useState(
    elementToUpdate?.payType === "venmo"
  );
  let [showPaypal, setShowPaypal] = useState(
    elementToUpdate?.payType === "paypal"
  );
  let handleBack = () => {
    setShowCashApp(false);
    setShowVenmo(false);
    setShowPaypal(false);
  };

  let payButtons = [
    {
      label: "Cash App",
      PassedIcon: CashAppLogo,
      onClick: () => {
        setLocalForm({ ...localForm, payType: "cashapp" });
        setShowCashApp(true);
      },
    },
    {
      label: "Venmo",
      PassedIcon: VenmoLogo,
      onClick: () => {
        setLocalForm({ ...localForm, payType: "venmo" });
        setShowVenmo(true);
      },
    },
    {
      label: "PayPal",
      PassedIcon: PaypalLogo,
      onClick: () => {
        setLocalForm({ ...localForm, payType: "paypal" });
        setShowPaypal(true);
      },
    },
  ];

  const typeIsSelected = showCashApp || showVenmo || showPaypal;
  // useEffect(() => {
  //   clearForm();
  //   setForm({
  //     value: elementToUpdate?.value,
  //     label: elementToUpdate?.label,
  //   });
  // });
  return (
    <Container style={{ paddingLeft: 8, paddingRight: 8 }}>
      <form style={{ width: "100%" }} onSubmit={(e) => onSubmit(e, localForm)}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              {elementToUpdate ? "Update" : "Create"} Pay Me Button
            </Typography>
          </Grid>
          {typeIsSelected && (
            <Grid xs={12}>
              <Typography variant={"subtitle"}>
                <IconButton color="primary" onClick={handleBack}>
                  <ArrowBack />
                </IconButton>
                Pick a different Payment Service
              </Typography>
            </Grid>
          )}
          {!typeIsSelected && (
            <Grid xs={12}>
              <List>
                {payButtons.map(({ label, PassedIcon, onClick }) => (
                  <ListItem
                    button
                    key={label}
                    style={{ marginTop: -2, marginBottom: -2 }}
                    onClick={onClick}
                  >
                    <ListItemIcon className={classes.listItemIcon}>
                      <img src={PassedIcon} alt="paylogo" />
                    </ListItemIcon>
                    <ListItemText primary={label} />
                  </ListItem>
                ))}
              </List>
            </Grid>
          )}

          {typeIsSelected && (
            <>
              {showCashApp && (
                <>
                  <Grid item xs={12}>
                    <StepLabel step="Step 1:" subtitle="Enter your Cash Tag:" />
                    <TextField
                      variant="outlined"
                      fullWidth
                      value={localForm.value}
                      onChange={(e) =>
                        setLocalForm({
                          ...localForm,
                          value: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <StepLabel
                      step="Step 2:"
                      subtitle="What would you like the button to say?"
                    />
                    <TextField
                      variant="outlined"
                      fullWidth
                      value={localForm.label}
                      onChange={(e) =>
                        setLocalForm({ ...localForm, label: e.target.value })
                      }
                    />
                  </Grid>
                </>
              )}

              {showVenmo && (
                <>
                  <Grid item xs={12}>
                    <StepLabel
                      step="Step 1:"
                      subtitle="Enter your Venmo handle:"
                    />
                    <TextField
                      variant="outlined"
                      fullWidth
                      value={localForm.value}
                      onChange={(e) =>
                        setLocalForm({
                          ...localForm,
                          value: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <StepLabel
                      step="Step 2:"
                      subtitle="What would you like the button to say?"
                    />
                    <TextField
                      variant="outlined"
                      fullWidth
                      value={localForm.label}
                      onChange={(e) =>
                        setLocalForm({
                          ...localForm,
                          label: e.target.value,
                        })
                      }
                    />
                  </Grid>
                </>
              )}

              {showPaypal && (
                <>
                  <Grid item xs={12}>
                    <StepLabel
                      step="Step 1:"
                      subtitle="Enter your PayPal handle:"
                    />
                    <TextField
                      variant="outlined"
                      fullWidth
                      value={localForm.value}
                      onChange={(e) =>
                        setLocalForm({
                          ...localForm,
                          value: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <StepLabel
                      step="Step 2:"
                      subtitle="What would you like the button to say?"
                    />
                    <TextField
                      variant="outlined"
                      fullWidth
                      value={localForm.label}
                      onChange={(e) =>
                        setLocalForm({ ...localForm, label: e.target.value })
                      }
                    />
                  </Grid>
                </>
              )}
            </>
          )}
          <Grid item xs={6}>
            <Button variant="outlined" fullWidth onClick={cancelForm}>
              CANCEL
            </Button>
          </Grid>
          {typeIsSelected && (
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
              >
                SAVE
              </Button>
            </Grid>
          )}
          {elementToUpdate && (
            <Container>
              <Button
                onClick={onRemove}
                fullWidth
                style={{ color: "red" }}
                variant="text"
              >
                REMOVE BUTTON
              </Button>
              <MoveElement element={elementToUpdate} />
            </Container>
          )}
        </Grid>
      </form>
    </Container>
  );
};

export default PayMe;
