import React, { useState } from "react";
import isUrl from "is-url";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { Modal, Button, Typography, Grid, Divider } from "@mui/material";
import BackgroundSquare from "./BackgroundSquare";
import squares from "./Squares";
import axios from "axios";

import useStore from "../store";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 800,
    backgroundColor: theme.palette.background.paper,
    //border: "2px solid #000",
    borderRadius: 5,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function OrgBackgroundModal({ openBgModal = false, onClose }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  let user = useStore((state) => state.user);
  let isPremiumUser = useStore((state) => state.isPremiumUser);
  let updateOrg = useStore((state) => state.updateOrg);
  const fetchPresignedUrl = useStore((state) => state.fetchPresignedUrl);
  let fetchPage = useStore((state) => state.fetchPage);
  let showBackgroundModal = useStore((state) => state.showBackgroundModal);
  let setShowBackgroundModal = useStore(
    (state) => state.setShowBackgroundModal
  );
  let setSelectedSquare = useStore((state) => state.setSelectedSquare);
  let page = useStore((state) => state.page);
  let [backgroundImage, setBackgroundImage] = useState("");
  let [backgroundVideo, setBackgroundVideo] = useState("");
  let [isSubmitting, setIsSubmitting] = useState(false);
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);

  let handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await updateOrg(user.org._id, {
        //defaultBackground: backgroundVideo,
        defaultBackground: backgroundImage,
      });

      setIsSubmitting(false);
      setShowBackgroundModal(false);
      fetchPage();
      enqueueSnackbar("Your background has been updated.", {
        variant: "success",
      });
    } catch (err) {
      setIsSubmitting(false);
      enqueueSnackbar(
        (err?.response?.data?.error && err?.response?.data?.error["0"].msg) ||
          "Error updating page.",
        { variant: "error" }
      );
    }
  };

  let handleBackgroundSelect = (e, values) => {
    setSelectedSquare(values?.name);
    if (values.type !== "video") {
      setBackgroundImage(values?.name);
      setBackgroundVideo(null); // reset video
    } else {
      setBackgroundVideo(values?.image);
      setBackgroundImage(null); // reset image
    }
  };

  const handleImageUpload = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    var file = document.querySelector("#file").files[0];
    var name = document
      .querySelector("#file")
      .value.split("\\")[2]
      .replace(/\s+/g, ""); //remove the whitespace

    let isVideo = ["mp4", "mov"].includes(name.split(".").pop());
    let urlRequest = await fetchPresignedUrl(
      name,
      isVideo ? "backgroundvideo" : "backgroundimg"
    );
    let uploadURL = urlRequest.data.uploadURL;
    let filePath = urlRequest.data.path;

    if (file) {
      try {
        await axios({
          method: "put",
          url: uploadURL,
          headers: {
            "Content-Type": isVideo ? "video/*" : "image/*",
          },
          data: file,
        });

        await updateOrg(
          user.org._id,
          isVideo
            ? { backgroundImage: null, backgroundVideo: filePath }
            : { backgroundImage: filePath }
        );
        fetchPage();
        enqueueSnackbar("Your background has been updated.", {
          variant: "success",
        });
        setIsSubmitting(false);
        // fetchPage();
        // cancelForm();
        setShowBackgroundModal(false);
      } catch (e) {
        setIsSubmitting(false);
        enqueueSnackbar("There was an issue uploading your image.", {
          variant: "error",
        });
      }
    } else {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Modal
        open={openBgModal}
        onClose={onClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <form onSubmit={handleSubmit}>
          <Grid
            container
            spacing={2}
            style={modalStyle}
            className={classes.paper}
          >
            <Grid
              container
              //direction="row"
              spacing={2}
              style={{ marginBottom: 10 }}
            >
              <Grid item xs={12}>
                <h2 id="simple-modal-title">Choose Background</h2>
              </Grid>
              <Grid item xs={3}>
                <Typography>Current background...</Typography>
              </Grid>
              <Grid item xs={4}>
                <img
                  style={{ width: 60, height: 60 }}
                  src={
                    isUrl(page?.backgroundImage)
                      ? page?.backgroundImage
                      : `https://d2ub6d0yniya4m.cloudfront.net/thumbnails/${user?.org?.defaultBackground}thumb.png`
                  }
                  alt="background img"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" id="simple-modal-description">
                  Choose a background color or image from the choices below...
                </Typography>
              </Grid>

              {squares
                .filter(({ name }) => !name.startsWith("bg_gs"))
                .map(({ color, image, name, thumb, type }) => (
                  <Grid item>
                    <BackgroundSquare
                      color={color}
                      image={image}
                      thumb={thumb}
                      name={name}
                      onClick={handleBackgroundSelect}
                      type={type}
                    />
                  </Grid>
                ))}
              {(user.email.includes("brit") ||
                user.email.endsWith("govspend.com") ||
                user.email.endsWith("jeff@i.help")) && (
                //   || user.email.endsWith("brit@i.help")
                <Grid item xs={12}>
                  <Divider style={{ width: "100%", margin: 10 }} />
                  <Typography>Or choose a GovSpend background...</Typography>
                </Grid>
              )}

              {(user.email.includes("brit") ||
                user.email.endsWith("govspend.com") ||
                user.email.endsWith("jeff@i.help")) &&
                // || user.email.endsWith("brit@i.help")
                squares
                  .filter(({ name }) => name.startsWith("bg_gs"))
                  .map(({ color, image, name, thumb }) => (
                    <Grid item>
                      <BackgroundSquare
                        color={color}
                        image={image}
                        thumb={thumb}
                        name={name}
                        onClick={handleBackgroundSelect}
                      />
                    </Grid>
                  ))}

              <Grid item xs={12}>
                <Divider style={{ width: "100%", margin: 10 }} />
                <Typography>
                  Or upload your own image...{" "}
                  <Typography variant="caption">
                    (accepted formats .png .jpg .mov .mp4)
                  </Typography>
                </Typography>
                {isPremiumUser(user) ? (
                  <Button
                    style={{ marginTop: 10 }}
                    variant="contained"
                    color="primary"
                    component="label"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? `Uploading...` : `Choose File...`}
                    <input
                      id="file"
                      type="file"
                      accept="video/mp4,video/quicktime,image/png,image/jpg"
                      hidden
                      onChange={handleImageUpload}
                    />
                  </Button>
                ) : (
                  <Button
                    style={{ borderRadius: 15, fontWeight: 700, marginTop: 10 }}
                    variant="contained"
                    color="primary"
                    href="/billing"
                  >
                    Upgrade to PRO
                  </Button>
                )}
              </Grid>
            </Grid>

            <Grid container justifyContent="flex-end" alignItems="flex-end">
              <Grid item xs={3}>
                <Button
                  style={{ marginRight: 5 }}
                  variant="outlined"
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? `Saving...` : `Save`}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Modal>
    </>
  );
}
