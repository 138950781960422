import { Button, Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import _ from "lodash";
import { makeStyles } from "@mui/styles";
import SchedulerNavbar from "../../components/SchedulerNavbar";
import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import useStore from "../../store";
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  paper: {
    padding: 20,
  },
  dataTable: {
    height: "80vh",
    padding: 20,
    margin: 20,
  },
});

let Admin = () => {
  let classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  let [users, setUsers] = useState([]);
  let listUsers = useStore((state) => state.listUsers);
  let deleteAccount = useStore((state) => state.deleteAccount);
  async function loadUsers() {
    let request = await listUsers();
    request.data.map((user) => {
      user.id = user._id;
      return user;
    });
    setUsers(_.sortBy(request.data, (user) => user.orgs[0]));
  }
  useEffect(() => {
    document.title = "Admin";
    loadUsers();
  }, []);

  let handleAccountDelete = async (id) => {
    let request = await deleteAccount(id);
    enqueueSnackbar(request?.data?.message, { variant: "success" });
    loadUsers();
  };
  const columnWidth = 200;

  const columns = [
    {
      field: "firstName",
      headerName: "First Name",
      width: columnWidth,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      width: columnWidth,
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
    },
    {
      field: "pageUrl",
      headerName: "Page URL",
      width: columnWidth,
      renderCell: (params) => (
        <a href={`https://i.help/${params?.row?.defaultPage?.name}`}>
          {params?.row?.defaultPage?.name}
        </a>
      ),
    },
    {
      field: "orgs",
      headerName: "Orgs",
      width: columnWidth,
    },
    {
      field: "Manage",
      headerName: "Manage",
      width: 300,
      editable: false,

      renderCell: (params) => (
        <Grid
          style={{ display: "flex", width: "100%", cursor: "pointer" }}
          justifyContent="space-between"
          alignItems="center"
        >
          <Button
            variant="contained"
            color="primary"
            href={`/admin/user/${params.row._id}`}
          >
            Manage User
          </Button>

          <Button
            variant="contained"
            style={{
              backgroundColor: "red",
              color: "#fff",
              fontWeight: 700,
            }}
            onClick={() =>
              window.confirm(
                `Are you sure you want to delete ${params.row.email}? This action cannot be undone.`
              ) && handleAccountDelete(params.row._id)
            }
          >
            Delete Account
          </Button>
        </Grid>
      ),
    },
    {
      field: "createdAt",
      headerName: "Created At",

      width: columnWidth,
    },
    {
      field: "lastSeenAt",
      headerName: "Last Seen At",
      width: columnWidth,
    },
  ];
  return (
    <SchedulerNavbar hideAddNewMeeting={true}>
      <DataGrid
        rows={users}
        columns={columns}
        pageSize={20}
        pagination
        className={classes.dataTable}
        disableSelectionOnClick
        getCellClassName={(params) => {
          if (params.field !== "description") {
            return "uneditableCell";
          }
        }}
      />
    </SchedulerNavbar>
  );
};

export default Admin;
