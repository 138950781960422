import { Typography, TextField, Grid, Button, Container } from "@mui/material";
import { useState } from "react";
import StepLabel from "../../../components/StepLabel";
import useStore from "../../../store";
const PageMessage = ({ onSubmit, cancelForm, onRemove }) => {
  const page = useStore((state) => state.page);
  const user = useStore((state) => state.user);
  const updatePage = useStore((state) => state.updatePage);
  const fetchPage = useStore((state) => state.fetchPage);
  let [message, setMessage] = useState(page.message);
  let [primaryMessage, setPrimaryMessage] = useState(page.primaryMessage);
  let handleSubmit = async (e) => {
    e.preventDefault();
    await updatePage(user.defaultPage, {
      message,
      primaryMessage,
    });
    fetchPage();
    cancelForm();
  };
  return (
    <Container style={{ paddingLeft: 8, paddingRight: 8 }}>
      <form style={{ width: "100%" }} onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="subtitle2">Update Page Message</Typography>
          </Grid>
          <Grid item xs={12}>
            <StepLabel step="Step 1:" subtitle="Update your page message:" />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              label="Primary Message"
              placeholder="Meet with me"
              multiline
              maxRows={4}
              fullWidth
              value={primaryMessage}
              onChange={(e) => setPrimaryMessage(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              label="Secondary Message"
              placeholder="Meet with me"
              multiline
              maxRows={4}
              fullWidth
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </Grid>

          <Grid item xs={6}>
            <Button variant="outlined" fullWidth onClick={cancelForm} fullWidth>
              CANCEL
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" color="primary" type="submit" fullWidth>
              SAVE
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default PageMessage;
