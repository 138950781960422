import React from "react";
import {
  TextField,
  Button,
  CssBaseline,
  Container,
  Typography,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import Navbar from "../../components/Navbar";
import { useSnackbar } from "notistack";
const baseUrl =
  process.env.REACT_APP_BASE_URL || process.env.REACT_APP_DEV_ENDPOINT;
function Reset() {
  const { enqueueSnackbar } = useSnackbar();

  let [loginForm, setLoginForm] = React.useState({});
  const [loginError, setLoginError] = React.useState();
  const [loading, setLoading] = React.useState(false);
  let submitForm = async (e, { email, password }) => {
    e.preventDefault();
    setLoading(true);
    try {
      await axios({
        method: "post",
        url: `${baseUrl}/v1/users/password/reset`,
        data: {
          email,
        },
      });
      enqueueSnackbar(`Your password reset email is on it's way to ${email}`, {
        variant: "success",
      });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err?.response?.data?.error?.message, err?.response?.status);
      setLoginError(
        err?.response?.data?.error?.message || "Invalid username or password."
      );
      // enqueueSnackbar("Invalid Username or Password.", { variant: "error" });
    }
  };

  const classes = useStyles();

  return (
    <Navbar buttonURL="/login" buttonText="Login">
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            Reset Your Password
          </Typography>
          <form
            className={classes.form}
            onSubmit={(e) => submitForm(e, loginForm)}
          >
            <TextField
              variant="outlined"
              margin="normal"
              required
              error={loginError}
              helperText={loginError}
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              disabled={loading}
              onChange={(e) =>
                setLoginForm({
                  ...loginForm,
                  email: e.target.value.toLowerCase().trim(),
                })
              }
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={loading}
              className={classes.submit}
            >
              {loading ? <CircularProgress /> : "Reset Password"}
            </Button>
          </form>
        </div>
      </Container>
    </Navbar>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(10),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default Reset;
