import { makeStyles } from "@mui/styles";
import { IconButton } from "@mui/material";
import { Videocam } from "@mui/icons-material";
import useStore from "../store";

const useStyles = makeStyles((theme) => ({
  backgroundImage: {
    width: 60,
    height: 60,
    cursor: "pointer",
    "&:hover": {
      // width: 160,
      // height: 160,
    },
  },
}));

const BackgroundSquare = ({
  color,
  onClick,
  isSelected,
  image,
  name,
  thumb,
  type,
  ...rest
}) => {
  let selectedSquare = useStore((state) => state.selectedSquare);
  let classes = useStyles();
  return (
    <div
      onClick={(e) =>
        onClick(e, {
          color,
          onClick,
          isSelected,
          image,
          name,
          thumb,
          type,
          ...rest,
        })
      }
      style={{
        position: "relative",
      }}
    >
      {type === "video" && (
        <IconButton
          style={{
            position: "absolute",
          }}
        >
          <Videocam style={{ fontSize: 40 }} />
        </IconButton>
      )}
      {thumb && (
        <img
          className={classes.backgroundImage}
          style={{
            ...(selectedSquare === name && {
              outline: "none",
              border: "2px solid",
              borderRadius: 7,
              borderColor: "#9ecaed",
              boxShadow: "0 0 10px #9ecaed",
            }),
          }}
          src={thumb}
          alt="background_image"
        />
      )}
    </div>
  );
};

export default BackgroundSquare;
