import { useHistory } from "react-router-dom";

const Logout = () => {
  let history = useHistory();
  localStorage.clear();
  history.push("/login");
  return <div>Logging you out...</div>;
};

export default Logout;
