import {
  Event,
  Call,
  MailOutline,
  ThumbUpOutlined,
  GetAppOutlined,
  MeetingRoomOutlined,
  PermMediaOutlined,
  VideoLibraryOutlined,
  TextFields,
  AccountCircle,
  WhatsApp,
  TextsmsOutlined,
  WebAssetOutlined,
  AttachMoney,
  PublishOutlined,
} from "@mui/icons-material";
let buttons = {
  pageSettingsButtons: [
    {
      label: "Profile Image",
      Icon: AccountCircle,
      formComponent: "AvatarForm",
    },
    {
      label: "Page Text",
      Icon: TextFields,
      formComponent: "PageMessage",
    },
  ],
  paidContentButtons: [
    {
      label: "Add Images",
      Icon: PermMediaOutlined,
      formComponent: "",
    },
    {
      label: "Add Video",
      Icon: VideoLibraryOutlined,
      formComponent: "",
    },
  ],
  freeContentButtons: [
    {
      label: "Web Conference",
      Icon: MeetingRoomOutlined,
      formComponent: "WebConference",
    },
    {
      label: "Schedule Meeting",
      Icon: Event,
      formComponent: "ScheduleMeeting",
    },
    {
      label: "Call Me",
      Icon: Call,
      formComponent: "CallMe",
    },
    {
      label: "Text Me",
      Icon: TextsmsOutlined,
      formComponent: "TextMe",
    },
    {
      label: "Email Me",
      Icon: MailOutline,
      formComponent: "EmailMe",
    },
    {
      label: "WhatsApp Me",
      Icon: WhatsApp,
      formComponent: "WhatsAppMe",
    },
    {
      label: "View Website / YouTube",
      Icon: WebAssetOutlined,
      formComponent: "ViewWebsite",
    },
    {
      label: "Pay Me",
      Icon: AttachMoney,
      formComponent: "PayMe",
    },
    /*    
    {
      label: "Link to Custom URL",
      Icon: ExitToApp,
      formComponent: "CustomURL",
    },
    */
    {
      label: "Visit Social Media",
      Icon: ThumbUpOutlined,
      formComponent: "SocialMedia",
    },
    {
      label: "Download File",
      Icon: GetAppOutlined,
      formComponent: "DownloadFile",
    },
    {
      label: "Upload File",
      Icon: PublishOutlined,
      formComponent: "UploadFile",
      disabled: false,
    },
  ],
};

export default buttons;
