import { Typography, Paper, Button, Divider, Grid } from "@mui/material";
import {
  Timeline,
  TimelineOppositeContent,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@mui/lab/";
import { format } from "date-fns";
import { makeStyles } from "@mui/styles";
import SchedulerNavbar from "../../components/SchedulerNavbar";
import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import useStore from "../../store";
import DisplayValue from "../../components/DisplayValue";
const useStyles = makeStyles({
  paper: { minHeight: 300 },
});

let ManageUser = () => {
  const { enqueueSnackbar } = useSnackbar();
  let classes = useStyles();
  let history = useHistory();
  let [selectedUser, setSelectedUser] = useState({});
  let [availabilityEvents, setAvailabilityEvents] = useState([]);
  let [bookings, setBookings] = useState([]);
  let [audits, setAudits] = useState([]);
  const setUser = useStore((state) => state.setUser);

  let retrieveUser = useStore((state) => state.retrieveUser);
  let impersonateUser = useStore((state) => state.impersonateUser);
  let retrieveAudits = useStore((state) => state.retrieveAudits);
  let refreshUserRequest = useStore((state) => state.refreshUserRequest);
  let clearPage = useStore((state) => state.clearPage);
  let clearUser = useStore((state) => state.clearUser);
  const { id } = useParams();
  async function loadUsers() {
    try {
      let request = await retrieveUser(id); // This will also fetch their page, bookings and availability events
      setSelectedUser(request.data.user);
      setAvailabilityEvents(request.data.availabilityEvents);
      setBookings(request.data.bookings);
    } catch (e) {
      console.log(e);
    }
  }
  async function fetchAudits() {
    let request = await retrieveAudits(id);
    setAudits(request.data);
  }

  async function loginAsUser() {
    clearUser();
    clearPage();
    let { data: tokenRequest } = await impersonateUser(
      id,
      selectedUser.orgs[0]
    );
    let { token } = tokenRequest;
    enqueueSnackbar(
      `You're now impersonating ${selectedUser.firstName} ${selectedUser.lastName}.`,
      { variant: "success" }
    );
    localStorage.setItem("token", token);
    let { data: user } = await refreshUserRequest();
    user.isImpersonating = true;
    user.org = user.orgs[0];
    setUser(user);
    handleLoginResponse(user, token);
  }
  useEffect(() => {
    loadUsers();
    fetchAudits();
  }, []);

  function handleLoginResponse(user, token) {
    localStorage.setItem("token", token);
    localStorage.setItem("pageId", user.defaultPage);
    localStorage.setItem("authenticatedAt", new Date());
    window.Intercom("boot", {
      app_id: "gpn6c78o",
      name: user.firstName + " " + user.lastName, // Full name
      email: user.email, // Email address
      created_at: user.createdAt, // Signup date as a Unix timestamp
      last_seen: Math.floor(Date.now() / 1000),
    });
    window.Upscope("updateConnection", {
      // Set the user ID below. If you don't have one, set to undefined.
      uniqueId: user.email,
      // Set the user name or email below (e.g. ["John Smith", "john.smith@acme.com"]).
      identities: [user.firstName + " " + user.lastName],
    });
    history.push("/p/edit");
  }
  return (
    <SchedulerNavbar
      hideAddNewMeeting
      additionalButtons={[
        <Button onClick={() => history.push("/admin")}>
          Return to User List
        </Button>,
      ]}
    >
      <Grid container spacing={1} style={{ margin: 20, width: "90%" }}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography variant="h4" style={{ padding: 15 }}>
              Details
            </Typography>
            <Divider />

            <Grid container justifyContent="center" spacing={1}>
              {Object.keys(selectedUser)
                .filter((k) => typeof selectedUser[k] === "string")
                .map((k) => (
                  <Grid item key={k} xs={12} sm={6} md={4} lg={3}>
                    <DisplayValue
                      title={k
                        .replace(/([A-Z])/g, " $1")
                        .replace(/^./, function (str) {
                          return str.toUpperCase();
                        })}
                      value={selectedUser[k]}
                    />
                  </Grid>
                ))}
            </Grid>

            {/* <pre>{JSON.stringify(user, null, 2)}</pre> */}
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography variant="h4" style={{ padding: 15 }}>
              Key Metrics
            </Typography>
            <Divider />

            <Grid container justifyContent="center" spacing={1}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <DisplayValue
                  key={"lastSeenAt"}
                  title="Last Logged In"
                  value={
                    selectedUser?.lastSeenAt &&
                    format(
                      new Date(selectedUser?.lastSeenAt),
                      "MMMM dd, yyyy h':'mm a"
                    )
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <DisplayValue
                  key={"Page Views"}
                  title="Page Views"
                  value={selectedUser?.defaultPage?.pageViews}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <DisplayValue
                  key={"Page Views"}
                  title="Number of Buttons"
                  value={
                    selectedUser?.defaultPage?.published?.dropZone1?.elements
                      ?.length
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <DisplayValue
                  key={"Page Last Viewed"}
                  title="Page Last Viewed"
                  value={
                    selectedUser?.defaultPage?.pageLastSeenAt &&
                    format(
                      new Date(selectedUser?.defaultPage?.pageLastSeenAt),
                      "MMMM dd, yyyy h':'mm a"
                    )
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <DisplayValue
                  key={"Latest Booking"}
                  title="Latest Scheduled Meeting"
                  value={
                    bookings[0]?.createdAt
                      ? format(
                          new Date(bookings[0]?.createdAt),
                          "MMMM dd, yyyy h':'mm a"
                        )
                      : "No Meetings Scheduled"
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <DisplayValue
                  key={"Latest Availability Event"}
                  title="Latest Availability Event"
                  value={
                    availabilityEvents[0]?.createdAt
                      ? format(
                          new Date(availabilityEvents[0]?.createdAt),
                          "MMMM dd, yyyy h':'mm a"
                        )
                      : "No Events Created"
                  }
                />
              </Grid>
            </Grid>

            {/* <pre>{JSON.stringify(selectedUser, null, 2)}</pre> */}
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper style={{ minWidth: "100%", minHeight: 300 }}>
            <Typography variant="h4" style={{ padding: 15 }}>
              Actions
            </Typography>
            <Divider />
            <Grid style={{ padding: 10 }} container spacing={2}>
              <Grid item>
                <Button
                  href={`/${selectedUser?.defaultPage?.name}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  variant="outlined"
                  color="primary"
                >
                  View Their Page
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={loginAsUser}
                >
                  Log In As User
                </Button>
              </Grid>
              <Grid item>
                <Button variant="outlined" color="primary">
                  Create Reset Password Link
                </Button>
              </Grid>
              <Grid item>
                <Button variant="outlined" color="primary">
                  Send Password Reset Email
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper style={{ minWidth: "100%", minHeight: 300 }}>
            <Typography variant="h4" style={{ padding: 15 }}>
              Timeline
            </Typography>
            <Divider />
            <Timeline>
              {audits.map((audit) => (
                <TimelineItem>
                  <TimelineOppositeContent color="text.secondary">
                    {format(new Date(audit.createdAt), "MM/dd/yyyy - h':'mm a")}
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>{audit.url}</TimelineContent>
                </TimelineItem>
              ))}
            </Timeline>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper style={{ minWidth: "100%", minHeight: 300 }}>
            <Typography variant="h4" style={{ padding: 15 }}>
              Audit Log
            </Typography>
            <Divider />
            {audits.map((audit) => (
              <>
                <div style={{ paddingLeft: 5 }}>
                  <pre>{JSON.stringify(audit, null, 2)}</pre>
                </div>
                <Divider />
              </>
            ))}
          </Paper>
        </Grid>
      </Grid>
    </SchedulerNavbar>
  );
};

export default ManageUser;
