import { Typography } from "@mui/material";

const DisplayValue = ({ title, value, key }) => (
  <div key={key} style={{ textAlign: "center" }}>
    <Typography variant="subtitle2">{title}</Typography>

    <Typography variant="body2">{value}</Typography>
  </div>
);

export default DisplayValue;
