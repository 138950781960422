import { Typography, TextField, Grid, Button, Container } from "@mui/material";
import { useEffect, useState } from "react";
import MuiPhoneInput from "material-ui-phone-number";
import StepLabel from "../../../components/StepLabel";
import useStore from "../../../store";
import MoveElement from "../../../components/MoveElement";
const CallMe = ({ onSubmit, cancelForm, onRemove }) => {
  const elementToUpdate = useStore((state) => state.elementToUpdate);

  let [localForm, setLocalForm] = useState({
    elementType: "CallMe",
    defaultPosition: "dropZone1",
    value: elementToUpdate ? elementToUpdate.value : "",
    label: elementToUpdate ? elementToUpdate.label : "Call Me",
  });

  // useEffect(() => {
  //   clearForm();
  //   setForm({
  //     value: elementToUpdate?.value,
  //     label: elementToUpdate?.label,
  //   });
  // });

  return (
    <Container style={{ paddingLeft: 8, paddingRight: 8 }}>
      <form style={{ width: "100%" }} onSubmit={(e) => onSubmit(e, localForm)}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              {elementToUpdate ? "Update" : "Create"} Call Me Button
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <StepLabel step="Step 1:" subtitle="Enter your phone number:" />
            <MuiPhoneInput
              defaultCountry={"us"}
              variant="outlined"
              value={localForm.value}
              fullWidth
              onChange={(value) => setLocalForm({ ...localForm, value: value })}
            />
          </Grid>

          <Grid item xs={12}>
            <StepLabel
              step="Step 2:"
              subtitle="What would you like the button to say?"
            />
            <TextField
              variant="outlined"
              fullWidth
              value={localForm.label}
              onChange={(e) =>
                setLocalForm({ ...localForm, label: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={6}>
            <Button variant="outlined" fullWidth onClick={cancelForm} fullWidth>
              CANCEL
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" color="primary" type="submit" fullWidth>
              SAVE
            </Button>
          </Grid>
          {elementToUpdate && (
            <Container>
              <Button
                onClick={onRemove}
                fullWidth
                style={{ color: "red" }}
                variant="text"
              >
                REMOVE BUTTON
              </Button>
              <MoveElement element={elementToUpdate} />
            </Container>
          )}
        </Grid>
      </form>
    </Container>
  );
};

export default CallMe;
