import React from "react";
import {
  Container,
  Paper,
  Avatar,
  Grid,
  Divider,
  IconButton,
} from "@mui/material";
import { LinkedIn, Twitter, Facebook } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import Skeleton from "@mui/material/Skeleton";
const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    minWidth: "100%",
    backgroundColor: "#5179a1",
    position: "fixed",
  },
  paper: {
    marginTop: theme.spacing(20),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: 300,
    borderRadius: 20,
  },
  avatar: {
    marginTop: theme.spacing(-8),
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
  actionButton: {
    margin: 5,
  },
  inlineContent: {
    display: "inline",
  },
  image: {
    maxHeight: 75,
    margin: 5,
  },
  divider: {
    width: "60%",
    marginTop: 15,
    marginBottom: 15,
  },
  buttonSkeleton: {
    display: "inline",
    margin: 5,
  },
}));
const HelpPageLoadingScreen = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Container>
        <Grid justifyContent="center" alignContent="center" container>
          <Grid item xs={12} md={6} lg={6}>
            <Paper className={classes.paper}>
              <Avatar className={classes.avatar} />
              <Skeleton variant="text" width={200} />
              <div className={classes.inlineContent}>
                <Skeleton
                  //className={classes.buttonSkeleton}
                  variant="rect"
                  width={80}
                  height={40}
                />
                <Skeleton
                  className={classes.buttonSkeleton}
                  variant="rect"
                  width={60}
                  height={30}
                />
                <Skeleton
                  className={classes.buttonSkeleton}
                  variant="rect"
                  width={60}
                  height={30}
                />
              </div>
              <Divider className={classes.divider} />
              <div className={classes.inlineContent}>
                <img
                  className={classes.image}
                  src="https://via.placeholder.com/150"
                  alt="workimage"
                />
                <img
                  className={classes.image}
                  src="https://via.placeholder.com/150"
                  alt="workimage"
                />
                <img
                  className={classes.image}
                  src="https://via.placeholder.com/150"
                  alt="workimage"
                />
              </div>
              <div className={classes.inlineContent}>
                <IconButton>
                  <Facebook fontSize="large" />
                </IconButton>
                <IconButton>
                  <LinkedIn fontSize="large" />
                </IconButton>
                <IconButton>
                  <Twitter fontSize="large" />
                </IconButton>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default HelpPageLoadingScreen;
