import { useEffect } from "react";
import SchedulerNavbar from "../../components/SchedulerNavbar";
import { Paper, Button, Container, Typography, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import useStore from "../../store";
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(20),
    width: "100%",
    padding: 20,
  },
}));
//import { makeStyles, useTheme } from "@mui/styles";
const SchedulerSettings = () => {
  const classes = useStyles();
  let user = useStore((state) => state.user);
  if (user) {
  }

  useEffect(() => {}, []);
  return (
    <SchedulerNavbar>
      <Container>
        <Grid container justifyContent="center">
          <Grid item xs={6}>
            <Paper className={classes.paper}>
              <Typography variant="subtitle2">Connect your calendar</Typography>
              <br />
              <Typography variant="body1">
                People who visit your page will be able to schedule meetings on
                your calendar. In order to activate this feature, you must
                connect to your calendar to i.help.
              </Typography>
              <br />
              <Typography variant="body1">
                Please select your calendar type below and follow the steps to
                allow access to your calendar.
              </Typography>
              <br />
              <Grid
                container
                alignContent="center"
                justifyContent="center"
                spacing={1}
              >
                <Grid item>
                  <Button fullWidth variant="outlined">
                    Connect Google Calendar
                  </Button>
                </Grid>
                <Grid item>
                  <Button fullWidth variant="outlined">
                    Connect Outlook Calendar
                  </Button>
                </Grid>
                <Grid item>
                  <Button fullWidth variant="outlined">
                    Connect iCloud Calendar
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </SchedulerNavbar>
  );
};
export default SchedulerSettings;
