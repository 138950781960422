/* 
This component renders the 
appropriate form in the FormMenu 
based on the formName prop. 
*/

import PageMessage from "./forms/PageMessage";
import CallMe from "./forms/CallMe";
import PayMe from "./forms/PayMe";
import TextMe from "./forms/TextMe";
import EmailMe from "./forms/EmailMe";
import WhatsAppMe from "./forms/WhatsAppMe";
import ViewWebsite from "./forms/ViewWebsite";
import CustomURL from "./forms/CustomURL";
import DownloadFile from "./forms/DownloadFile";
import UploadFile from "./forms/UploadFile";
import SocialMedia from "./forms/SocialMedia";
import WebConference from "./forms/WebConference";
import Background from "./forms/Background";
import ScheduleMeeting from "./forms/ScheduleMeeting";
import AvatarForm from "./forms/AvatarForm";
import useStore from "../../store";
let DrawerForm = ({ formName, onSubmit, page }) => {
  const setShowForm = useStore((state) => state.setShowForm);
  const setElementToUpdate = useStore((state) => state.setElementToUpdate);
  const elementToUpdate = useStore((state) => state.elementToUpdate);
  const clearForm = useStore((state) => state.clearForm);
  const setFormName = useStore((state) => state.setFormName);
  const removeElement = useStore((state) => state.removeElement);
  const fetchPage = useStore((state) => state.fetchPage);
  function cancelForm() {
    setFormName(null);
    clearForm();
    setElementToUpdate(null);
    setShowForm(false);
  }

  async function onRemove(id) {
    await removeElement(elementToUpdate._id);
    clearForm();
    setShowForm(false);
    setElementToUpdate(null);
    fetchPage();
  }

  let forms = {
    PageMessage,
    CallMe,
    TextMe,
    EmailMe,
    ViewWebsite,
    CustomURL,
    DownloadFile,
    SocialMedia,
    WebConference,
    Background,
    ScheduleMeeting,
    AvatarForm,
    WhatsAppMe,
    PayMe,
    UploadFile,
  };
  let Component = forms[formName];
  if (Component === undefined) {
    return (
      <>
        <p>Could not find form component with {formName} to render.</p>
        <button onClick={cancelForm}>Back</button>
      </>
    );
  } else {
    return (
      <Component
        onSubmit={onSubmit}
        cancelForm={cancelForm}
        onRemove={onRemove}
        page={page}
      />
    );
  }
};

export default DrawerForm;
