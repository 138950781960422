import { TextField, Chip } from "@mui/material";
import { useState } from "react";

const MultiEmailInput = ({ onChange, label, style, required }) => {
  let [chips, setChips] = useState([]);
  let handleChips = (event) => {
    let value = event.target.value.split(",");
    setChips(value);
    onChange(value);
  };
  return (
    <>
      {chips.map((chip) => chip && <Chip style={{ margin: 5 }} label={chip} />)}

      <TextField
        label={label || "Email(s) separated by commas"}
        variant="outlined"
        required={required || false}
        style={{ ...style }}
        fullWidth
        onChange={handleChips}
      />
    </>
  );
};

export default MultiEmailInput;
