import React, { useEffect, useState } from "react";
import {
  Paper,
  Avatar,
  Grid,
  Typography,
  Button,
  Fab,
  FormControl,
  Modal,
  TextField,
  CircularProgress,
  IconButton,
} from "@mui/material";
import MuiPhoneInput from "material-ui-phone-number";
import isUrl from "is-url";
import { ArrowBack } from "@mui/icons-material";
import { startOfDay, endOfDay } from "date-fns";
import { format, isFuture, isWithinInterval } from "date-fns";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { StaticDatePicker, LocalizationProvider } from "@mui/lab";
import { useParams, useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { createBreakpoint } from "react-use";
import "./schedule.css";
import MultiEmailInput from "../../components/MultiEmailInput";
import useStore from "../../store";
import { useQuery } from "../../util";
import PlaceHolderPage from "./PlaceHolderPage.js";
import logo from "../../assets/ihelp_logo.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    minWidth: "100%",
    //position: "fixed",
  },
  modalPaper: {
    position: "absolute",
    [theme.breakpoints.up("xs")]: {
      maxWidth: "100vw",
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: 500,
    },
    backgroundColor: theme.palette.background.paper,
    //border: "2px solid #000",
    borderRadius: 5,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  paper: {
    //marginTop: "50%",
    [theme.breakpoints.up("lg")]: {
      marginTop: "10%",
    },
    [theme.breakpoints.up("xl")]: {
      marginTop: "10%",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "10%",
    },
    [theme.breakpoints.between("xs", "sm")]: {
      margin: 10,
      marginTop: "18%",
      // minHeight: "95vh",
      // width: "100%",
      // minHeight: "100vh",
    },

    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    alignItems: "center",
    maxHeight: 600,
    borderRadius: 20,
    textAlign: "center",
    padding: 20,
    zIndex: 5,
  },
  backButton: {
    position: "absolute",
    marginRight: "40%",
    [theme.breakpoints.up("md", "lg")]: {
      marginRight: "45%",
    },
    [theme.breakpoints.only("xs")]: {
      marginRight: "80%",
    },
  },
  avatar: {
    marginTop: theme.spacing(-8),
    width: theme.spacing(15),
    height: theme.spacing(15),
    marginBottom: 10,
  },
  actionButton: {
    margin: 5,
  },
  timeSlotButton: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#006aff",
    border: "1px solid rgba(0, 107, 255, 0.5)",
    "&:hover": {
      border: "2px solid #006aff",
      backgroundColor: "#fff",
    },
  },
  timeSlotContainer: {
    maxHeight: 400,
    overflowY: "scroll",
  },
  inlineContent: {
    display: "inline-block",
    textAlign: "center",
  },
  image: {
    maxHeight: 75,
    margin: 5,
  },
  divider: {
    width: "60%",
    marginTop: 15,
    marginBottom: 15,
  },
  referralFab: {
    position: "absolute",
    right: 0,
    margin: 10,
    backgroundColor: "#fff",
    fontSize: "1rem",
  },
  backgroundVideo: {
    zIndex: -1,
    position: "fixed",
  },
}));
const useBreakpoint = createBreakpoint({
  xl: 1563,
  lg: 1200,
  md: 900,
  sm: 600,
  xs: 0,
});
const withHttp = (url) => (!/^https?:\/\//i.test(url) ? `http://${url}` : url);
const HelpPageScheduler = () => {
  const breakpoint = useBreakpoint();
  let isMobile = ["xs", "sm", "md"].includes(breakpoint);
  let { pageId, eventTypeId } = useParams();
  let history = useHistory();
  let query = useQuery();
  let mode = query.get("mode") === "draft" ? "draft" : "published";
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = useState(null);
  const [dateSelected, setDateSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [timeSlots, setTimeSlots] = useState([]);
  const [emails, setEmails] = useState([]);
  const [showBookMeetingModal, setShowBookMeetingModal] = useState(false);
  const [bookingDetails, setBookingDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    purpose: "",
    additionalEmails: [],
  });
  const [eventType, setEventType] = useState({});
  const [timeslotRequestMade, setTimeslotRequestMade] = useState(false);
  const [timeSlotRequestError, setTimeSlotRequestError] = useState("");
  const [blockedDays, setBlockedDays] = useState([0, 6]);
  const [bookingDateTime, setBookingDateTime] = useState({
    start: new Date(),
    end: new Date(),
  });
  const { enqueueSnackbar } = useSnackbar();
  const {
    fetchPage,
    page,
    isLoadingPage,
    fetchPageError,
    updatePageMetrics,
    fetchTimeSlots,
    createBooking,
  } = useStore((state) => ({
    createBooking: state.createBooking,
    fetchPage: state.fetchPage,
    page: state.page,
    isLoadingPage: state.isLoadingPage,
    fetchPageError: state.fetchPageError,
    updatePageMetrics: state.updatePageMetrics,
    fetchTimeSlots: state.fetchTimeSlots,
  }));
  function getModalStyle() {
    return {
      top: "40%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    };
  }
  const [modalStyle] = React.useState(getModalStyle);
  let showSlots = !isMobile || dateSelected;
  let showCalendar = !isMobile || !dateSelected;
  let handleMetricUpdate = async () => {
    let viewedPages = window.localStorage.getItem("viewedPages")
      ? JSON.parse(window.localStorage.getItem("viewedPages"))
      : [];
    if (!viewedPages.includes(pageId)) {
      await updatePageMetrics(page?._id);
      window.localStorage.setItem(
        "viewedPages",
        JSON.stringify([pageId, ...viewedPages])
      );
    }
  };
  useEffect(() => {
    document.title = "i.help: " + pageId;
    fetchPage(pageId, "name", mode);
    breakpoint !== "xs" && handleDateChange(new Date()); // Don't auto select dates if on mobile
  }, []);

  useEffect(() => {
    if (page?._id) {
      handleMetricUpdate();
    }
  }, [page]);

  function handleReferralClick() {
    localStorage.setItem("referralPage", page._id);
    history.push("/signup");
  }
  function disableDays(date, blockedDays) {
    return blockedDays.includes(date.getDay());
  }
  async function handleDateChange(e) {
    setTimeslotRequestMade(false);
    setDateSelected(true);
    setSelectedDate(e);
    let timeSlotsRequest;
    try {
      timeSlotsRequest = await fetchTimeSlots(eventTypeId, {
        startTime: startOfDay(e).toUTCString(),
        endTime: endOfDay(e).toUTCString(),
      });
    } catch (e) {
      let errorMessage =
        e?.response?.data?.error?.message || "Error fetching timeslots";
      setTimeslotRequestMade(true);
      setTimeSlotRequestError(errorMessage);
      return enqueueSnackbar(errorMessage, {
        variant: "error",
      });
    }

    console.log(timeSlotsRequest.data);
    let startLocalTimeZone = startOfDay(new Date(e));
    let endLocalTimeZone = endOfDay(new Date(e));
    let todaysSlots = timeSlotsRequest.data.availability.filter(
      (slot) =>
        isFuture(new Date(slot.start)) &&
        isWithinInterval(new Date(slot.start), {
          start: startLocalTimeZone,
          end: endLocalTimeZone,
        })
    );
    setTimeSlots(todaysSlots);
    let days = Object.keys(timeSlotsRequest.data.eventType.availability);
    setEventType(timeSlotsRequest.data.eventType);
    let closedDays = [];
    for (const [index, day] of days.entries()) {
      let dayIsOpen = timeSlotsRequest.data.eventType.availability[day].open;
      if (!dayIsOpen) {
        closedDays.push(index);
      }
    }
    setBlockedDays(closedDays);
    setTimeslotRequestMade(true);
  }

  function handleBookMeeting({ start, end }, index) {
    setBookingDateTime({ start, end, index });
    setShowBookMeetingModal(true);
  }
  async function handleBookingFormSubmit(e) {
    e.preventDefault();
    try {
      setIsLoading(true);
      bookingDetails.additionalEmails.push(bookingDetails.email);
      await createBooking({
        eventType: eventTypeId,
        start: bookingDateTime.start,
        end: bookingDateTime.end,
        bookingDetails,
      });
      setIsLoading(false);
      setShowBookMeetingModal(false);
      enqueueSnackbar(
        `Meeting has been successfully booked for ${format(
          bookingDateTime.start,
          "h':'mm bb 'on' cccc, MMMM do "
        )}.`,
        { variant: "success" }
      );
      window.location = eventType.redirectUrl
        ? withHttp(eventType.redirectUrl)
        : "https://www.i.help/confirmation";
      handleDateChange(new Date(bookingDateTime.start));
    } catch (e) {
      console.log(e);
      setIsLoading(false);
      enqueueSnackbar(
        `Error booking for ${format(
          bookingDateTime.start,
          "h':'mm bb 'on' cccc, MMMM do "
        )}.`,
        { variant: "error" }
      );
    }
  }
  return (
    <>
      {isLoadingPage && !fetchPageError ? (
        <div></div>
      ) : fetchPageError ? (
        <PlaceHolderPage />
      ) : (
        <>
          {page?.backgroundVideo && (
            <video className={classes.backgroundVideo} autoPlay loop muted>
              <source src={page?.backgroundVideo} type="video/mp4" />
            </video>
          )}
          <div
            id="page-content"
            style={{
              ...(!page?.backgroundVideo && page?.backgroundImage
                ? {
                    backgroundImage: isUrl(page?.backgroundImage)
                      ? `url("${page?.backgroundImage}")`
                      : `url("https://d2ub6d0yniya4m.cloudfront.net/bgimages/${page?.backgroundImage}.png")`,
                    backgroundSize: "100% 100%",
                  }
                : !page?.backgroundVideo
                ? { backgroundColor: page?.color || "#5179a1" }
                : null),
              height: "calc(100vh + 200px)",
            }}
            className={classes.root}
          >
            <Fab
              onClick={handleReferralClick}
              variant="extended"
              className={classes.referralFab}
              style={{ whiteSpace: "nowrap", height: 30 }}
            >
              Like this page? Create yours for free at{"  "}
              <img
                style={{ height: 20, paddingLeft: 5 }}
                src={logo}
                alt="logo"
              />
            </Fab>

            <Grid justifyContent="center" alignItems="center" container>
              <Grid item xs={12} sm={10} md={6} lg={6} xl={6}>
                <Paper className={classes.paper}>
                  <Avatar className={classes.avatar} src={page?.avatar} />
                  <IconButton
                    href={`${
                      process.env.NODE_ENV === "production"
                        ? "https://i.help"
                        : "http://localhost:3001"
                    }/${page?.name}`}
                    className={classes.backButton}
                  >
                    <ArrowBack />
                  </IconButton>
                  <Typography variant="h4">{page?.primaryMessage}</Typography>
                  <Grid container justifyContent="center" alignContent="center">
                    {showCalendar && (
                      <Grid item md={6} overflow="hidden">
                        <Typography variant="h6">
                          Select Date and Time
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <StaticDatePicker
                            style={{ width: "100%" }}
                            displayStaticWrapperAs="desktop"
                            views={["day"]}
                            shouldDisableDate={(e) =>
                              disableDays(e, blockedDays)
                            }
                            disablePast
                            value={selectedDate}
                            onChange={handleDateChange}
                          />
                        </LocalizationProvider>
                      </Grid>
                    )}
                    {showSlots && (
                      <Grid item md={6} style={{ overflow: "auto" }}>
                        <>
                          <Typography variant="h6">
                            {isMobile && (
                              <IconButton
                                color="primary"
                                onClick={() => setDateSelected(false)}
                              >
                                <ArrowBack />
                              </IconButton>
                            )}
                            {format(
                              selectedDate || new Date(),
                              "cccc, MMMM do"
                            )}
                            <Typography variant="body2">
                              {Intl.DateTimeFormat().resolvedOptions().timeZone}
                            </Typography>
                          </Typography>
                          <div className={classes.timeSlotContainer}>
                            {timeSlots.map(({ start, end }, index) => (
                              <Button
                                fullWidth
                                size="large"
                                variant="outlined"
                                style={{ marginTop: 10, color: "#006aff" }}
                                className={classes.timeSlotButton}
                                onClick={() =>
                                  handleBookMeeting(
                                    {
                                      start: new Date(start),
                                      end: new Date(end),
                                    },
                                    index
                                  )
                                }
                              >
                                {`${format(
                                  new Date(start),
                                  "h':'mm a"
                                )} - ${format(new Date(end), "h':'mm a")}`}
                              </Button>
                            ))}
                            {timeSlots.length === 0 && !timeslotRequestMade && (
                              <Button
                                fullWidth
                                size="large"
                                disabled
                                variant="outlined"
                                style={{ marginTop: 10 }}
                                className={classes.timeSlotButton}
                              >
                                <CircularProgress />
                              </Button>
                            )}
                            {timeSlots.length === 0 && timeslotRequestMade && (
                              <Typography style={{ marginTop: 60 }}>
                                {timeSlotRequestError ||
                                  "There are no available meeting times for this day."}
                              </Typography>
                            )}
                          </div>
                        </>
                      </Grid>
                    )}
                  </Grid>
                  <FormControl component="fieldset">
                    <Grid justifyContent="center" container>
                      {/* <FormLabel component="legend">Gender</FormLabel> */}
                    </Grid>
                  </FormControl>
                </Paper>
              </Grid>
            </Grid>
            <Modal
              open={showBookMeetingModal}
              onClose={() => setShowBookMeetingModal(false)}
            >
              <form onSubmit={handleBookingFormSubmit}>
                <Grid
                  container
                  spacing={2}
                  justifyContent="center"
                  style={modalStyle}
                  className={classes.modalPaper}
                >
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    <Typography variant="h6">{`Booking ${
                      eventType.name || "Meeting"
                    }`}</Typography>
                    <Typography variant="h6">
                      {`${format(
                        bookingDateTime.start,
                        "h':'mm bb 'on' cccc, MMMM do "
                      )}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      id="meetingTitle"
                      label="Meeting Title"
                      name="title"
                      autoFocus
                      value={bookingDetails.meetingTitle}
                      onChange={(e) =>
                        setBookingDetails({
                          ...bookingDetails,
                          meetingTitle: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      multiline
                      id="purpose"
                      label="Description of Meeting"
                      name="purpose"
                      value={bookingDetails.purpose}
                      onChange={(e) =>
                        setBookingDetails({
                          ...bookingDetails,
                          purpose: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="firstName"
                      label="First Name"
                      name="firstName"
                      autoComplete="given_name"
                      value={bookingDetails.firstName}
                      onChange={(e) =>
                        setBookingDetails({
                          ...bookingDetails,
                          firstName: e.target.value.trim(),
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="lastName"
                      label="Last Name"
                      name="lastName"
                      autoComplete="family_name"
                      value={bookingDetails.lastName}
                      onChange={(e) =>
                        setBookingDetails({
                          ...bookingDetails,
                          lastName: e.target.value.trim(),
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="email"
                      label="Your Email"
                      name="email"
                      autoComplete="email"
                      value={bookingDetails.email}
                      onChange={(e) =>
                        setBookingDetails({
                          ...bookingDetails,
                          email: e.target.value.trim(),
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MuiPhoneInput
                      defaultCountry={"us"}
                      variant="outlined"
                      disableAreaCodes={true}
                      value={bookingDetails.phone}
                      placeholder="Your Phone Number"
                      fullWidth
                      onChange={(value) =>
                        setBookingDetails({
                          ...bookingDetails,
                          phone: value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MultiEmailInput
                      label="Other Invitees (email addresses separated by commas"
                      onChange={(e) =>
                        setBookingDetails({
                          ...bookingDetails,
                          additionalEmails: e,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <div style={{ width: "100%" }}>
                      <Button
                        variant="outlined"
                        onClick={() => setShowBookMeetingModal(false)}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={isLoading}
                        style={{ marginLeft: 10 }}
                      >
                        Book Meeting
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </form>
            </Modal>
          </div>
        </>
      )}
    </>
  );
};

export default HelpPageScheduler;
