import React, { useState, useEffect } from "react";
import { MenuItem, FormControl, Select } from "@mui/material";
import useStore from "../store";

export default function EventTypeSelect({
  onChange,
  value,
  calendarType,
  onSelectionLoaded = () => {},
}) {
  const listEventTypes = useStore((state) => state.listEventTypes);
  let [eventTypes, setEventTypes] = useState([]);

  async function fetchEventTypes() {
    let eventsRequest = await listEventTypes();
    console.log({ eventTypes: eventsRequest.data, calendarType });
    let filteredEvents = eventsRequest.data.filter(
      (eventType) => eventType.calendarType === calendarType
    );
    onSelectionLoaded(filteredEvents);
    setEventTypes(filteredEvents);
  }

  useEffect(() => fetchEventTypes(), [calendarType]);

  let menuArray = [
    <MenuItem
      key="createeventtype"
      onClick={() => (window.location = "/p/edit/eventtype")}
    >
      Create New Event Type
    </MenuItem>,
  ];

  for (const eventType of eventTypes) {
    menuArray.unshift(
      <MenuItem key={eventType._id} value={eventType._id}>
        {`${eventType.name} - ${eventType.duration} min`}
      </MenuItem>
    );
  }
  return (
    <FormControl variant="outlined" style={{ width: "100%" }}>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value || ""}
        onChange={onChange}
        fullWidth
      >
        {menuArray}
      </Select>
    </FormControl>
  );
}
